import {
  GET_LIST_REQUEST,
  GET_LIST_SUCCESS,
  GET_LIST_FAIL,
} from '../actions/cetustekByOrderList';

const initState = {
  isLoadingList: false,
  list: [],
  pagination: null,
};

const cetustekByOrderList = (state = initState, action) => {
  switch (action.type) {
    case GET_LIST_REQUEST: {
      return { ...state, isLoadingList: true };
    }
    case GET_LIST_SUCCESS: {
      const { payload } = action;
      const { data } = payload;
      const { items, pagination } = data;

      return {
        ...state,
        isLoadingList: false,
        list: items,
        pagination,
      };
    }
    case GET_LIST_FAIL: {
      return { ...initState };
    }
    default:
      return state;
  }
};

export default cetustekByOrderList;
