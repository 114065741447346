import styled from 'styled-components';
import { media, Footer } from '@shopline/dashboard-ui';
import { PropTypes } from 'prop-types';
import PageHeader from '../PageHeader';

import PageHeaderPropTypes from '../../types/pageHeader';

const PageWrapper = styled.div`
  padding: 1.5rem 2rem;
  ${media.mobile`
    padding: 1.5rem 0;
  `}
`;

const Page = ({
  titleLeftSection,
  titleRightSection = null,
  breadcrumbs,
  hideFooter,
  className,
  children,
}) => (
  <PageWrapper className={className}>
    <PageHeader
      titleLeftSection={titleLeftSection}
      titleRightSection={titleRightSection}
      breadcrumbs={breadcrumbs}
    />
    {children}
    {!hideFooter && <Footer />}
  </PageWrapper>
);

Page.propTypes = {
  ...PageHeaderPropTypes,
  hideFooter: PropTypes.bool,
};

export default Page;
