import React, { useState, useCallback, useMemo } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { flow, curryRight, overEvery, identity, negate } from 'lodash-es';
import some from 'lodash/fp/some';
import getOr from 'lodash/fp/getOr';
import join from 'lodash/fp/join';
import { ImagePreview, InfiniteScrollPicker } from '@shopline/dashboard-ui';
import EllipsisText from 'components/EllipsisText';
import getTranslation, { isValidTranslationString } from 'utils/getTranslation';
import { isNoResult } from '../../utils';
import customTheme from './customTheme';
import * as columns from './columns';
import useProducts from './useProducts';
import Footer from './Footer';

const MODE_ALL = 'ALL';
const MODE_SELECTED = 'SELECTED';

const getMode = (modeAll) => (modeAll ? MODE_ALL : MODE_SELECTED);

const ThumbnailContainer = styled.div`
  flex-shrink: 0;
`;

const Title = styled(EllipsisText)`
  margin-left: 0.5rem;
`;

const renderText = (text) => (
  <EllipsisText fontType="Body" color="INK_800">
    {text}
  </EllipsisText>
);

const composePrice = ({ price }) => `${price.currency_symbol}${price.dollars}`;

const isValidFieldTranslations = overEvery([
  Array.isArray,
  some(isValidTranslationString),
]);

const curriedGetTranslation = curryRight(getTranslation, 4);

const createTableColumns = ({ locale, t }) => [
  {
    key: columns.TITLE,
    title: t('invoiceManagement:Product Title'),
    renderCell: ({ titleTranslations, thumbnail }) => (
      <>
        {thumbnail !== '' && (
          <ThumbnailContainer>
            <ImagePreview.Thumbnail image={thumbnail.url} />
          </ThumbnailContainer>
        )}
        <Title fontType="Body" color="INK_800">
          {getTranslation(titleTranslations, locale)}
        </Title>
      </>
    ),
  },
  {
    key: columns.DESCRIPTION,
    title: t('invoiceManagement:Product Variations'),
    renderCell: flow(
      getOr({}, 'fieldsTranslations'),
      curriedGetTranslation(locale, [], isValidFieldTranslations),
      join(','),
      renderText,
    ),
  },
  {
    key: columns.PRICE,
    title: t('invoiceManagement:Product Price'),
    renderCell: flow(composePrice, renderText),
  },
];

const ProductPicker = ({ merchantId, onClose, onPick }) => {
  const { t, i18n } = useTranslation();
  const tableColumns = useMemo(
    () => createTableColumns({ locale: i18n.language, t }),
    [i18n.language, t],
  );
  const [state, { loadMore, setKeyword, toggleSelection }] = useProducts({
    merchantId,
  });
  const [modeAll, setModeAll] = useState(true);
  const handleRowClick = useCallback(
    ([item]) => toggleSelection(item),
    [toggleSelection],
  );
  const handleToggleModeAll = useCallback(() => {
    setModeAll(negate(identity));
  }, []);
  const handleSubmit = useCallback(() => {
    onPick(
      Array.from(state.selectedMap.values()).map((item) => ({
        name: getTranslation(item.titleTranslations, i18n.language),
        price: item.price.dollars,
      })),
    );
  }, [onPick, state.selectedMap, i18n.language]);
  const showItemCount =
    state.currentPage < state.totalPages
      ? state.data.length + 1
      : state.data.length;
  return (
    <ThemeProvider theme={customTheme}>
      <InfiniteScrollPicker
        enableSelection
        title={t('invoiceManagement:Import Product information')}
        noResultTitle={t('invoiceManagement:No Products Found')}
        noResultText=""
        noSelectedText=""
        searchPlaceholder={t('invoiceManagement:Search Product Placeholder')}
        mode={getMode(modeAll)}
        items={state.data}
        selectedItems={state.selectedMap}
        noResult={isNoResult(state)}
        columns={tableColumns}
        showItemCount={showItemCount}
        onClose={onClose}
        onSearch={setKeyword}
        onRowClick={handleRowClick}
        loadMoreItems={loadMore}
        Footer={
          <Footer
            modeAll={modeAll}
            onModeAllToggle={handleToggleModeAll}
            selectedCount={state.selectedMap.size}
            onSubmit={handleSubmit}
            onCancel={onClose}
          />
        }
      />
    </ThemeProvider>
  );
};

export default ProductPicker;
