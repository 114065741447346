import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icons, InputWithSuffixIcon } from '@shopline/dashboard-ui';

const SearchBar = ({
  placeholder,
  onSearch,
  disabled = false,
  ...restProps
}) => {
  const { t } = useTranslation();
  const [text, setText] = useState('');
  const [searchText, setSearchText] = useState(restProps.defaultValue || '');

  const handleSearch = (value) => {
    onSearch(value);
    setSearchText(value);
  };

  return (
    <InputWithSuffixIcon
      isButtonDisabled={disabled || text === searchText}
      status="valid"
      placeholder={placeholder || t('common:Search')}
      Icon={Icons.Outline.Search}
      block
      buttonTooltipLabel={t('common:Search')}
      onChange={setText}
      onButtonClick={(_, value) => handleSearch(value)}
      onKeyPress={(event, value) =>
        event.key === 'Enter' && text !== searchText && handleSearch(value)
      }
      {...restProps}
    />
  );
};

export default SearchBar;
