import View from './View';
import useProps from './useProps';

const ByOrderList = ({ isExportPopupOpen, setIsExportPopupOpen }) => {
  const props = useProps();
  return (
    <View
      {...props}
      isExportPopupOpen={isExportPopupOpen}
      setIsExportPopupOpen={setIsExportPopupOpen}
    />
  );
};

export default ByOrderList;
