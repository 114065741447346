import {
  CARRIER_TYPE,
  INVOICE_ISSUE_TYPE,
  TAX_TYPE,
} from '../../constants/invoice';

const invoiceIssueTypeConfig = [
  {
    key: INVOICE_ISSUE_TYPE.CLOUD,
    value: INVOICE_ISSUE_TYPE.CLOUD,
    label: 'invoiceManagement:Non-physical electronic invoice',
  },
  {
    key: INVOICE_ISSUE_TYPE.DONATE,
    value: INVOICE_ISSUE_TYPE.DONATE,
    label: 'invoiceManagement:Donate e-invoice',
  },
  {
    key: INVOICE_ISSUE_TYPE.UNIFORM,
    value: INVOICE_ISSUE_TYPE.UNIFORM,
    label: 'invoiceManagement:Uniform invoice',
  },
  {
    key: INVOICE_ISSUE_TYPE.DUPLICATE_UNIFORM,
    value: INVOICE_ISSUE_TYPE.DUPLICATE_UNIFORM,
    label: 'invoiceManagement:Duplicate uniform invoice',
  },
];

const invoiceStatusConfig = [
  {
    key: 'valid',
    value: 'valid',
    label: 'invoiceManagement:Issued',
  },
  {
    key: 'invalid',
    value: 'invalid',
    label: 'invoiceManagement:Voided',
  },
];

const carrierTypeConfig = [
  {
    key: CARRIER_TYPE.MEMBER,
    value: CARRIER_TYPE.MEMBER,
    label: 'invoiceManagement:Member',
  },
  {
    key: CARRIER_TYPE.MOBILE_BARCODE,
    value: CARRIER_TYPE.MOBILE_BARCODE,
    label: 'invoiceManagement:Mobile Barcode',
  },
  {
    key: CARRIER_TYPE.NPC_BARCODE,
    value: CARRIER_TYPE.NPC_BARCODE,
    label: 'invoiceManagement:NPC Barcode',
  },
];

const taxTypeConfig = [
  {
    key: TAX_TYPE.TAXABLE,
    value: TAX_TYPE.TAXABLE,
    label: 'invoiceManagement:Taxable',
  },
  {
    key: TAX_TYPE.TAX_FREE,
    value: TAX_TYPE.TAX_FREE,
    label: 'invoiceManagement:Tax Free',
  },
  {
    key: TAX_TYPE.ZERO_TAX_EXPORTED,
    value: TAX_TYPE.ZERO_TAX_EXPORTED,
    label: 'invoiceManagement:Zero-Tax-Rate - Exported by customs',
  },
  {
    key: TAX_TYPE.ZERO_TAX_NOT_EXPORTED,
    value: TAX_TYPE.ZERO_TAX_NOT_EXPORTED,
    label: 'invoiceManagement:Zero-Tax-Rate - Not exported by customs',
  },
];

const searchTypeConfig = [
  {
    key: 'orderNo',
    value: 'order_number',
    label: 'invoiceManagement:Order Number',
  },
  {
    key: 'invoiceNo',
    value: 'invoice_number',
    label: 'invoiceManagement:Invoice Number',
  },
  {
    key: 'buyerName',
    value: 'buyer_name',
    label: "invoiceManagement:Buyer's Name",
  },
  {
    key: 'taxId',
    value: 'buyer_companyun',
    label: 'invoiceManagement:Tax ID',
  },
];

const fixedChannelConfig = [
  {
    key: 'shop',
    value: 'shop',
    label: 'invoiceManagement:Shop',
  },
];

const invoiceTypeConfig = [
  {
    key: 'all',
    value: 'all',
    label: 'common:All',
  },
  {
    key: 'invoice',
    value: 'invoice',
    label: 'invoiceManagement:Order Invoice',
  },
  {
    key: 'independent',
    value: 'independent',
    label: 'invoiceManagement:Non-order Invoice',
  },
];

export {
  invoiceIssueTypeConfig,
  invoiceStatusConfig,
  carrierTypeConfig,
  taxTypeConfig,
  searchTypeConfig,
  fixedChannelConfig,
  invoiceTypeConfig,
};
