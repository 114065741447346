import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { mapValues } from 'lodash-es';
import update from 'immutability-helper';
import useSimpleForm from 'hooks/useSimpleForm';
import { discountNameRule, getDiscountValueRule } from '../../validateRules';

const useDiscountEditor = ({
  maxDiscount,
  initialName,
  initialDiscountValue,
  validateDefaultValues,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const defaultValues = useMemo(
    () => ({
      name: initialName,
      discountValue: initialDiscountValue,
    }),
    [initialName, initialDiscountValue],
  );
  const rules = useMemo(
    () => ({
      name: mapValues(discountNameRule, (ruleConfig, ruleType) =>
        update(ruleConfig, {
          message: {
            $set: t(ruleConfig.transKey || `fieldValidation:${ruleType}`, {
              ...ruleConfig,
              fieldName: t('invoiceManagement:Discount Name'),
            }),
          },
        }),
      ),
      discountValue: mapValues(
        getDiscountValueRule(maxDiscount),
        (ruleConfig, ruleType) =>
          update(ruleConfig, {
            message: {
              $set: t(ruleConfig.transKey || `fieldValidation:${ruleType}`),
            },
          }),
      ),
    }),
    [t, maxDiscount],
  );
  const [values, meta] = useSimpleForm({
    defaultValues,
    rules,
    validateDefaultValues,
    onSubmit,
  });

  const renderNameMessages = useMemo(
    () => [() => meta.errors.name],
    [meta.errors.name],
  );
  const renderDiscountValueMessages = useMemo(
    () => [() => meta.errors.discountValue],
    [meta.errors.discountValue],
  );

  return [
    values,
    meta,
    {
      renderMessages: {
        name: renderNameMessages,
        discountValue: renderDiscountValueMessages,
      },
    },
  ];
};

export default useDiscountEditor;
