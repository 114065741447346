import update from 'immutability-helper';
import { actionTypes } from '../../actions/tradevanCreate/buyerInfo';

export const initialState = {
  customerId: '',
  importedEmail: '',
  name: '',
  tel: '',
  email: '',
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case actionTypes.SET_FIELD_VALUE:
      return update(state, {
        [payload[0]]: {
          $set: payload[1],
        },
      });
    case actionTypes.IMPORT_CUSTOMER:
      return update(state, {
        $merge: {
          ...payload,
          importedEmail: payload.email || '',
        },
      });
    default:
      return state;
  }
}
