const IconTimes = (props) => (
  <svg
    aria-hidden="true"
    focusable="false"
    viewBox="0 0 11 11"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.92188 7.79687C10.0677 7.94271 10.1406 8.11979 10.1406 8.32812C10.1406 8.53646 10.0677 8.71354 9.92188 8.85937L8.85938 9.92187C8.71354 10.0677 8.53646 10.1406 8.32813 10.1406C8.11979 10.1406 7.94271 10.0677 7.79688 9.92187L5.5 7.625L3.20313 9.92187C3.05729 10.0677 2.88021 10.1406 2.67188 10.1406C2.46354 10.1406 2.28646 10.0677 2.14063 9.92187L1.07813 8.85937C0.932292 8.71354 0.859375 8.53646 0.859375 8.32812C0.859375 8.11979 0.932292 7.94271 1.07813 7.79687L3.375 5.5L1.07813 3.20312C0.932292 3.05729 0.859375 2.88021 0.859375 2.67188C0.859375 2.46354 0.932292 2.28646 1.07813 2.14062L2.14063 1.07812C2.28646 0.932291 2.46354 0.859374 2.67188 0.859374C2.88021 0.859374 3.05729 0.932291 3.20313 1.07812L5.5 3.375L7.79688 1.07812C7.94271 0.932291 8.11979 0.859374 8.32813 0.859374C8.53646 0.859374 8.71354 0.932291 8.85938 1.07812L9.92188 2.14062C10.0677 2.28646 10.1406 2.46354 10.1406 2.67188C10.1406 2.88021 10.0677 3.05729 9.92188 3.20312L7.625 5.5L9.92188 7.79687Z"
      fill="currentColor"
    />
  </svg>
);

export default IconTimes;
