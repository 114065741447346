import { useMemo } from 'react';

const getEcEternalOrderUrl = (orderId) =>
  `<admin-endpoint>/admin/<handle>/orders/${orderId}`;
const getPosEternalOrderUrl = (orderId) =>
  `<admin-endpoint>/admin/<handle>/orders/pos/${orderId}`;

const useEternalOrderUrl = ({ orderId, eternalOrderUrl, createdBy }) =>
  useMemo(() => {
    if (eternalOrderUrl) {
      return eternalOrderUrl;
    }
    if (!orderId) {
      return '';
    }
    return createdBy === 'EC'
      ? getEcEternalOrderUrl(orderId)
      : getPosEternalOrderUrl(orderId);
  }, [eternalOrderUrl, createdBy, orderId]);

export default useEternalOrderUrl;
