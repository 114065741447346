import { useEffect } from 'react';
import { Route, useLocation } from 'react-router-dom';
import {
  dispatchRouteChange,
  dispatchRouteSearchChange,
} from 'utils/message/postMessageCenter';

const DispatchRoute = ({ component: Component, ...otherProps }) => {
  const { pathname, search } = useLocation();
  useEffect(() => {
    dispatchRouteChange({ pathname });
  }, [pathname]);

  useEffect(() => {
    dispatchRouteSearchChange({ search });
  }, [search]);

  return <Route component={Component} {...otherProps} />;
};

export default DispatchRoute;
