import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import createRootReducer from './rootReducer';

import createApi from '../utils/api';

const configureStore = () => {
  const api = createApi();
  const updateApiBaseUrl = (baseUrl) => {
    api.defaults.baseURL = baseUrl;
  };

  const thunkMiddleware = thunk.withExtraArgument({ api, updateApiBaseUrl });

  // eslint-disable-next-line no-underscore-dangle
  const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
  const middlewares = [thunkMiddleware];
  const store = createStore(createRootReducer(), composeEnhancers(applyMiddleware(...middlewares)));

  return store;
};

export default configureStore;
