import { get } from 'lodash-es';
import { getByDayListActionTypes } from '../actions/tradevanByDayList';

const initState = {
  isLoadingList: true,
  list: [],
  pagination: null,
};

const tradevanByDayList = (state = initState, action) => {
  switch (action.type) {
    case getByDayListActionTypes.request: {
      return { ...state, isLoadingList: true };
    }
    case getByDayListActionTypes.success: {
      const { payload } = action;
      const { data } = payload;
      const records = get(data, 'items.0.records', []);
      const pagination = get(data, 'pagination', null);
      return {
        ...state,
        isLoadingList: false,
        list: records,
        pagination,
      };
    }
    case getByDayListActionTypes.fail: {
      return { ...initState, isLoadingList: false };
    }
    case getByDayListActionTypes.init: {
      return { ...initState };
    }
    default:
      return state;
  }
};

export default tradevanByDayList;
