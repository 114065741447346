import update from 'immutability-helper';

const IMAGE_PREVIEW_SIZE = 'SMALL';

const customTheme = (theme) =>
  update(theme, {
    imagePreview: {
      types: {
        [IMAGE_PREVIEW_SIZE]: {
          maxWidth: {
            $set: '2.25rem',
          },
          maxHeight: {
            $set: '2.25rem',
          },
        },
      },
    },
  });

export default customTheme;
