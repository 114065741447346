import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import map from 'lodash/fp/map';
import useTranslateOptions from 'hooks/useTranslateOptions';
import { actions } from 'stores/invoiceManagement/actions/tradevanCreate/main';
import * as taxTypes from 'constants/taxTypes';
import * as invoiceKinds from 'constants/invoiceKinds';
import PARENT_E2E_PREFIX from './e2ePrefix';
import { createDropdownOptionsMapper } from './utils';
import {
  taxTypeValueSelector,
  invoiceKindValueSelector,
  defaultTaxTypeSelector,
} from './selectors/main';
import { Dropdown, Field, FieldRow, Section } from './components';
import * as InvoiceKindDetail from './InvoiceKindDetail';

const E2E_PREFIX = `${PARENT_E2E_PREFIX}-meta-section`;

const mapTaxTypeOptions = map(
  createDropdownOptionsMapper(`${E2E_PREFIX}-tax-type`),
);
const taxTypeOptions = mapTaxTypeOptions([
  {
    value: taxTypes.TAXABLE,
    label: 'invoiceManagement:Taxable',
  },
  {
    value: taxTypes.TAX_FREE,
    label: 'invoiceManagement:Tax Free',
  },
  {
    value: taxTypes.ZERO_TAX_RATE_CUSTOMS_EXPORT,
    label: 'invoiceManagement:Zero-Tax-Rate - Exported by customs',
  },
  {
    value: taxTypes.ZERO_TAX_RATE_NON_CUSTOMS_EXPORT,
    label: 'invoiceManagement:Zero-Tax-Rate - Not exported by customs',
  },
]);

const mapInvoiceKindOptions = map(
  createDropdownOptionsMapper(`${E2E_PREFIX}-invoice-kind`),
);

const invoiceKindOptions = mapInvoiceKindOptions([
  {
    value: invoiceKinds.PERSONAL,
    label: 'invoiceManagement:Non-physical electronic invoice',
  },
  {
    value: invoiceKinds.DONATION,
    label: 'invoiceManagement:Donate e-invoice',
  },
  {
    value: invoiceKinds.COMPANY,
    label: 'invoiceManagement:Uniform invoice',
  },
  {
    value: invoiceKinds.DUPLICATE,
    label: 'invoiceManagement:Duplicate uniform invoice',
  },
]);

const renderInvoiceKindDetail = (invoiceKind) => {
  switch (invoiceKind) {
    case invoiceKinds.PERSONAL:
      return <InvoiceKindDetail.Personal />;
    case invoiceKinds.DONATION:
      return <InvoiceKindDetail.Donation />;
    case invoiceKinds.COMPANY:
      return <InvoiceKindDetail.Company />;
    case invoiceKinds.DUPLICATE:
      return <InvoiceKindDetail.Duplicate />;
    default:
      return null;
  }
};

const MetaSection = () => {
  const { t } = useTranslation();
  const taxType = useSelector(taxTypeValueSelector);
  const invoiceKind = useSelector(invoiceKindValueSelector);
  const defaultTaxType = useSelector(defaultTaxTypeSelector);
  const translatedTaxTypeOptions = useTranslateOptions(taxTypeOptions);
  const translatedInvoiceKindOptions = useTranslateOptions(invoiceKindOptions);
  const dispatch = useDispatch();
  const handleTaxTypeChange = useCallback(
    ([value]) => {
      dispatch(actions.setTaxType(value));
    },
    [dispatch],
  );
  const handleInvliceKindChange = useCallback(
    ([value]) => {
      dispatch(actions.setInvoiceKind(value));
    },
    [dispatch],
  );
  useEffect(() => {
    if (defaultTaxType) {
      dispatch(actions.setTaxType(defaultTaxType));
    }
  }, [dispatch, defaultTaxType]);
  return (
    <Section title={t('invoiceManagement:Invoice Information')}>
      <FieldRow>
        <Field title={`${t('invoiceManagement:Tax Type')}*`}>
          <Dropdown
            onChange={handleTaxTypeChange}
            value={taxType}
            options={translatedTaxTypeOptions}
            placeHolder=""
            e2eId={`${E2E_PREFIX}-tax-type`}
          />
        </Field>
        <Field title={`${t('invoiceManagement:Type of Invoice Request')}*`}>
          <Dropdown
            onChange={handleInvliceKindChange}
            value={invoiceKind}
            options={translatedInvoiceKindOptions}
            placeHolder=""
            e2eId={`${E2E_PREFIX}-invoice-kind`}
          />
        </Field>
      </FieldRow>
      {renderInvoiceKindDetail(invoiceKind[0])}
    </Section>
  );
};

export default MetaSection;
