import dayjs from 'dayjs';
import { uniq } from 'lodash-es';
import { useSearch as _useSearch, validateParam } from 'hooks/useSearch';
import { useMemo } from 'react';

const validKey = [
  'startDate',
  'endDate',
  'limit',
  'page',
  'invoiceStatus',
  'field',
  'query',
  'channel',
  'invoiceType',
  'invoiceNumber',
];
const invoiceStatus = ['valid', 'invalid'];
const limits = ['25', '50', '100'];

const parseSearchParams = (params) => {
  const searchParams = Object.entries(params).reduce(
    (outcome, [key, value]) => {
      let newValue;
      if (!value || !validKey.includes(key)) return outcome;
      const decodedValue = decodeURIComponent(value);
      switch (key) {
        case 'startDate':
        case 'endDate':
          newValue = new Date(decodedValue);
          break;
        case 'limit':
          newValue = validateParam(limits, decodedValue);
          break;
        case 'page': {
          newValue = parseInt(decodedValue, 10) > 0 ? decodedValue : '1';
          break;
        }
        case 'invoiceStatus': {
          newValue = uniq(
            decodedValue
              .split(',')
              .map((current) => validateParam(invoiceStatus, current)),
          );
          break;
        }
        default:
          newValue = decodedValue;
          break;
      }
      return Object.assign(outcome, newValue && { [key]: newValue });
    },
    {},
  );
  return searchParams;
};

const genGenerateSearchParams = (baseParameters) => (params) => {
  const searchParams = Object.keys(params).reduce(
    (outcome, key) => {
      if (!params[key]) return outcome;
      let newValue;
      switch (key) {
        case 'startDate':
        case 'endDate': {
          const currentValue = params[key];
          newValue = dayjs(currentValue).format('YYYY-MM-DD');
          break;
        }
        case 'invoiceStatus': {
          const currentValue = params[key];
          newValue = currentValue.length ? currentValue.join(',') : '';
          break;
        }
        default: {
          const currentValue = params[key]?.toString();
          newValue = currentValue;
          break;
        }
      }
      return {
        ...outcome,
        ...(newValue && { [key]: newValue }),
      };
    },
    {
      page: baseParameters.page.toString(),
      limit: baseParameters.limit.toString(),
    },
  );
  return searchParams;
};

const useSearch = (baseParameters) => {
  const generateSearchParams = useMemo(
    () => genGenerateSearchParams(baseParameters),
    [baseParameters],
  );
  const props = _useSearch({
    baseParameters,
    parseSearchParams,
    generateSearchParams,
  });
  return props;
};

export default useSearch;
