import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TextArea } from '@shopline/dashboard-ui';
import { actions } from 'stores/invoiceManagement/actions/tradevanCreate/duplicateInvoice';
import { duplicateAddressRule } from '../validateRules';
import { submitKeySelector } from '../selectors/main';
import { duplicateAddressSelector } from '../selectors/duplicateInvoice';
import { Field, FieldRow, FormInput } from '../components';
import PARENT_E2E_PREFIX from './e2ePrefix';

const E2E_PREFIX = `${PARENT_E2E_PREFIX}-duplicate`;

const Duplicate = () => {
  const { t } = useTranslation();
  const submitKey = useSelector(submitKeySelector);
  const address = useSelector(duplicateAddressSelector);
  const dispatch = useDispatch();
  const handleAddressChange = useCallback(
    ({ target: { value } }) => {
      dispatch(actions.setAddress(value));
    },
    [dispatch],
  );
  return (
    <FieldRow>
      <Field title={`${t('invoiceManagement:Send to')}*`}>
        <FormInput
          as={TextArea}
          rows={2}
          maxCount={100}
          block
          value={address}
          onChange={handleAddressChange}
          e2eInputId={`${E2E_PREFIX}-address-input`}
          e2eMessagesId={`${E2E_PREFIX}-address-errors`}
          trimStart
          rule={duplicateAddressRule}
          fieldName={t('invoiceManagement:Send to')}
          validateKey={submitKey}
        />
      </Field>
    </FieldRow>
  );
};

export default Duplicate;
