import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from 'styled-components';
import { noop } from 'lodash-es';
import { InfiniteScrollPicker } from '@shopline/dashboard-ui';
import ItemRowContainer from '@shopline/dashboard-ui/dist/esm/components/infiniteScrollPicker/layout/ItemRowContainer';
import { HeadCell } from '@shopline/dashboard-ui/dist/esm/components/infiniteScrollPicker/layout/Head';
import { isNoResult } from '../../utils';
import * as columns from './columns';
import useCustomers from './useCustomers';
import renderIsMember from './renderIsMember';
import createRenderAction from './createRenderAction';
import createRenderCell from './createRenderCell';

const getTableCloumns = ({ onClickAction, t }) => [
  {
    key: columns.NAME,
    title: t('invoiceManagement:Customer Name'),
    renderCell: createRenderCell({ key: columns.NAME }),
  },
  {
    key: columns.MOBILE_PHONE,
    title: t('invoiceManagement:Customer Mobile'),
    renderCell: createRenderCell({ key: columns.MOBILE_PHONE }),
  },
  {
    key: columns.EMAIL,
    title: t('invoiceManagement:Customer Email'),
    renderCell: createRenderCell({ key: columns.EMAIL }),
  },
  {
    key: columns.IS_MEMBER,
    title: t('invoiceManagement:Is A Member'),
    renderCell: renderIsMember,
  },
  {
    key: columns.ACTION,
    title: '',
    renderCell: createRenderAction({
      onClick: onClickAction,
      text: t('invoiceManagement:Import'),
    }),
  },
];

const map = new Map();

const applyCustomStyles = () => css`
  & ${HeadCell}:nth-child(4),
  & ${HeadCell}:nth-child(5) {
    text-align: center;
  }
  & ${ItemRowContainer} > *:nth-child(4),
  & ${ItemRowContainer} > *:nth-child(5) {
    justify-content: center;
  }
`;

const CustomerPicker = ({ merchantId, onClose, onPick }) => {
  const { t } = useTranslation();
  const [state, { loadMore, setKeyword }] = useCustomers({ merchantId });
  const tableCloumns = useMemo(
    () =>
      getTableCloumns({
        t,
        onClickAction: (event) => {
          const btn = event.target.closest('[data-import-customer-button]');
          const {
            customerId,
            customerName,
            customerMobilePhone,
            customerEmail,
          } = btn.dataset;
          onPick({
            id: customerId,
            name: customerName,
            tel: customerMobilePhone,
            email: customerEmail,
          });
        },
      }),
    [onPick, t],
  );
  return (
    <InfiniteScrollPicker
      enableSelection={false}
      title={t("invoiceManagement:Import Customer's information")}
      noResultTitle={t('invoiceManagement:No Result')}
      noResultText={t('invoiceManagement:Try Again Search')}
      noSelectedText=""
      searchPlaceholder={t('invoiceManagement:Search Customer Placeholder')}
      mode="ALL"
      items={state.data}
      selectedItems={map}
      noResult={isNoResult(state)}
      columns={tableCloumns}
      showItemCount={Math.min(state.data.length + 1, state.totalCount)}
      onClose={onClose}
      onSearch={setKeyword}
      onRowClick={noop}
      loadMoreItems={loadMore}
      applyCustomStyles={applyCustomStyles}
    />
  );
};

export default CustomerPicker;
