import { useState, useCallback } from 'react';
import { Popup, RadioButton, Collapse, Button } from '@shopline/dashboard-ui';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import TextLabel from 'components/TextLabel';
import { perPageLimitConfig } from 'components/PageDropdown';

import getTranslation from 'utils/getTranslation';

const E2E_PREFIX = 'mobile-filter-popup-';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  ${({ hasPadding }) => hasPadding && 'padding: 1rem 1.8125rem 0rem 1rem;'}
  ${({ hasBorder, theme }) =>
    hasBorder && `border-bottom: 1px solid ${theme.colors.SKY_DEFAULT}`};
  & > * {
    margin-bottom: 1rem;
  }
  & > label {
    align-items: center;
    & > div > span {
      max-width: unset;
    }
  }
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .btn-cancel {
    margin-right: 1rem;
  }
`;

const PanelHeader = ({ title }) => (
  <TextLabel fontType="Subheading" color="INK_800">
    {title}
  </TextLabel>
);

const mergeParams = (newParams) => (prevParams) => ({
  ...prevParams,
  ...newParams,
});

const ByDayMobileFiltersPopup = ({
  e2eFeaturePrefix,
  initParams,
  fixedChannelConfig,
  channels,
  onConfirm,
  onCancel,
}) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const [parameters, setParameters] = useState(() => initParams);

  const onChange = useCallback((e) => {
    const { name, value } = e.target;
    setParameters(mergeParams({ [name]: value, page: '1' }));
  }, []);

  const renderFooter = () => (
    <Buttons>
      <Button.Default
        e2eId={`${e2eFeaturePrefix}${E2E_PREFIX}popup-cancel-btn`}
        className="btn-cancel"
        onClick={onCancel}
        width="MEDIUM"
        height="LARGE"
      >
        {t('common:Cancel')}
      </Button.Default>
      <Button.Primary
        e2eId={`${e2eFeaturePrefix}${E2E_PREFIX}popup-confirm-btn`}
        className="btn-delete"
        onClick={() => {
          onConfirm(parameters);
        }}
        width="MEDIUM"
        height="LARGE"
      >
        {t('common:Filter')}
      </Button.Primary>
    </Buttons>
  );

  return (
    <Popup
      e2eId={`${e2eFeaturePrefix}${E2E_PREFIX}popup`}
      title={t('common:Filter')}
      width="40rem"
      renderFooter={renderFooter}
      onClose={onCancel}
      hasBodyPadding={false}
    >
      <Collapse e2eId={`${e2eFeaturePrefix}${E2E_PREFIX}-collapse`}>
        <Collapse.Panel
          e2eId={`${e2eFeaturePrefix}${E2E_PREFIX}-panel-channel`}
          panelKey="channel"
          header={<PanelHeader title={t('invoiceManagement:Channel')} />}
        >
          <Content>
            <RadioButton
              data-e2e-id={`${e2eFeaturePrefix}${E2E_PREFIX}-channel-radio-btn-all`}
              name="channel"
              label={t('common:All')}
              checked={!parameters.channel}
              onChange={onChange}
            />
            {fixedChannelConfig.map((channel) => (
              <RadioButton
                key={channel.key}
                data-e2e-id={`${e2eFeaturePrefix}${E2E_PREFIX}-channel-radio-btn-${channel.value}`}
                name="channel"
                label={t(channel.label)}
                checked={parameters.channel === channel.value}
                value={channel.value}
                onChange={onChange}
              />
            ))}
            {channels.map((channel) => (
              <RadioButton
                key={channel._id}
                data-e2e-id={`${e2eFeaturePrefix}${E2E_PREFIX}-channel-radio-btn-${channel.value}`}
                label={getTranslation(channel.name, language)}
                checked={channel._id === parameters.channel}
                name="channel"
                value={channel._id}
                onChange={onChange}
              />
            ))}
          </Content>
        </Collapse.Panel>
        <Collapse.Panel
          e2eId={`${e2eFeaturePrefix}${E2E_PREFIX}-panel-page`}
          panelKey="page"
          header={<PanelHeader title={t('common:Per Page Limit')} />}
        >
          <Content>
            {perPageLimitConfig.map((limit) => (
              <RadioButton
                key={limit.value}
                data-e2e-id={`${e2eFeaturePrefix}${E2E_PREFIX}-page-checkbox-${limit.value}`}
                label={t(`common:${limit.label}`)}
                checked={limit.value === parameters.limit}
                name="limit"
                value={limit.value}
                onChange={onChange}
              />
            ))}
          </Content>
        </Collapse.Panel>
      </Collapse>
    </Popup>
  );
};

export default ByDayMobileFiltersPopup;
