import styled from 'styled-components';
import getPriceDisplay from './getPriceDisplay';
import Text from '../Text';

const PriceText = styled(Text).attrs(({ children, ...props }) => ({
  fontType: 'Body',
  color: 'INK_800',
  ...props,
  children: getPriceDisplay(children),
}))``;

export default PriceText;
