import { BrowserRouter as Router, Switch, Route, matchPath } from 'react-router-dom';
import InvoiceManagementRoute from './InvoiceManagement';
import ToastList from './ToastList';
import AppBase from './AppBase';

import { features } from '../constants/routes';

const matchValidPath = (targetPath) =>
  matchPath(targetPath, {
    path: `/admin/:merchantId/:feature(${features.join('|')})`,
  });

const FeatureRoute = () => {
  const matchResult = matchValidPath(window.location.pathname);

  if (!matchResult) return <div>Invalid Route</div>;
  return (
    <Router basename={`/admin/${matchResult.params.merchantId}`}>
      <AppBase merchantId={matchResult.params.merchantId}>
        <Switch>
          <Route exact path="/" component={() => <div>Index</div>} />
          <Route path="/invoice-management" component={InvoiceManagementRoute} />
        </Switch>
        <Route component={ToastList} />
      </AppBase>
    </Router>
  );
};

export default FeatureRoute;
