import { nanoid } from 'nanoid/non-secure';
import update from 'immutability-helper';
import { fromPairs, iteratee } from 'lodash-es';
import pick from 'lodash/fp/pick';
import pull from 'lodash/fp/pull';
import { actionTypes } from '../../actions/tradevanCreate/discountItems';

export const initialState = {
  itemMap: {},
  itemIdList: [],
};

const createDiscount = pick(['name', 'discountValue']);

const createItemPair = (discount) => [nanoid(), createDiscount(discount)];

const insertItemPairs = (state, itemPairs) =>
  update(state, {
    itemMap: {
      $merge: fromPairs(itemPairs),
    },
    itemIdList: {
      $push: itemPairs.map(iteratee('0')),
    },
  });

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case actionTypes.INSERT:
      return insertItemPairs(state, [createItemPair(payload)]);
    case actionTypes.UPDATE:
      return update(state, {
        itemMap: {
          [payload.id]: {
            $set: createDiscount(payload.discount),
          },
        },
      });
    case actionTypes.PULL:
      return update(state, {
        itemMap: {
          $unset: [payload],
        },
        itemIdList: {
          $apply: pull(payload),
        },
      });
    default:
      return state;
  }
}
