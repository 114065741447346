import React, { useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { iteratee } from 'lodash-es';
import { actions } from 'stores/invoiceManagement/actions/tradevanCreate/main';
import { Grid, PageTitle, Page } from './components';
import MetaSection from './MetaSection';
import BuyerSection from './BuyerSection';
import ItemsSection from './ItemsSection';
import SummarySection from './SummarySection';
import PARENT_E2E_PREFIX from './e2ePrefix';

const E2E_PREFIX = `${PARENT_E2E_PREFIX}-tradevan-create`;

const merchantIdSelector = iteratee('merchant.merchantData.id');

const TradevanCreate = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const merchantId = useSelector(merchantIdSelector);
  const breadcrumbs = useMemo(
    () => [
      {
        id: t('invoiceManagement:Invoice Management'),
        label: t('invoiceManagement:Invoice Management'),
        e2eId: `${E2E_PREFIX}-breadcrumb-invoice-management`,
      },
      {
        id: t('invoiceManagement:Invoice'),
        label: t('invoiceManagement:Invoice'),
        e2eId: `${E2E_PREFIX}-breadcrumb-invoice`,
        url: `/admin/${merchantId}/invoice-management/tradevan`,
        onClick: (event) => {
          event.preventDefault();
          history.push('/invoice-management/tradevan');
        },
      },
      {
        id: t('invoiceManagement:Create Invoice'),
        label: t('invoiceManagement:Create Invoice'),
        e2eId: `${E2E_PREFIX}-breadcrumb-create-invoice`,
      },
    ],
    [t, history, merchantId],
  );
  const dispatch = useDispatch();
  useEffect(
    () => () => {
      dispatch(actions.init());
    },
    [dispatch],
  );
  return (
    <Page
      titleLeftSection={
        <PageTitle>{t('invoiceManagement:Create Invoice')}</PageTitle>
      }
      breadcrumbs={breadcrumbs}
    >
      <Grid.Row>
        <Grid.Column disableGutter lg={8} md={12} sd={12}>
          <MetaSection />
          <BuyerSection />
          <ItemsSection />
        </Grid.Column>
        <Grid.Column disableGutter lg={4} md={12} sd={12}>
          <SummarySection />
        </Grid.Column>
      </Grid.Row>
    </Page>
  );
};

export default TradevanCreate;
