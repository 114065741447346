import { useEffect, useCallback } from 'react';
import OrderList from './OrderList';
import ExportPopup from '../../components/ExportPopup';

const View = ({
  parameters,
  updateParams,
  list,
  isLoadingList,
  getList,
  pagination,
  channels,
  getChannels,
  exportByOrderList,
  isExportPopupOpen,
  setIsExportPopupOpen,
}) => {
  const onClosePopup = useCallback(() => {
    setIsExportPopupOpen(false);
  }, [setIsExportPopupOpen]);

  const onConfirmPopup = useCallback(
    ({ rangeType, range }) => {
      exportByOrderList({ rangeType, parameters, range, list });
      setIsExportPopupOpen(false);
    },
    [exportByOrderList, setIsExportPopupOpen, parameters, list],
  );

  useEffect(() => {
    getChannels();
  }, [getChannels]);

  return (
    <>
      <OrderList
        parameters={parameters}
        updateParams={updateParams}
        list={list}
        isLoadingList={isLoadingList}
        getList={getList}
        pagination={pagination}
        channels={channels}
      />
      {isExportPopupOpen && (
        <ExportPopup onClose={onClosePopup} onSubmit={onConfirmPopup} />
      )}
    </>
  );
};

export default View;
