import { actionTypes } from '../../actions/tradevanCreate/duplicateInvoice';

export const initialState = {
  address: '',
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case actionTypes.SET_ADDRESS:
      return {
        ...state,
        address: payload,
      };
    default:
      return state;
  }
}
