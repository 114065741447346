import React from 'react';
import { useTranslation } from 'react-i18next';

const ExportToastMessage = ({ receiverEmail }) => {
  const { t } = useTranslation();
  const message = t('common:Report Toast Message', {
    email: receiverEmail,
  });

  return <span dangerouslySetInnerHTML={{ __html: message }} />;
};

export default ExportToastMessage;
