import styled from 'styled-components';

const ItemList = styled.ul`
  max-height: 27.875rem;
  overflow: auto;
  margin: 1.25rem 0;
  flex: 0 0 100%;
  padding: 0;
  &:empty {
    display: none;
  }
`;

export default ItemList;
