import { createSelector, createStructuredSelector } from 'reselect';
import { camelCase, cond, flow, stubTrue } from 'lodash-es';
import getOr from 'lodash/fp/getOr';
import prop from 'lodash/fp/prop';
import map from 'lodash/fp/map';
import merge from 'lodash/fp/merge';
import mapKeys from 'lodash/fp/mapKeys';
import createApi from 'utils/api';

const itemSelector = createStructuredSelector({
  uniqueId: prop('id'),
  titleTranslations: getOr({}, ['title_translations']),
  thumbnail: getOr('', ['medias', 0, 'images', 'original']),
  price: getOr({}, ['price']),
});

const variationsSelector = flow(
  prop('variations'),
  map(
    createStructuredSelector({
      uniqueId: prop('id'),
      thumbnail: prop(['media', 'images', 'original']),
      fieldsTranslations: getOr({}, ['fields_translations']),
      price: prop(['price']),
    }),
  ),
);

const itemVariationsSelector = createSelector(
  itemSelector,
  variationsSelector,
  (item, variations) => variations.map(merge(item)),
);

const getProducts = ({ page, perPage, merchantId, keyword }) =>
  createApi()
    .get(`/merchants/${merchantId}/products`, {
      params: { page, per_page: perPage, query: keyword },
    })
    .then((res) => ({
      pagination: mapKeys(camelCase, res.data.pagination),
      items: res.data.items
        .map(
          cond([
            [({ variations }) => variations.length === 0, itemSelector],
            [stubTrue, itemVariationsSelector],
          ]),
        )
        .flat(),
    }));

export default getProducts;
