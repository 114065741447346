import { cond, constant, get, stubTrue } from 'lodash-es';
import eq from 'lodash/fp/eq';
import { INVOICE_ISSUE_TYPE } from 'constants/invoice';
import * as apiCarrierTypes from 'constants/apiCarrierTypes';

const getIssueTypeTransKey = cond([
  [
    eq(INVOICE_ISSUE_TYPE.CLOUD),
    constant('invoiceManagement:Non-physical electronic invoice'),
  ],
  [
    eq(INVOICE_ISSUE_TYPE.DONATE),
    constant('invoiceManagement:Donate e-invoice'),
  ],
  [
    eq(INVOICE_ISSUE_TYPE.UNIFORM),
    constant('invoiceManagement:Uniform invoice'),
  ],
  [eq(INVOICE_ISSUE_TYPE.PAPER), constant('invoiceManagement:Paper invoice')],
  [
    eq(INVOICE_ISSUE_TYPE.DUPLICATE_UNIFORM),
    constant('invoiceManagement:Duplicate uniform invoice'),
  ],
  [stubTrue, constant('-')],
]);

const getPersonalCarrierTypeTransKey = cond([
  [eq(apiCarrierTypes.MEMBER), constant('invoiceManagement:Member')],
  [
    eq(apiCarrierTypes.MOBILE_BARCODE),
    constant('invoiceManagement:Mobile Barcode'),
  ],
  [
    eq(apiCarrierTypes.DIGITAL_CERTIFICATE),
    constant('invoiceManagement:NPC Barcode'),
  ],
  [stubTrue, constant('-')],
]);

const generateDetailId = (invoice = {}) =>
  `${invoice.invoice_number}-${invoice.period}`;

// money amount from ec is money object
const getDollarFromMoneyObj = (money) => get(money, 'dollars', 0);

export {
  getIssueTypeTransKey,
  getPersonalCarrierTypeTransKey,
  generateDetailId,
  getDollarFromMoneyObj,
};
