import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { media, Loading } from '@shopline/dashboard-ui';
import { useTranslation } from 'react-i18next';
import { camelCase, flow } from 'lodash-es';
import defaults from 'lodash/fp/defaults';
import groupBy from 'lodash/fp/groupBy';
import mapKeys from 'lodash/fp/mapKeys';
import mapValues from 'lodash/fp/mapValues';
import reduce from 'lodash/fp/reduce';
import TextLabel from 'components/TextLabel';
import PriceText, { getPriceDisplay } from 'components/PriceText';
import { InfoContainer } from './InfoSection';
import { CellContainer, baseCellStyled, baseCellContainerStyles } from './Cell';
import Row from './Row';
import { E2E_BY_ORDER_PREFIX } from '../../../constants';

const E2E_PREFIX = `${E2E_BY_ORDER_PREFIX}detail-`;

const OrderDetailSubSection = styled.div`
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.SKY_DEFAULT}`};
  padding-bottom: 0.5rem;
`;

const StyledCellContainer = styled(CellContainer)`
  flex-wrap: nowrap;
`;

const ProductCellContainer = styled.div`
  ${baseCellContainerStyles}
  flex: 1 1 auto;
  ${media.mobile`
    flex-direction: column;
  `}
`;

const ProductNameCell = styled.div`
  ${baseCellStyled}
  ${media.mobile`
    width: 100%;
    flex-grow: 0;
  `}
`;

const taxTypeMixin = css`
  width: auto;
  margin-top: 0.5rem;
  ${media.mobile`
    margin-top: 0.25rem;
  `}
`;

const TaxTypeCell = styled(ProductNameCell)`
  ${taxTypeMixin}
  flex-grow: 0;
`;

const ProductAmountCell = styled.div`
  ${baseCellStyled}
  flex: 0 0 auto;
  width: 6rem;
  margin-left: 1rem;
  text-align: right;
  ${media.mobile`
    margin-left: 0;
    margin-top: 0.25rem;
    text-align: left;
    width: 100%;
  `}
`;

const ProductSubTotalCell = styled.div`
  ${baseCellStyled}
  text-align: right;
  width: 6rem;
  margin-left: 1rem;
  flex: 0 0 auto;
`;

const TaxTypeDisplayCell = styled(ProductSubTotalCell)`
  ${taxTypeMixin}
  flex-grow: 1;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  & > :first-child {
    margin-right: 16px;
  }
`;

const StyledText = styled(TextLabel)`
  word-break: break-word;
  overflow-wrap: break-word;
`;

const transformDetails = flow(
  groupBy('production_code'),
  mapKeys(camelCase),
  defaults({
    deliveryFee: [],
    paymentFee: [],
    product: [],
    discount: [],
    storeCredits: [],
    pointDiscount: [],
    customRefund: [],
  }),
);

const sumItems = reduce(
  (accumulator, { unit_price: price, quantity }) =>
    accumulator + price * quantity,
  0,
);

const renderContent = (isLoading, content) =>
  isLoading ? <Loading.SparkLine /> : content;

const OrderSection = ({ titleLangKey, details, orderAmount, isLoading }) => {
  const { t } = useTranslation();

  const detailGroups = useMemo(() => transformDetails(details), [details]);

  const {
    deliveryFee,
    paymentFee,
    product: subtotal,
  } = useMemo(() => mapValues(sumItems, detailGroups), [detailGroups]);

  return (
    <InfoContainer>
      <TextLabel
        as="h2"
        e2eId={`${E2E_PREFIX}invoice-info-title`}
        fontType="Heading"
        weight="Semibold"
      >
        {t(titleLangKey)}
      </TextLabel>
      <OrderDetailSubSection>
        {!isLoading ? (
          detailGroups.product.map((item, index) => (
            <React.Fragment key={`item.${index}`}>
              <StyledCellContainer>
                <ProductCellContainer>
                  <ProductNameCell>
                    <StyledText fontType="Body">{item.description}</StyledText>
                  </ProductNameCell>
                  <ProductAmountCell>
                    <TextLabel fontType="Body">{`${
                      item.quantity
                    } x ${getPriceDisplay(item.unit_price)}`}</TextLabel>
                  </ProductAmountCell>
                </ProductCellContainer>
                <ProductSubTotalCell>
                  <PriceText>{item.unit_price * item.quantity}</PriceText>
                </ProductSubTotalCell>
              </StyledCellContainer>
              <StyledCellContainer>
                <TaxTypeCell>
                  <StyledText fontType="Body">
                    {t('invoiceManagement:Tax Type')}
                  </StyledText>
                </TaxTypeCell>
                <TaxTypeDisplayCell>
                  <StyledText fontType="Body">
                    {t('invoiceManagement:TaxTypeDisplay', {
                      context: item.tax_type,
                    })}
                  </StyledText>
                </TaxTypeDisplayCell>
              </StyledCellContainer>
            </React.Fragment>
          ))
        ) : (
          <LoadingContainer>
            <Loading.AvatarRect width="2.5rem" />
            <Loading.BodyText width="100%" />
          </LoadingContainer>
        )}
        <Row
          key="subtotal"
          title={t('invoiceManagement:Subtotal')}
          content={renderContent(isLoading, getPriceDisplay(subtotal))}
        />
      </OrderDetailSubSection>
      <OrderDetailSubSection>
        <Row
          key="deliveryFee"
          title={t('invoiceManagement:Delivery Fee')}
          content={renderContent(isLoading, getPriceDisplay(deliveryFee))}
        />
        <Row
          key="paymentFee"
          title={t('invoiceManagement:Payment Fee')}
          content={renderContent(isLoading, getPriceDisplay(paymentFee))}
        />
        {[
          ...detailGroups.discount,
          ...detailGroups.storeCredits,
          ...detailGroups.pointDiscount,
          ...detailGroups.customRefund,
        ].map((item, index) => (
          <React.Fragment key={`discount.${index}`}>
            <Row
              title={item.description}
              content={renderContent(
                isLoading,
                getPriceDisplay(item.unit_price),
              )}
            />
            <Row
              title={t('invoiceManagement:Tax Type')}
              content={t('invoiceManagement:TaxTypeDisplay', {
                context: item.tax_type,
              })}
            />
          </React.Fragment>
        ))}
      </OrderDetailSubSection>
      <Row
        title={t('invoiceManagement:Total Amount')}
        titleWeight="Semibold"
        content={renderContent(isLoading, getPriceDisplay(orderAmount))}
      />
    </InfoContainer>
  );
};

export default OrderSection;
