import dayjs from 'dayjs';

const locale = {
  name: 'th',
  weekdays: 'วันอาทิตย์_วันจันทร์_วันอังคาร_วันพุธ_วันพฤหัสบดี_วันศุกร์_วันเสาร์'.split('_'),
  weekdaysShort: 'อา._จ._อ._พ._พฤ._ศ._ส.'.split('_'),
  weekdaysMin: 'อา._จ._อ._พ._พฤ._ศ._ส.'.split('_'),
  months: 'มกราคม_กุมภาพันธ์_มีนาคม_เมษายน_พฤษภาคม_มิถุนายน_กรกฎาคม_สิงหาคม_กันยายน_ตุลาคม_พฤศจิกายน_ธันวาคม'.split(
    '_',
  ),
  monthsShort: 'ม.ค._ก.พ._มี.ค._เม.ย._พ.ค._มิ.ย._ก.ค._ส.ค._ก.ย._ต.ค._พ.ย._ธ.ค.'.split('_'),
  formats: {
    LTS: 'H:mm:ss',
    LT: 'h:mm a',
    L: 'DD/MM/YYYY',
    LL: 'MMM D, YYYY',
    LLL: 'MMM D, YYYY [เวลา] h:mm a',
    LLLL: 'dddd, D MMMM YYYY HH:mm',
  },
  meridiem: (hour) => (hour >= 12 ? 'pm' : 'am'),
  relativeTime: {
    future: 'ภายใน %s',
    past: '%s ที่ผ่านมาแล้ว ',
    s: 'ซักครู่หนึ่ง',
    m: '1 นาที',
    mm: '%d นาที',
    h: '1 ชั่วโมง',
    hh: '%d ชั่วโมง',
    d: '1 วัน',
    dd: '%d วัน',
    M: '1 เดือน',
    MM: '%d เดือน',
    y: '1 ปี',
    yy: '%d ปี',
  },
  ordinal: (n) => {
    const s = ['th', 'st', 'nd', 'rd'];
    const v = n % 100;
    return `[${n}${s[(v - 20) % 10] || s[v] || s[0]}]`;
  },
};

dayjs.locale(locale, null, true);
export default locale;
