import { useState, useEffect, useCallback } from 'react';
import OrderList from './OrderList';
import DetailDrawer from './DetailDrawer';
import ExportPopup from '../../components/ExportPopup';

const View = ({
  parameters,
  updateParams,
  list,
  isLoadingList,
  getInvoiceList,
  voidInvoice,
  detail,
  isLoadingDetail,
  getInvoiceDetail,
  resetInvoiceDetail,
  pagination,
  channels,
  getChannels,
  exportInvoice,
  isExportPopupOpen,
  setIsExportPopupOpen,
}) => {
  const [detailId, setDetailId] = useState(null);

  useEffect(() => {
    getChannels();
  }, [getChannels]);

  const onClickClose = useCallback(() => {
    setDetailId(null);
  }, []);

  const onClosePopup = useCallback(() => {
    setIsExportPopupOpen(false);
  }, [setIsExportPopupOpen]);

  const onConfirmPopup = useCallback(
    ({ rangeType, range }) => {
      exportInvoice({ rangeType, parameters, range, list });
      setIsExportPopupOpen(false);
    },
    [exportInvoice, setIsExportPopupOpen, parameters, list],
  );

  useEffect(() => {
    getChannels();
  }, [getChannels]);

  useEffect(() => {
    if (detailId) {
      const [invoiceNumber, period] = detailId.split('-');
      getInvoiceDetail({ invoiceNumber, period });
    }
    return () => {
      resetInvoiceDetail();
    };
  }, [detailId, getInvoiceDetail, resetInvoiceDetail]);

  return (
    <>
      <OrderList
        parameters={parameters}
        updateParams={updateParams}
        detailId={detailId}
        onChangeDetailId={setDetailId}
        list={list}
        isLoadingList={isLoadingList}
        getInvoiceList={getInvoiceList}
        voidInvoice={voidInvoice}
        pagination={pagination}
        channels={channels}
      />
      {detailId && (
        <DetailDrawer
          detail={detail}
          isLoading={isLoadingDetail}
          onClickClose={onClickClose}
        />
      )}
      {isExportPopupOpen && (
        <ExportPopup onClose={onClosePopup} onSubmit={onConfirmPopup} />
      )}
    </>
  );
};

export default View;
