import { memo } from 'react';
import { Button } from '@shopline/dashboard-ui';
import { useTranslation } from 'react-i18next';

import { E2E_CETUSTEK_BY_ORDER_PREFIX } from '../../constants';

const E2E_PREFIX = `${E2E_CETUSTEK_BY_ORDER_PREFIX}title-button-section-`;

const TitleButtonSection = ({ onClickExport, exportDisabled }) => {
  const { t } = useTranslation();
  return (
    <Button.Default
      e2eId={`${E2E_PREFIX}cancel-btn`}
      onClick={onClickExport}
      disabled={exportDisabled}
    >
      {t('invoiceManagement:Export')}
    </Button.Default>
  );
};

export default memo(TitleButtonSection);
