import axios from 'axios';
import dayjs from 'dayjs';
import decamelizeKeysDeep from 'decamelize-keys-deep';
import { merge, pick } from 'lodash';
import { createCancelToken } from 'utils/api';

export const GET_INVOICE_LIST_REQUEST = 'GET_INVOICE_LIST_REQUEST';
export const GET_INVOICE_LIST_SUCCESS = 'GET_INVOICE_LIST_SUCCESS';
export const GET_INVOICE_LIST_FAIL = 'GET_INVOICE_LIST_FAIL';

export const getInvoiceListRequest = () => ({
  type: GET_INVOICE_LIST_REQUEST,
});

export const getInvoiceListSuccess = (data = {}) => ({
  type: GET_INVOICE_LIST_SUCCESS,
  payload: { data },
});

export const getInvoiceListFailed = () => ({
  type: GET_INVOICE_LIST_FAIL,
});

let cancelTokenSource = null;

export const cookApiSearchParams = ({ startDate, endDate, ...restParams }) => {
  const dashCaseParams = decamelizeKeysDeep(restParams);
  const searchParams = merge(
    pick(dashCaseParams, [
      'invoice_status',
      'invoice_type',
      'channel',
      'query',
      'field',
      'page',
    ]),
    {
      per_page: dashCaseParams.limit,
    },
  );
  const dateQuery =
    startDate && endDate
      ? {
          start_date: dayjs(startDate).format('YYYY-MM-DD'),
          end_date: dayjs(endDate).format('YYYY-MM-DD'),
        }
      : {};
  return merge(searchParams, dateQuery);
};

export const getInvoiceList =
  (parameters) => async (dispatch, getState, context) => {
    const { api } = context;

    cancelTokenSource = createCancelToken(cancelTokenSource);

    dispatch(getInvoiceListRequest());
    try {
      const params = cookApiSearchParams(parameters);
      const { data } = await api('/invoices', {
        cancelToken: cancelTokenSource.token,
        params,
      });
      cancelTokenSource = null;
      dispatch(getInvoiceListSuccess(data));
    } catch (e) {
      if (axios.isCancel(e)) return;
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.error(e);
      }

      dispatch(getInvoiceListFailed());
    }
  };
