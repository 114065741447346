import { has } from 'lodash-es';
import { CREATE_UI_TOAST, DISMISS_UI_TOAST, DELETE_UI_TOAST, CLEAR_UI_TOAST } from '../actions';

const initialState = {};

const toasts = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_UI_TOAST:
      return {
        ...state,
        ...action.payload.toast,
      };

    case DISMISS_UI_TOAST: {
      const { id: targetId } = action.payload;
      const isExist = has(state, targetId);
      return isExist
        ? {
            ...state,
            [targetId]: {
              ...state[targetId],
              isShow: false,
            },
          }
        : state;
    }
    case DELETE_UI_TOAST:
      delete state[action.payload.id];
      return state;

    case CLEAR_UI_TOAST:
      return initialState;

    default:
      return state;
  }
};

export default toasts;
