import styled from 'styled-components';
import prop from 'lodash/fp/prop';
import PriceText from 'components/PriceText';

const Discount = styled.div.attrs({
  role: 'button',
})`
  flex: 1 0 0;
  margin-right: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 1.75rem;
  &:hover {
    background-color: ${prop('theme.colors.PRIMARY_200')};
  }

  & > ${PriceText} {
    flex: 0 0 6rem;
    margin-left: 0.5rem;
    text-align: right;
  }
`;

export default Discount;
