import { useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Table } from '@shopline/dashboard-ui';
import { isFinite, noop } from 'lodash-es';

import LoadingRows from 'components/Table/LoadingRows';
import WelcomeBox from 'components/Table/Welcomebox';

import useInvoiceTableConfig from '../hooks/useInvoiceTableConfig';

const StyledTableWrapper = styled.div`
  cursor: pointer;
  & thead th:first-child,
  & tbody td:first-child {
    padding-left: 1.5rem;
  }
  ${({ activeRowIndex, theme }) =>
    isFinite(activeRowIndex) &&
    activeRowIndex !== -1 &&
    `
      tr:nth-child(${activeRowIndex + 1}) td {
        background-color: ${theme.colors.PRIMARY_200};
      }
  `}
`;

const InvoiceTable = ({
  E2E_FEATURE_PREFIX = '',
  isLoading = false,
  isEmpty = false,
  tableData,
  removeDisabled,
  onTableRowClick = noop,
  onClickRemove,
  excludeColumnKeys = [],
  activeRowId,
}) => {
  const { t } = useTranslation();

  const { activeRowIndex, tablePropColumns, dataSource } =
    useInvoiceTableConfig({
      tableData,
      removeDisabled,
      onClickRemove,
      excludeColumnKeys,
      activeRowId,
    });

  const handleRowClick = useCallback(
    (e, rowData) => {
      e.stopPropagation();
      onTableRowClick(rowData.id);
    },
    [onTableRowClick],
  );

  return (
    <StyledTableWrapper activeRowIndex={activeRowIndex}>
      <Table
        e2eId={`${E2E_FEATURE_PREFIX}by-order-invoice-table`}
        columns={tablePropColumns}
        dataSource={isLoading ? [] : dataSource}
        hoverable
        rowHeight={3.5625}
        onTableRowClick={handleRowClick}
      />
      {isEmpty && (
        <WelcomeBox
          welcomeImg="empty.svg"
          welcomeTitle={t('invoiceManagement:No Invoice Record')}
          welcomeSubtitle={t('invoiceManagement:No Invoice Record Description')}
          imgMaxWidth="25.5rem"
        />
      )}
      {isLoading && (
        <LoadingRows withAvatar rowNumber={10} rowHeight="3.5625rem" />
      )}
    </StyledTableWrapper>
  );
};

export default InvoiceTable;
