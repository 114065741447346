import PropTypes from 'prop-types';

const PageHeaderPropTypes = {
  titleLeftSection: PropTypes.node,
  titleRightSection: PropTypes.node,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      label: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      e2eId: PropTypes.string,
    }),
  ),
};

export default PageHeaderPropTypes;
