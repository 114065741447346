import { useMemo } from 'react';
import { media, Toast as UIToast } from '@shopline/dashboard-ui';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useSelector, useDispatch } from 'react-redux';
import { dismissUIToast, deleteUIToast } from '../../stores/toasts/actions';

const ToastContainer = styled.div`
  position: fixed;
  right: 1.5rem;
  top: 1.5rem;
  z-index: 50;
  ${media.mobile`
    left: 0;
    right: 0;
    top: 1.5rem;
  `}
`;

const toastMap = {
  alert: UIToast.Alert,
  information: UIToast.Information,
  success: UIToast.Success,
  warning: UIToast.Warning,
};

const ToastList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toasts = useSelector((state) => state.toasts);

  const hasToast = useMemo(() => Object.keys(toasts).length > 0, [toasts]);

  const handleRemoveClick = (id) => () => {
    dispatch(dismissUIToast(id));
  };

  const handleOnAnimationEnd = (id) => () => {
    if (toasts[id] && !toasts[id].isShow) {
      dispatch(deleteUIToast(id));
    }
  };

  const generateToastTitle = (toast) => {
    if (toast.titleWithParams) {
      const { key, params } = toast.titleWithParams;
      return t(key, params);
    }
    if (toast.title) {
      return t(toast.title);
    }
    return '';
  };

  const generalToastDescription = (toast) => {
    if (toast.descriptionNodeWithProps) {
      const { node: Description, props = {} } = toast.descriptionNodeWithProps;
      return <Description {...props} />;
    }
    if (toast.descriptionWithParams) {
      const { key, params } = toast.descriptionWithParams;
      return t(key, params);
    }
    if (toast.description) return t(toast.description);
    return undefined;
  };

  if (!hasToast) return null;

  return (
    <ToastContainer>
      {Object.keys(toasts).map((id, idx) => {
        const toast = toasts[id];
        const Toast = toastMap[toast.type];
        if (!Toast) return null;
        return (
          <Toast
            e2eId={`alert ${idx + 1}`}
            key={id}
            title={generateToastTitle(toast)}
            description={generalToastDescription(toast)}
            isShow={toast.isShow}
            onClick={handleRemoveClick(id)}
            onAnimationEnd={handleOnAnimationEnd(id)}
          />
        );
      })}
    </ToastContainer>
  );
};

export default ToastList;
