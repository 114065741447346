import { Text } from '@shopline/dashboard-ui';
import { useTranslation } from 'react-i18next';

const TextLabel = (props) => {
  const { i18n } = useTranslation();
  const { language } = i18n;
  return <Text lang={language} {...props} />;
};

export default TextLabel;
