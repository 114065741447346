import axios from 'axios';
import { createCancelToken } from '../../../../utils/api';

export const GET_INVOICE_DETAIL_REQUEST = 'GET_INVOICE_DETAIL_REQUEST';
export const GET_INVOICE_DETAIL_SUCCESS = 'GET_INVOICE_DETAIL_SUCCESS';
export const GET_INVOICE_DETAIL_FAIL = 'GET_INVOICE_DETAIL_FAIL';
export const RESET_INVOICE_DETAIL = 'RESET_INVOICE_DETAIL';

export const getInvoiceDetailRequest = () => ({
  type: GET_INVOICE_DETAIL_REQUEST,
});

export const getInvoiceDetailSuccess = (data = {}) => ({
  type: GET_INVOICE_DETAIL_SUCCESS,
  payload: { data },
});

export const getInvoiceDetailFailed = () => ({
  type: GET_INVOICE_DETAIL_FAIL,
});

export const resetInvoiceDetail = () => ({
  type: RESET_INVOICE_DETAIL,
});

let cancelTokenSource = null;

export const getInvoiceDetail =
  ({ invoiceNumber, period }) =>
  async (dispatch, getState, context) => {
    const { api } = context;
    cancelTokenSource = createCancelToken(cancelTokenSource);

    dispatch(getInvoiceDetailRequest());
    try {
      const { data } = await api(`/invoices/${period}/${invoiceNumber}`, {
        cancelToken: cancelTokenSource.token,
      });
      cancelTokenSource = null;
      dispatch(getInvoiceDetailSuccess(data));
    } catch (e) {
      if (axios.isCancel(e)) return;
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.error(e);
      }

      dispatch(getInvoiceDetailFailed());
    }
  };
