import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { media, Button, TextButton } from '@shopline/dashboard-ui';

const Container = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.SNOW_200};

  ${media.mobile`
    padding: 1rem;
  `};
`;

const ModeAllButton = styled(TextButton.Primary)`
  order: 1;
  margin-right: auto;
`;

const CancelButton = styled(Button.Default)`
  margin-right: 1rem;
`;

const Footer = ({
  modeAll,
  onModeAllToggle,
  selectedCount,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation();
  return (
    <Container>
      <ModeAllButton onClick={onModeAllToggle}>
        {modeAll
          ? t('invoiceManagement:View Selected Products', {
              count: selectedCount,
            })
          : t('invoiceManagement:View All Products')}
      </ModeAllButton>
      <Button.Primary onClick={onSubmit} disabled={selectedCount === 0}>
        {t('invoiceManagement:Import')}
      </Button.Primary>
      <CancelButton onClick={onCancel}>{t('common:Cancel')}</CancelButton>
    </Container>
  );
};

export default Footer;
