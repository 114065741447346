import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getPriceDisplay } from 'components/PriceText';
import styled from 'styled-components';
import Time from '../components/Time';

export const COLUMN_KEYS = {
  INVOICE_DATE: 'invoiceDate',
  INVOICE_RANGES: 'invoiceRanges',
  TAXABLE_AMOUNT: 'taxableAmount',
  FREE_TAX_AMOUNT: 'freeTaxAmount',
  ZERO_TAX_AMOUNT: 'zeroTaxAmount',
  CANCELLED_INVOICES: 'cancelledInvoices',
};

const InvoiceText = styled.div`
  line-height: 1.25rem;
`;
const InvoiceRanges = ({ ranges }) =>
  ranges.map((range, index) => (
    <InvoiceText key={index}>{range.join(' ~ ')}</InvoiceText>
  ));

const CancelledInvoices = ({ invoices }) =>
  invoices.length
    ? invoices.map((invoice, index) => (
        <InvoiceText key={index}>{`${invoice}${
          index + 1 !== invoices.length ? ',' : ''
        }`}</InvoiceText>
      ))
    : '-';

const useByDayInvoiceTableConfig = ({ tableData, excludeColumnKeys }) => {
  const { t } = useTranslation();
  const tablePropColumns = useMemo(
    () =>
      [
        {
          key: COLUMN_KEYS.INVOICE_DATE,
          title: t('invoiceManagement:Invoice Date'),
          width: 10,
        },
        {
          key: COLUMN_KEYS.INVOICE_RANGES,
          title: t('invoiceManagement:Start - End Number'),
          width: 13,
        },
        {
          key: COLUMN_KEYS.TAXABLE_AMOUNT,
          title: t('invoiceManagement:Total Taxable Invoice Amount'),
          width: 10,
        },
        {
          key: COLUMN_KEYS.FREE_TAX_AMOUNT,
          title: t('invoiceManagement:Total Tax Exempt Invoice Amount'),
          width: 10,
        },
        {
          key: COLUMN_KEYS.ZERO_TAX_AMOUNT,
          title: t('invoiceManagement:Total Zero-Tax-Rate Invoice Amount'),
          width: 10,
        },
        {
          key: COLUMN_KEYS.CANCELLED_INVOICES,
          title: t('invoiceManagement:Void Invoice Number'),
          width: 10,
        },
      ].filter((column) => !excludeColumnKeys.includes(column.key)),
    [t, excludeColumnKeys],
  );

  const dataSource = useMemo(
    () =>
      tableData.map((invoiceData) => {
        const {
          invoice_date: invoiceDate,
          invoice_ranges: invoiceRanges,
          cancelled_invoices: cancelledInvoices,
          total_tax_amount: taxAmount,
          total_sales_amount: salesAmount,
          total_free_tax_amount: freeTaxAmount,
          total_zero_tax_amount: zeroTaxAmount,
        } = invoiceData;

        const data = {
          id: invoiceDate,
          invoiceDate: <Time time={invoiceDate} format="LL" />,
          invoiceRanges: <InvoiceRanges ranges={invoiceRanges} />,
          cancelledInvoices: <CancelledInvoices invoices={cancelledInvoices} />,
          taxableAmount: getPriceDisplay(taxAmount + salesAmount),
          freeTaxAmount: getPriceDisplay(freeTaxAmount),
          zeroTaxAmount: getPriceDisplay(zeroTaxAmount),
        };

        return data;
      }),
    [tableData],
  );

  return {
    tablePropColumns,
    dataSource,
  };
};

export default useByDayInvoiceTableConfig;
