import { memo } from 'react';
import { Icons } from '@shopline/dashboard-ui';
import styled from 'styled-components';
import Dropdown from '../Dropdown';
import TextLabel from '../TextLabel';

const SelectorContainer = styled.span`
  cursor: pointer;
  > span {
    margin-right: 0.25rem;
  }
  > svg {
    transition: transform 0.3s ease-in-out;
  }
`;

const TextLabelDropdown = (props) => (
  <Dropdown
    {...props}
    value={[props.value]}
    renderSelector={({ isOpen, onSelectorClick }) => {
      const { value, options } = props;
      return (
        <SelectorContainer onClick={onSelectorClick}>
          <TextLabel fontType="DisplayLarge" color="INK_800">
            {options.find((option) => option.value === value)?.label}
          </TextLabel>
          <Icons.Outline.Chevron size="SMALL" position={isOpen ? 'TOP' : 'BOTTOM'} />
        </SelectorContainer>
      );
    }}
  />
);

export default memo(TextLabelDropdown);
