import { useCallback, useState, useMemo, useEffect, memo } from 'react';
import { useTranslation } from 'react-i18next';
import useScreenType from 'hooks/useScreenType';
import DropdownSearchComponent from 'components/DropdownSearch';
import { getTargetConfig } from 'utils/getFilterConfig';
import { searchTypeConfig } from '../filterConfig';

import { E2E_BY_ORDER_PREFIX } from '../constants';

const E2E_PREFIX = `${E2E_BY_ORDER_PREFIX}dropdown-search-`;

const DropdownSearch = ({ field, query, onSearch, ...restProps }) => {
  const { t } = useTranslation();
  const [searchField, setSearchField] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const screenType = useScreenType();

  const updateSearchField = useCallback((values) => {
    setSearchField(values[0]);
    setSearchValue('');
  }, []);

  const updateInputValue = useCallback((value) => {
    setSearchValue(value);
  }, []);

  const dropdownConfig = useMemo(
    () => ({
      width: screenType !== 'mobile' ? '7.5rem' : '6rem',
      placeHolder: t('common:Search'),
      onChange: updateSearchField,
      options: searchTypeConfig.map((config) => ({
        ...config,
        label: t(config.label),
      })),
      e2eId: `${E2E_PREFIX}dropdown`,
    }),
    [t, updateSearchField, screenType],
  );

  const inputConfig = useMemo(
    () => ({
      placeholder: t('common:Search'),
      ...(screenType !== 'mobile'
        ? {
            width: '14.5625rem',
          }
        : {
            block: true,
          }),
      tooltipText: t('common:Please Enter', {
        field: t(getTargetConfig(searchTypeConfig, searchField, 'label')),
      }),
      status: 'valid',
      onChange: updateInputValue,
      buttonTooltipLabel: t('common:Search'),
      e2eInputId: `${E2E_PREFIX}input`,
    }),
    [t, searchField, updateInputValue, screenType],
  );

  useEffect(() => {
    if (field && query) {
      setSearchField(field);
      setSearchValue(query);
    } else {
      setSearchField(dropdownConfig.options[0].value);
      setSearchValue('');
    }
  }, [field, query, dropdownConfig]);

  return (
    <DropdownSearchComponent
      onSearch={onSearch}
      searchValue={searchValue}
      searchField={searchField}
      input={inputConfig}
      dropdown={dropdownConfig}
      {...restProps}
    />
  );
};

export default memo(DropdownSearch);
