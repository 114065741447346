import { useState, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import { pick } from 'lodash-es';
import { InputWithSuffixIcon as UIInputWithSuffixIcon, Icons, Theme } from '@shopline/dashboard-ui';

import { DropdownPropTypes } from '../../types/dropdown';
import CustomSelector from './CustomSelector';
import Dropdown from '../Dropdown';
import TooltipSelectorWrapper from '../Dropdown/TooltipSelectorWrapper';

const SearchWrapper = styled.div`
  display: flex;
`;

const InputWrapper = styled.div`
  flex-grow: 1;
`;

const getMinHeight = (inputSize) => {
  // add 0.125(input border) to be as tall as Input
  const height = parseFloat(Theme.inputs.size[inputSize].height) + 0.125;
  return `${height.toString()}rem`;
};

const DropdownSearch = ({ className, inputSize = 'MEDIUM', searchField, searchValue, dropdown, input, onSearch }) => {
  const selectorRef = useRef(null);
  const { options, placeHolder, ...restDropdown } = dropdown;
  const inputRef = useRef(null);
  const [isInputHover, setIsInputHover] = useState(false);

  const onKeyPressInput = (e) => {
    if (e.keyCode === 13 || e.key === 'Enter') onSearch({ searchField, searchValue });
  };

  const handleValueToLabel = (value) => {
    const currentOption = options.find((option) => option.value === value);
    if (currentOption) {
      return `${currentOption.label}`;
    }
    return '';
  };

  const onClickSearch = () => {
    onSearch({ searchField, searchValue });
  };

  const onMouseEnter = useCallback(
    (e) => {
      setIsInputHover(true);
      if (input.onMouseEnter) onMouseEnter(e);
    },
    [input.onMouseEnter],
  );
  const onMouseLeave = useCallback(
    (e) => {
      setIsInputHover(false);
      if (input.onMouseLeave) onMouseLeave(e);
    },
    [input.onMouseLeave],
  );

  const renderTooltipSelector = (selectorProps) => (
    <TooltipSelectorWrapper width={dropdown.width} selectorRef={selectorRef} text={handleValueToLabel(searchField)}>
      <CustomSelector
        ref={selectorRef}
        onClick={selectorProps.onSelectorClick}
        isOpen={selectorProps.isOpen}
        placeHolder={placeHolder}
        width={dropdown.width}
        minHeight={getMinHeight(inputSize)}
        contentPadding={dropdown.contentPadding}
        currentLabel={handleValueToLabel(searchField)}
        disabledShadow
      />
    </TooltipSelectorWrapper>
  );

  return (
    <SearchWrapper className={className}>
      <Dropdown
        options={options}
        value={[searchField]}
        placeHolder={placeHolder}
        renderSelector={renderTooltipSelector}
        {...restDropdown}
      />
      <InputWrapper>
        <UIInputWithSuffixIcon
          ref={inputRef}
          block
          status="valid"
          value={searchValue}
          Icon={Icons.Outline.Search}
          inputSize={inputSize}
          borderRadius="0px 6px 6px 0px"
          onKeyPress={onKeyPressInput}
          onButtonClick={onClickSearch}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          isInputTooltipShow={isInputHover}
          {...input}
        />
      </InputWrapper>
    </SearchWrapper>
  );
};

DropdownSearch.propTypes = {
  className: PropTypes.string,
  inputSize: PropTypes.oneOf(['SMALL', 'MEDIUM', 'LARGE']),
  searchField: PropTypes.string.isRequired,
  searchValue: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
  dropdown: PropTypes.shape(pick(DropdownPropTypes, ['width', 'placeHolder', 'onChange', 'options'])).isRequired,
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    width: PropTypes.string,
    block: PropTypes.bool,
    tooltipText: PropTypes.string,
    buttonTooltipLabel: PropTypes.string,
  }),
};

export default DropdownSearch;
