import React, { useState, useCallback, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { Popover as TinyPopover, ArrowContainer } from 'react-tiny-popover';
import { negate, identity } from 'lodash-es';
import DiscountEditor from './DiscountEditor';

const DiscountEditorPopover = ({
  children,
  onSubmit,
  container: Container,
  popoverProps,
  ...props
}) => {
  const [show, setShow] = useState(false);
  const themeContext = useContext(ThemeContext);
  const togglePopover = useCallback(() => {
    setShow(negate(identity));
  }, []);
  const hidePopover = useCallback(() => {
    setShow(false);
  }, []);
  const handleSubmit = useCallback(
    (discount) => {
      discount.name = discount.name.trim();
      onSubmit(discount);
      hidePopover();
    },
    [onSubmit, hidePopover],
  );
  return (
    <TinyPopover
      isOpen={show}
      positions={['bottom', 'top']}
      padding={8}
      onClickOutside={hidePopover}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor={themeContext.colors.SNOW_100}
          arrowSize={8}
        >
          <DiscountEditor
            {...props}
            onSubmit={handleSubmit}
            onCancel={hidePopover}
          />
        </ArrowContainer>
      )}
      {...popoverProps}
    >
      <Container>{children({ togglePopover })}</Container>
    </TinyPopover>
  );
};

export default DiscountEditorPopover;
