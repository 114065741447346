import { useCallback } from 'react';
import styled, { css } from 'styled-components';
import prop from 'lodash/fp/prop';
import gt from 'lodash/fp/gt';
import { Icons } from '@shopline/dashboard-ui';
import { StyledSvg } from '@shopline/dashboard-ui/dist/esm/components/icons/Icon';

export const Container = styled.div`
  display: flex;
`;

const Button = styled.div.attrs({
  role: 'button',
})`
  height: 1.75rem;
  width: 1.75rem;
  display: flex;
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  & > ${StyledSvg} {
    color: inherit;
    fill: currentColor;
  }
`;

const disabledMixin = css`
  color: ${prop('theme.colors.INK_400')};
  cursor: not-allowed;
`;

const MinusButton = styled(Button)`
  border-radius: 0.375rem 0 0 0.375rem;
  color: ${prop('theme.colors.INK_600')};
  background-color: ${prop('theme.colors.SNOW_100')};
  border-color: ${prop('theme.colors.INK_300')};
  ${({ disabled }) => disabled && disabledMixin}
`;

const PlusButton = styled(Button)`
  border-radius: 0 0.375rem 0.375rem 0;
  color: ${prop('theme.colors.SNOW_100')};
  background-color: ${prop('theme.colors.PRIMARY_600')};
  border-color: ${prop('theme.colors.PRIMARY_600')};
`;

const Input = styled.input`
  height: 1.75rem;
  width: 2em;
  border-width: 1px 0;
  border-style: solid;
  border-color: ${prop('theme.colors.INK_300')};
  text-align: center;
  outline: none;
  border-radius: 0;
  color: ${prop('theme.colors.INK_800')};
  font-size: 0.875rem;
`;

const gt1 = gt(1);

const QtyInput = ({ value, onChange, onIncrement, e2eId }) => {
  const handleMinus = useCallback(() => {
    onIncrement(-1);
  }, [onIncrement]);
  const handlePlus = useCallback(() => {
    onIncrement(1);
  }, [onIncrement]);
  const handleChange = useCallback(
    (event) => {
      const parsedValue = parseInt(event.target.value, 10);
      onChange(Number.isNaN(parsedValue) || gt1(parsedValue) ? 1 : parsedValue);
    },
    [onChange],
  );
  return (
    <Container>
      <MinusButton
        disabled={value === 1}
        onClick={handleMinus}
        data-e2e-id={`${e2eId}-minus-btn`}
      >
        <Icons.Outline.Minus size="XSMALL" />
      </MinusButton>
      <Input
        type="number"
        min="1"
        value={value}
        onChange={handleChange}
        data-e2e-id={`${e2eId}-input`}
      />
      <PlusButton onClick={handlePlus} data-e2e-id={`${e2eId}-plus-btn`}>
        <Icons.Outline.Plus size="XSMALL" />
      </PlusButton>
    </Container>
  );
};

export default QtyInput;
