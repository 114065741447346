import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from '@shopline/dashboard-ui';
import Text from 'components/Text';
import { withProps } from 'utils/recompose';

const Container = styled.div`
  margin-top: 1rem;
  flex: 1 1 0;
  &:not(:first-child) {
    margin-left: 0.5rem;
  }
  ${media.mobile`
    flex: 1 0 100%;
    width: 100%;
    &:not(:first-child) {
      margin-left: 0;
    }
  `}
`;

const Heading = withProps({ as: 'h4', fontType: 'Caption', color: 'INK_800' })(
  Text,
);

const StyledText = styled(Heading)`
  margin-bottom: 0.25rem;
  display: block;
`;

const Field = ({ title, children }) => (
  <Container>
    <StyledText>{title}</StyledText>
    {children}
  </Container>
);
Field.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Field;
