import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { actions } from 'stores/invoiceManagement/actions/tradevanCreate/personalCarrier';
import { digitalCertificateRule } from '../../validateRules';
import { submitKeySelector } from '../../selectors/main';
import { Field, FormInput } from '../../components';
import PARENT_E2E_PREFIX from './e2ePrefix';

const E2E_PREFIX = `${PARENT_E2E_PREFIX}-digital-certificate`;

const DigitalCertificate = ({ carrierValue }) => {
  const { t } = useTranslation();
  const submitKey = useSelector(submitKeySelector);
  const dispatch = useDispatch();
  const handleChange = useCallback(
    ({ target: { value } }) => {
      dispatch(actions.setValue(value));
    },
    [dispatch],
  );
  return (
    <Field title={`${t('invoiceManagement:NPC Barcode')}*`}>
      <FormInput
        block
        value={carrierValue}
        onChange={handleChange}
        e2eInputId={`${E2E_PREFIX}-input`}
        e2eMessagesId={`${E2E_PREFIX}-errors`}
        trimStart
        trimEnd
        rule={digitalCertificateRule}
        fieldName={t('invoiceManagement:NPC Barcode')}
        validateKey={submitKey}
      />
    </Field>
  );
};

export default DigitalCertificate;
