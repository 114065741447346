import { useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import { getByDayList } from 'stores/invoiceManagement/actions/cetustekByDayList';
import { getChannels } from 'stores/channels/actions/getChannels';
import useSearch from '../../hooks/useSearch';
import { byDayDefaultStart, byDayDefaultEnd } from '../../constants';

export const baseParameters = {
  page: '1',
  limit: '25',
  startDate: byDayDefaultStart,
  endDate: byDayDefaultEnd,
};

const useProps = () => {
  const { cetustekByDayList } = useSelector((state) => state.invoiceManagement);
  const channels = useSelector((state) => state.channels);
  const dispatch = useDispatch();
  const { parameters, updateParams } = useSearch(baseParameters);

  const computedState = useMemo(
    () => ({
      parameters,
      ...channels,
      ...cetustekByDayList,
    }),
    [parameters, channels, cetustekByDayList],
  );

  const handlers = useMemo(
    () => ({
      updateParams,
      getByDayList: bindActionCreators(getByDayList, dispatch),
      getChannels: bindActionCreators(getChannels, dispatch),
    }),
    [dispatch, updateParams],
  );

  return useMemo(
    () => ({ ...computedState, ...handlers }),
    [computedState, handlers],
  );
};

export default useProps;
