import { useState, useEffect } from 'react';
import { debounce } from 'lodash-es';

const getSize = () => ({
  width: window.innerWidth,
  height: window.innerHeight,
});

export const useWindowSize = ({ wait = 100 } = {}) => {
  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    const handleResize = debounce(() => {
      setWindowSize(getSize());
    }, wait);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [wait]);

  return windowSize;
};
