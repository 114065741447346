import { useEffect, useCallback, memo } from 'react';
import styled from 'styled-components';

import { TRADEVAN } from 'constants/vendor';
import Paginator from 'components/Paginator';

import { fixedChannelConfig } from '../../filterConfig';
import ByDayFilterSection from '../../components/ByDayFilterSection';
import ByDayTagPanelSection from '../../components/ByDayTagPanelSection';
import ByDayInvoiceTable from '../../components/ByDayInvoiceTable';

import { E2E_BY_DAY_PREFIX } from '../../constants';

const TableContentWrapper = styled.div`
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.SNOW_200};
`;

const PaginatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
`;

const DayList = ({
  parameters,
  updateParams,
  list,
  isLoadingList,
  getByDayList,
  pagination,
  channels,
}) => {
  const onPageChange = useCallback(
    (page) => {
      updateParams({ page: page.toString() });
    },
    [updateParams],
  );

  useEffect(() => {
    getByDayList(parameters);
  }, [parameters, getByDayList]);

  return (
    <>
      <ByDayFilterSection
        e2eFeaturePrefix={E2E_BY_DAY_PREFIX}
        vendorType={TRADEVAN}
        fixedChannelConfig={fixedChannelConfig}
        channels={channels}
        parameters={parameters}
        updateParams={updateParams}
      />
      <ByDayTagPanelSection
        e2eFeaturePrefix={E2E_BY_DAY_PREFIX}
        vendorType={TRADEVAN}
        channels={channels}
        parameters={parameters}
        updateParams={updateParams}
      />
      <TableContentWrapper>
        <ByDayInvoiceTable
          e2eFeaturePrefix={E2E_BY_DAY_PREFIX}
          isEmpty={pagination && pagination.total_count === 0}
          isLoading={isLoadingList}
          tableData={list}
        />
      </TableContentWrapper>
      {!isLoadingList && pagination && pagination.total_pages > 1 && (
        <PaginatorWrapper>
          <Paginator
            totalCount={pagination.total_count}
            onPageChange={onPageChange}
            page={pagination.current_page}
            limit={pagination.per_page}
          />
        </PaginatorWrapper>
      )}
    </>
  );
};

export default memo(DayList);
