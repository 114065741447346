import dayjs from 'utils/day';
import { useTranslation } from 'react-i18next';
import { E2E_FEATURE_PREFIX } from '../constants';

const Time = ({ time, format = 'LLL' }) => {
  const { i18n } = useTranslation();
  const { language } = i18n;
  return (
    <span data-e2e-id={`${E2E_FEATURE_PREFIX}time`}>
      {time ? dayjs(language, time).format(format) : '-'}
    </span>
  );
};
export default Time;
