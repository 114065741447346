const IconCheck = (props) => (
  <svg
    aria-hidden="true"
    focusable="false"
    viewBox="0 0 13 10"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.5024 1.89062C12.6483 2.03646 12.7212 2.21354 12.7212 2.42187C12.7212 2.63021 12.6483 2.80729 12.5024 2.95312L6.84619 8.60937L5.78369 9.67187C5.63786 9.81771 5.46077 9.89062 5.25244 9.89062C5.04411 9.89062 4.86702 9.81771 4.72119 9.67187L3.65869 8.60937L0.830566 5.78125C0.684733 5.63542 0.611816 5.45833 0.611816 5.25C0.611816 5.04167 0.684733 4.86458 0.830566 4.71875L1.89307 3.65625C2.0389 3.51042 2.21598 3.4375 2.42432 3.4375C2.63265 3.4375 2.80973 3.51042 2.95557 3.65625L5.25244 5.96094L10.3774 0.828125C10.5233 0.682291 10.7004 0.609375 10.9087 0.609375C11.117 0.609375 11.2941 0.682291 11.4399 0.828125L12.5024 1.89062Z"
      fill="currentColor"
    />
  </svg>
);

export default IconCheck;
