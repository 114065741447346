import { iteratee } from 'lodash-es';
import ExportToastMessage from 'features/ToastList/components/ExportToastMessage';
import { showUIToast } from '../../../toasts/actions';

export const EXPORT_INVOICE_REQUEST = 'EXPORT_INVOICE_REQUEST';

export const exportInvoice =
  ({ rangeType, range, list }) =>
  async (dispatch, getState, context) => {
    try {
      const apiParams =
        rangeType === 'CURRENT'
          ? {
              export_type: 'by-ids',
              ids: list.map(iteratee('_id')),
            }
          : {
              export_type: 'by-date-range',
              start_date: range.start_date,
              end_date: range.end_date,
            };
      const { data } = await context.api.post('/invoices/export', apiParams);
      dispatch(
        showUIToast({
          type: 'success',
          title: 'invoiceManagement:Exporting Your Invoice Report',
          descriptionNodeWithProps: {
            node: ExportToastMessage,
            props: {
              receiverEmail: data.receiverEmail,
            },
          },
        }),
      );
    } catch (e) {
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.error(e);
      }
      dispatch(
        showUIToast({
          type: 'warning',
          title: 'invoiceManagement:Failed to export',
        }),
      );
    }
  };
