import axios from 'axios';

function createApi() {
  return axios.create({
    baseURL: '/api',
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  });
}

function createCancelToken(cancelTokenSource) {
  if (cancelTokenSource) cancelTokenSource.cancel();
  const { CancelToken } = axios;
  return CancelToken.source();
}

export default createApi;
export { createCancelToken };
