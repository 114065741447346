import { actionTypes } from '../../actions/tradevanCreate/companyInfo';

export const initialState = {
  title: '',
  uniformNumber: '',
  address: '',
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case actionTypes.SET_FIELD_VALUE:
      return {
        ...state,
        [payload[0]]: payload[1],
      };
    default:
      return state;
  }
}
