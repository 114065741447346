import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { curryRight, trim } from 'lodash-es';
import { IconButton, Icons } from '@shopline/dashboard-ui';
import { actions } from 'stores/invoiceManagement/actions/tradevanCreate/invoiceItems';
import { withProps } from 'utils/recompose';
import PARENT_E2E_PREFIX from './e2ePrefix';
import { itemNameRule } from './validateRules';
import { submitKeySelector } from './selectors/main';
import { itemSelector } from './selectors/invoiceItems';
import { FormInput, ItemContainer, PriceInput, QtyInput } from './components';

const E2E_PREFIX = `${PARENT_E2E_PREFIX}-item-editor`;

const curriedItemSelector = curryRight(itemSelector, 2);

const MediumIconTrash = withProps({ size: 'MEDIUM' })(Icons.Solid.Trash);

const ItemEditor = ({ id }) => {
  const { t } = useTranslation();
  const submitKey = useSelector(submitKeySelector);
  const item = useSelector(curriedItemSelector(id));
  const dispatch = useDispatch();
  const handleNameChange = useCallback(
    ({ target: { value } }) => {
      const trimmedValue = trim(value);
      dispatch(actions.setItemFieldValue(id, 'name', trimmedValue));
    },
    [dispatch, id],
  );
  const handlePriceChange = useCallback(
    (value) => {
      dispatch(actions.setItemFieldValue(id, 'price', value));
    },
    [dispatch, id],
  );
  const handleQtyChange = useCallback(
    (value) => {
      dispatch(actions.setItemFieldValue(id, 'qty', value));
    },
    [dispatch, id],
  );
  const handleIncrementQty = useCallback(
    (delta) => {
      dispatch(actions.incrementItemQty(id, delta));
    },
    [dispatch, id],
  );
  const handleClickTrash = useCallback(() => {
    dispatch(actions.pullItem(id));
  }, [dispatch, id]);
  return (
    <ItemContainer data-e2e-id={`${E2E_PREFIX}-${id}-container`}>
      <FormInput
        inputSize="SMALL"
        width="16.75"
        value={item.name}
        onChange={handleNameChange}
        placeholder={t('invoiceManagement:Enter Product Name')}
        rule={itemNameRule}
        fieldName={t('invoiceManagement:Product Name')}
        e2eInputId={`${E2E_PREFIX}-${id}-name-input`}
        e2eMessagesId={`${E2E_PREFIX}-${id}-name-errors`}
        validateKey={submitKey}
      />
      <PriceInput
        inputSize="SMALL"
        width="4.75"
        min="0"
        value={item.price}
        onChange={handlePriceChange}
        e2eInputId={`${E2E_PREFIX}-${id}-price-input`}
        e2eMessagesId={`${E2E_PREFIX}-${id}-price-errors`}
      />
      <QtyInput
        value={item.qty}
        onChange={handleQtyChange}
        onIncrement={handleIncrementQty}
        e2eId={`${E2E_PREFIX}-${id}-qty-input`}
      />
      <IconButton
        Icon={MediumIconTrash}
        colorType="basic"
        size={1.25}
        handleClick={handleClickTrash}
        tooltipProps={{ text: '', direction: 'TOP_CENTER' }}
        e2eId={`${E2E_PREFIX}-${id}-delete-btn`}
      />
    </ItemContainer>
  );
};

export default ItemEditor;
