import React, { useCallback, useState, useRef, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { negate, identity } from 'lodash-es';
import { Icons } from '@shopline/dashboard-ui';
import { withProps } from 'utils/recompose';
import { actions } from 'stores/invoiceManagement/actions/tradevanCreate/invoiceItems';
import { customTheme } from './utils';
import PARENT_E2E_PREFIX from './e2ePrefix';
import { submitKeySelector, merchantIdSelector } from './selectors/main';
import { itemIdListSelector } from './selectors/invoiceItems';
import {
  AddItemButton,
  ItemList,
  ProductPicker,
  Section,
  SectionCaption,
} from './components';
import ItemEditor from './ItemEditor';

const E2E_PREFIX = `${PARENT_E2E_PREFIX}-items-section`;

const SmallIconProduct = withProps({ size: 'SMALL' })(Icons.Outline.Product);
const SmallIconCirclePlus = withProps({ size: 'SMALL' })(
  Icons.Outline.CirclePlus,
);

const ItemsSection = () => {
  const { t } = useTranslation();
  const submitKey = useSelector(submitKeySelector);
  const submitKeyRef = useRef(submitKey);
  const [showPicker, setShowPicker] = useState(false);
  const itemIdList = useSelector(itemIdListSelector);
  const merchantId = useSelector(merchantIdSelector);
  const hasItems = itemIdList.length !== 0;
  const showNoItem = submitKey !== submitKeyRef.current && !hasItems;
  const dispatch = useDispatch();
  const insertEmpty = useCallback(() => {
    dispatch(actions.insertEmpty());
  }, [dispatch]);
  const handlePickCustomer = useCallback(
    (items) => {
      dispatch(actions.importProducts(items));
      setShowPicker(false);
    },
    [dispatch],
  );
  const handleToggleShowPicker = useCallback(() => {
    setShowPicker(negate(identity));
  }, []);
  useEffect(() => {
    if (hasItems) {
      submitKeyRef.current = submitKey;
    }
  }, [submitKey, hasItems]);
  return (
    <ThemeProvider theme={customTheme}>
      <Section title={t('invoiceManagement:Product Information')}>
        {showNoItem ? (
          <SectionCaption color="RED_600">
            {t('invoiceManagement:at least one product is required')}
          </SectionCaption>
        ) : (
          <SectionCaption>
            {t('invoiceManagement:Product Information Description')}
          </SectionCaption>
        )}
        <ItemList>
          {itemIdList.map((id) => (
            <ItemEditor key={id} id={id} />
          ))}
        </ItemList>
        <AddItemButton
          icon={<SmallIconProduct />}
          data-e2e-id={`${E2E_PREFIX}-import-btn`}
          onClick={handleToggleShowPicker}
        >
          {t('invoiceManagement:Import Product information')}
        </AddItemButton>
        <AddItemButton
          data-e2e-id={`${E2E_PREFIX}-insert-btn`}
          icon={<SmallIconCirclePlus />}
          onClick={insertEmpty}
        >
          {t('invoiceManagement:Custom Item')}
        </AddItemButton>
      </Section>
      {showPicker && (
        <ProductPicker
          merchantId={merchantId}
          onClose={handleToggleShowPicker}
          onPick={handlePickCustomer}
        />
      )}
    </ThemeProvider>
  );
};

export default ItemsSection;
