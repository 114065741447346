import createApi from 'utils/api';

const createInvoice = ({ merchantId, payload }) =>
  createApi()
    .post(`/merchants/${merchantId}/invoices`, {
      create_by: 'EC',
      channel_id: null,
      device_id: null,
      buyer_customer_number: '',
      invoice_type: '07',
      remark: '',
      ...payload,
    })
    .then((res) => res.data);

export default createInvoice;
