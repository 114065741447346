import { cond, constant, flow, stubTrue } from 'lodash-es';
import numberFormat from 'utils/numberFormat';

const DOLLAR_SIGN = 'NT$';

const format = flow(Math.abs, numberFormat);

export const getNumberDisplay = (number) => {
  const sign = number < 0 ? '-' : '';
  return `${sign}${DOLLAR_SIGN}${format(number)}`;
};

export default cond([
  [Number.isNaN, constant(`${DOLLAR_SIGN}-`)],
  [stubTrue, getNumberDisplay],
]);
