import { nanoid } from 'nanoid/non-secure';
import { TAXABLE } from 'constants/taxTypes';
import { PERSONAL } from 'constants/invoiceKinds';
import { actionTypes } from '../../actions/tradevanCreate/main';

export const initialState = {
  touched: false,
  submitKey: nanoid(),
  taxType: TAXABLE,
  invoiceKind: PERSONAL,
  shippingFee: 0,
  extraFee: 0,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case actionTypes.SET_TAX_TYPE:
      return {
        ...state,
        taxType: payload,
      };
    case actionTypes.SUBMIT:
      return {
        ...state,
        submitKey: nanoid(),
      };
    case actionTypes.SET_INVOICE_KIND:
      return {
        ...state,
        invoiceKind: payload,
      };
    case actionTypes.SET_SHIPPING_FEE:
      return {
        ...state,
        shippingFee: payload,
      };
    case actionTypes.SET_EXTRA_FEE:
      return {
        ...state,
        extraFee: payload,
      };
    default:
      return state;
  }
}
