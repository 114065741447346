import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { createSelector } from 'reselect';
import { cond, flow, nthArg, iteratee, stubTrue } from 'lodash-es';
import eq from 'lodash/fp/eq';
import { Button } from '@shopline/dashboard-ui';
import { getPriceDisplay } from 'components/PriceText';
import TextLabel from 'components/TextLabel';
import useEternalOrderUrl from 'hooks/useEternalOrderUrl';
import useComposeEternalUrl from 'hooks/useComposeEternalUrl';
import { INVOICE_ISSUE_TYPE } from 'constants/invoice';
import StatusBadge from '../components/StatusBadge';
import Time from '../components/Time';
import { issueTypeSelector } from '../selectors';
import {
  getIssueTypeTransKey,
  getPersonalCarrierTypeTransKey,
  generateDetailId,
} from '../utils';

const carrierTypeSelector = iteratee('carrier_type');

const carrierTypeTransKeySelector = createSelector(
  issueTypeSelector,
  carrierTypeSelector,
  cond([
    [
      eq(INVOICE_ISSUE_TYPE.CLOUD),
      flow(nthArg(1), getPersonalCarrierTypeTransKey),
    ],
    [stubTrue, getIssueTypeTransKey],
  ]),
);

export const COLUMN_KEYS = {
  INVOICE_NUMBER: 'invoiceNumber',
  ORDER_ID: 'orderId',
  INVOICE_AT: 'invoiceAt',
  SALES_AMOUNT: 'salesAmount',
  TAX_AMOUNT: 'taxAmount',
  TOTAL_AMOUNT: 'totalAmount',
  COMPANYUN: 'companyun',
  CARRIER_TYPE: 'carrierType',
  INVOICE_STATUS: 'invoiceStatus',
  INVALID_AT: 'invalidAt',
  VOID_BUTTON: 'voidButton',
};

const OrderId = ({ orderId, orderNumber, eternalOrderUrl, createdBy }) => {
  const eternalUrl = useEternalOrderUrl({
    orderId,
    eternalOrderUrl,
    createdBy,
  });
  const orderUrl = useComposeEternalUrl(eternalUrl);
  return orderId ? (
    <OutBoundLink
      href={orderUrl}
      target="_blank"
      onClick={(e) => e.stopPropagation()}
    >
      {orderNumber}
    </OutBoundLink>
  ) : (
    '-'
  );
};

const OutBoundLink = styled.a`
  color: ${({ theme }) => theme.colors.PRIMARY_500};
  &:visited {
    ${({ theme }) => theme.colors.PRIMARY_500}
  }
  text-decoration: none;
`;

const useInvoiceTableConfig = ({
  tableData,
  activeRowId,
  excludeColumnKeys,
  removeDisabled,
  onClickRemove,
}) => {
  const { t } = useTranslation();
  const tablePropColumns = useMemo(
    () =>
      [
        {
          key: COLUMN_KEYS.INVOICE_NUMBER,
          title: t('invoiceManagement:Invoice Number'),
          width: 10,
        },
        {
          key: COLUMN_KEYS.ORDER_ID,
          title: t('invoiceManagement:Order Number'),
          width: 10,
        },
        {
          key: COLUMN_KEYS.INVOICE_AT,
          title: t('invoiceManagement:Invoice Date'),
          width: 7,
        },
        {
          key: COLUMN_KEYS.SALES_AMOUNT,
          title: t('invoiceManagement:Sales Amount'),
          width: 7,
        },
        {
          key: COLUMN_KEYS.TAX_AMOUNT,
          title: t('invoiceManagement:Tax'),
          width: 7,
        },
        {
          key: COLUMN_KEYS.TOTAL_AMOUNT,
          title: t('invoiceManagement:Total Amount'),
          width: 7,
        },
        {
          key: COLUMN_KEYS.COMPANYUN, // 關貿不要
          title: t('invoiceManagement:Tax ID'),
          width: 7,
        },
        {
          key: COLUMN_KEYS.CARRIER_TYPE,
          title: t('invoiceManagement:Carrier'),
          width: 7,
        },
        {
          key: COLUMN_KEYS.INVOICE_STATUS,
          title: t('invoiceManagement:Invoice Status'),
          width: 7,
        },
        {
          key: COLUMN_KEYS.INVALID_AT,
          title: t('invoiceManagement:Voided Date'),
          width: 7,
        },
        {
          key: COLUMN_KEYS.VOID_BUTTON,
          title: '',
          width: 4,
        },
      ].filter((column) => !excludeColumnKeys.includes(column.key)),
    [t, excludeColumnKeys],
  );

  const handleClickRemove = useCallback(
    (rowData) => (e) => {
      e.stopPropagation();
      onClickRemove(rowData);
    },
    [onClickRemove],
  );

  const dataSource = useMemo(
    () =>
      tableData.map((invoiceData) => {
        const {
          invoice_number: invoiceNumber,
          period,
          eternal_order_url: eternalOrderUrl,
          order_id: orderId,
          order_number: orderNumber,
          invoice_at: invoiceAt,
          sales_amount: salesAmount,
          tax_amount: taxAmount,
          total_amount: totalAmount,
          companyun,
          invalid_at: invalidAt,
          create_by: createdBy,
        } = invoiceData;

        const data = {
          id: generateDetailId(invoiceData),
          invoiceNumber: (
            <TextLabel fontType="Body" color="PRIMARY_500">
              {invoiceNumber}
            </TextLabel>
          ),
          period,
          orderId: (
            <OrderId
              orderId={orderId}
              orderNumber={orderNumber}
              eternalOrderUrl={eternalOrderUrl}
              createdBy={createdBy}
            />
          ),
          invoiceAt: <Time time={invoiceAt} />,
          salesAmount: getPriceDisplay(salesAmount),
          taxAmount: getPriceDisplay(taxAmount),
          totalAmount: getPriceDisplay(totalAmount),
          companyun: companyun || '-',
          invalidAt: <Time time={invalidAt} />,
          carrierType: t(carrierTypeTransKeySelector(invoiceData)),
          invoiceStatus: <StatusBadge invalidAt={invalidAt} />,
          voidButton: invalidAt ? null : (
            <Button.Default
              disabled={removeDisabled}
              onClick={handleClickRemove(invoiceData)}
            >
              {t('invoiceManagement:Void')}
            </Button.Default>
          ),
        };
        return data;
      }),
    [t, tableData, removeDisabled, handleClickRemove],
  );

  const activeRowIndex = useMemo(
    () => dataSource.findIndex((rowData) => rowData.id === activeRowId),
    [activeRowId, dataSource],
  );

  return {
    activeRowIndex,
    tablePropColumns,
    dataSource,
  };
};

export default useInvoiceTableConfig;
