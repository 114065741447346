import { useTranslation } from 'react-i18next';
import DropdownComp from 'components/Dropdown';
import useTranslateOptions from 'hooks/useTranslateOptions';
import {
  invoiceStatusConfig,
  carrierTypeConfig,
  taxTypeConfig,
} from '../filterConfig';

const dropdownConfig = {
  block: true,
  minHeight: '2.25rem',
  keepPlaceHolder: true,
  shouldShowTooltip: true,
  useDefaultBorder: true,
  disabledShadow: true,
};

const InvoiceStatus = (props) => {
  const { t } = useTranslation();
  const options = useTranslateOptions(invoiceStatusConfig);
  return (
    <DropdownComp
      {...dropdownConfig}
      type="multipleOption"
      options={options}
      placeHolder={t('invoiceManagement:Invoice Status')}
      disabledShadow={false}
      useDefaultBorder={false}
      {...props}
    />
  );
};

const CarrierType = (props) => {
  const { t } = useTranslation();
  const options = useTranslateOptions(carrierTypeConfig);
  return (
    <DropdownComp
      {...dropdownConfig}
      options={options}
      placeHolder={t('invoiceManagement:Carrier')}
      {...props}
    />
  );
};

const TaxType = (props) => {
  const { t } = useTranslation();
  const options = useTranslateOptions(taxTypeConfig);
  return (
    <DropdownComp
      {...dropdownConfig}
      options={options}
      placeHolder={t('invoiceManagement:Tax Type')}
      {...props}
    />
  );
};

const Dropdown = { InvoiceStatus, CarrierType, TaxType };

export default Dropdown;
