const PREFIX = 'TRADEVAN_CREATED';

export const INIT = `${PREFIX}.INIT`;
export const SUBMIT = `${PREFIX}.SUBMIT`;
export const SET_TAX_TYPE = `${PREFIX}.SET_TAX_TYPE`;
export const SET_INVOICE_KIND = `${PREFIX}.SET_INVOICE_KIND`;
export const SET_SHIPPING_FEE = `${PREFIX}.SET_SHIPPING_FEE`;
export const SET_EXTRA_FEE = `${PREFIX}.SET_EXTRA_FEE`;

export const actionTypes = {
  INIT,
  SUBMIT,
  SET_TAX_TYPE,
  SET_INVOICE_KIND,
  SET_SHIPPING_FEE,
  SET_EXTRA_FEE,
};

export const init = () => ({
  type: actionTypes.INIT,
});

export const submit = () => ({
  type: actionTypes.SUBMIT,
});

export const setTaxType = (taxType) => ({
  type: actionTypes.SET_TAX_TYPE,
  payload: taxType,
});

export const setInvoiceKind = (invoiceKind) => ({
  type: actionTypes.SET_INVOICE_KIND,
  payload: invoiceKind,
});

export const setShippingFee = (shippingFee) => ({
  type: actionTypes.SET_SHIPPING_FEE,
  payload: shippingFee,
});

export const setExtraFee = (extraFee) => ({
  type: actionTypes.SET_EXTRA_FEE,
  payload: extraFee,
});

export const actions = {
  init,
  submit,
  setTaxType,
  setInvoiceKind,
  setShippingFee,
  setExtraFee,
};
