import axios from 'axios';
import dayjs from 'dayjs';
import { cond, constant, stubTrue } from 'lodash-es';
import eq from 'lodash/fp/eq';
import { createCancelToken } from 'utils/api';
import { TRADEVAN, CETUSTEK } from 'constants/vendor';

const vendorEndpointMap = {
  [TRADEVAN]: '/tradevan-daily-invoice-reports',
  [CETUSTEK]: '/cetustek-daily-invoice-reports',
};

export const generateGetByDateListActionTypes = (servicePrefix) => ({
  init: `${servicePrefix}_INIT`,
  request: `${servicePrefix}_GET_BY_DAY_LIST_REQUEST`,
  success: `${servicePrefix}_GET_BY_DAY_LIST_SUCCESS`,
  fail: `${servicePrefix}_GET_BY_DAY_LIST_FAIL`,
});

let cancelTokenSource = null;

const transformChannelToStoresAndCreatedBy = cond([
  [eq(''), constant({ stores: ['all'] })],
  [eq('shop'), constant({ stores: ['all'], created_by: 'ec' })],
  [stubTrue, (channel) => ({ stores: [channel], created_by: 'pos' })],
]);

const cookAxiosPayload = ({
  startDate,
  endDate,
  channel = '',
  limit,
  page,
  createdBy,
}) => {
  const axiosConfig = {
    cancelToken: cancelTokenSource.token,
    params: {
      start_date: dayjs(startDate).startOf('day').format(),
      end_date: dayjs(endDate).endOf('day').format(),
      page,
      per_page: limit,
      ...transformChannelToStoresAndCreatedBy(channel),
    },
  };
  return axiosConfig;
};

export const generateGetByDayList =
  (serviceProvider, actions) =>
  (parameters) =>
  async (dispatch, getState, context) => {
    const { api } = context;

    cancelTokenSource = createCancelToken(cancelTokenSource);

    dispatch({ type: actions.request });
    try {
      const axiosPayload = cookAxiosPayload({ ...parameters });
      const { data } = await api.get(
        vendorEndpointMap[serviceProvider],
        axiosPayload,
      );
      cancelTokenSource = null;
      dispatch({ type: actions.success, payload: { data } });
    } catch (e) {
      if (axios.isCancel(e)) return;
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.error(e);
      }

      dispatch({ type: actions.fail });
    }
  };
