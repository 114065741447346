import { memo, forwardRef } from 'react';
import styled from 'styled-components';
import { Icons } from '@shopline/dashboard-ui';
import { Selector } from '@shopline/dashboard-ui/dist/esm/components/dropdown/Dropdown';

const StyledSelector = styled(Selector)`
  border-radius: 6px 0px 0px 6px;
  border-right: none;
`;

const LabelWrapper = styled.span`
  padding-left: 0.75rem;
  text-overflow: ellipsis;
  overflow: hidden;
  width: calc(100% - 1.25rem);
  white-space: nowrap;
  ${({ theme, themeColor }) => themeColor && `color: ${theme.colors[themeColor]};`}
`;

const CustomSelector = forwardRef(
  (
    {
      isOpen,
      width,
      minHeight,
      border,
      contentPadding,
      onClick,

      currentLabel,
      keepPlaceHolder = false,
      placeHolder,
    },
    ref,
  ) => {
    const showPlaceholder = keepPlaceHolder || !currentLabel;
    return (
      <StyledSelector
        isOpen={isOpen}
        onClick={onClick}
        width={width}
        border={border}
        minHeight={minHeight}
        contentPadding={contentPadding}
        disabledShadow
        useDefaultBorder
      >
        <LabelWrapper ref={ref} themeColor={showPlaceholder ? 'INK_800' : ''}>
          {showPlaceholder ? placeHolder : currentLabel}
        </LabelWrapper>
        <Icons.Outline.Chevron position={isOpen ? 'TOP' : 'BOTTOM'} size="MEDIUM" />
      </StyledSelector>
    );
  },
);

export default memo(CustomSelector);
