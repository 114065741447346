import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { curryRight } from 'lodash-es';
import multiply from 'lodash/fp/multiply';
import { IconButton, Icons } from '@shopline/dashboard-ui';
import EllipsisText from 'components/EllipsisText';
import PriceText from 'components/PriceText';
import { actions } from 'stores/invoiceManagement/actions/tradevanCreate/discountItems';
import { withProps } from 'utils/recompose';
import PARENT_E2E_PREFIX from './e2ePrefix';
import { itemSelector } from './selectors/discountItems';
import useMaxDiscount from './useMaxDiscount';
import {
  Discount,
  DiscountEditorPopover,
  PopoverContainer,
} from './components';

const E2E_PREFIX = `${PARENT_E2E_PREFIX}-discount-item`;

const curriedItemSelector = curryRight(itemSelector, 2);

const MediumIconTrash = withProps({ size: 'MEDIUM' })(Icons.Solid.Trash);

const negative = multiply(-1);

const DiscountItem = ({ id }) => {
  const { t } = useTranslation();
  const item = useSelector(curriedItemSelector(id));
  const maxDiscount = useMaxDiscount(item.discountValue);
  const dispatch = useDispatch();
  const handleUpdateDiscount = useCallback(
    (discount) => {
      dispatch(actions.update(id, discount));
    },
    [dispatch, id],
  );
  const handlePullDiscount = useCallback(() => {
    dispatch(actions.pull(id));
  }, [dispatch, id]);

  return (
    <DiscountEditorPopover
      initialName={item.name}
      initialDiscountValue={item.discountValue}
      maxDiscount={maxDiscount}
      validateDefaultValues
      submitText={t('invoiceManagement:Save Discount')}
      onSubmit={handleUpdateDiscount}
      container={PopoverContainer}
      popoverProps={{ padding: 4 }}
      e2eId={`${E2E_PREFIX}-${id}-editor`}
    >
      {({ togglePopover }) => (
        <>
          <Discount
            onClick={togglePopover}
            data-e2e-id={`${E2E_PREFIX}-${id}-edit-btn`}
          >
            <EllipsisText
              fontType="Body"
              color="INK_800"
              e2eId={`${E2E_PREFIX}-${id}-name-text`}
            >
              {item.name}
            </EllipsisText>
            <PriceText e2eId={`${E2E_PREFIX}-${id}-price-text`}>
              {negative(item.discountValue)}
            </PriceText>
          </Discount>
          <IconButton
            Icon={MediumIconTrash}
            colorType="basic"
            size={1.75}
            handleClick={handlePullDiscount}
            tooltipProps={{ text: '', direction: 'TOP_CENTER' }}
            data-e2e-id={`${E2E_PREFIX}-${id}-delete-btn`}
          />
        </>
      )}
    </DiscountEditorPopover>
  );
};

export default DiscountItem;
