import {
  cond,
  flow,
  iteratee,
  toInteger,
  over,
  overSome,
  sum,
  stubTrue,
} from 'lodash-es';
import split from 'lodash/fp/split';
import map from 'lodash/fp/map';

const WEIGHTS = [1, 2, 1, 2, 1, 2, 4, 1];

const calcWeighting = (numbers) =>
  numbers.map((num, index) => num * WEIGHTS[index]);

const calcValidationNumber = flow(
  map(toInteger),
  calcWeighting,
  map(over([(num) => Math.floor(num / 10), (num) => num % 10])),
  map(sum),
  sum,
);

const isDividedBy5Exactly = (num) => num % 5 === 0;

const isValidUniformNumber = flow(
  split(''),
  cond([
    [
      iteratee([6, '7']),
      flow(
        calcValidationNumber,
        overSome([isDividedBy5Exactly, (num) => num % 5 === 4]),
      ),
    ],
    [stubTrue, flow(calcValidationNumber, isDividedBy5Exactly)],
  ]),
);

export default isValidUniformNumber;
