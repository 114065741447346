import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const useTranslateOptions = (options) => {
  const { t } = useTranslation();
  const optionsWithLang = useMemo(
    () =>
      options.map((option) => ({
        ...option,
        label: t(option.label),
      })),
    [options, t],
  );
  return optionsWithLang;
};

export default useTranslateOptions;
