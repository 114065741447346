import { memo, useMemo, useCallback } from 'react';
import { media } from '@shopline/dashboard-ui';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import originDayjs from 'dayjs';

import dayjs from 'utils/day';
import getTranslation from 'utils/getTranslation';

import useTranslateOptions from 'hooks/useTranslateOptions';
import TagPanel from 'components/TagPanel';

import { byDayDefaultStart, byDayDefaultEnd } from '../constants';
import { fixedChannelConfig } from '../filterConfig';

const E2E_PREFIX = 'tag-panel-section-';

const TagPanelWrapper = styled.div`
  margin: 0 0 1rem;
  ${media.mobile`
    margin: 0 1rem 1rem;
  `};
`;

const ByDayTagPanelSection = ({
  e2eFeaturePrefix,
  channels,
  parameters,
  updateParams,
}) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const fixedChannelOptions = useTranslateOptions(fixedChannelConfig);
  const channelsData = useMemo(
    () =>
      fixedChannelOptions.concat(
        channels.map((channel) => ({
          key: channel.id,
          value: channel.id,
          label: getTranslation(channel.name, language),
        })),
      ),
    [fixedChannelOptions, channels, language],
  );

  const onRemoveSearchCondition = useCallback(
    (e, conditions) => {
      const newParams = conditions.reduce((result, { type }) => {
        const params = {};
        switch (type) {
          case 'timeRange': {
            params.startDate = byDayDefaultStart;
            params.endDate = byDayDefaultEnd;
            break;
          }
          default:
            params[type] = undefined;
            break;
        }
        return Object.assign(result, params);
      }, {});
      updateParams({ ...parameters, ...newParams, page: '1' });
    },
    [updateParams, parameters],
  );

  const searchConditions = useMemo(() => {
    const renderedKeys = ['timeRange', 'channel'];
    const { startDate, endDate, channel } = parameters;
    const conditions = renderedKeys.reduce((resultConditions, currentKey) => {
      const newConditions = [];
      const pushCondition = (type, value, title, content) =>
        newConditions.push({
          type,
          value,
          title,
          content: content || value,
          e2eTagId: `${e2eFeaturePrefix}${E2E_PREFIX}tag-${type}`,
        });
      switch (currentKey) {
        case 'timeRange': {
          const isInitRange =
            originDayjs(startDate).isSame(byDayDefaultStart, 'date') &&
            originDayjs(endDate).isSame(byDayDefaultEnd, 'date');
          if (startDate && endDate && !isInitRange) {
            const fromTimeString = dayjs(language, startDate).format('LL');
            const toTimeString = dayjs(language, endDate).format('LL');
            pushCondition(
              'timeRange',
              `${fromTimeString} ~ ${toTimeString}`,
              t('invoiceManagement:Invoice Date'),
            );
          }
          break;
        }
        case 'channel':
          if (channel) {
            const target = channelsData.find(
              (channelData) => channelData.value === channel,
            );
            pushCondition(
              'channel',
              channel,
              t('invoiceManagement:Channel'),
              target ? target.label : '',
            );
          }
          break;
        default:
          break;
      }
      return [...resultConditions, ...newConditions];
    }, []);
    return conditions;
  }, [t, language, e2eFeaturePrefix, parameters, channelsData]);

  return searchConditions.length > 0 ? (
    <TagPanelWrapper>
      <TagPanel tags={searchConditions} onRemove={onRemoveSearchCondition} />
    </TagPanelWrapper>
  ) : null;
};

ByDayTagPanelSection.displayName = 'ByDayTagPanelSection';

export default memo(ByDayTagPanelSection);
