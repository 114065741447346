const PREFIX = 'PERSONAL_CARRIER';

export const SET_TYPE = `${PREFIX}.SET_TYPE`;
export const SET_VALUE = `${PREFIX}.SET_VALUE`;

export const actionTypes = {
  SET_TYPE,
  SET_VALUE,
};

export const setType = (type) => ({
  type: actionTypes.SET_TYPE,
  payload: type,
});

export const setValue = (value) => ({
  type: actionTypes.SET_VALUE,
  payload: value,
});

export const actions = {
  setType,
  setValue,
};
