import { Icons, Badge } from '@shopline/dashboard-ui';
import styled, { css } from 'styled-components';
import { cond, stubTrue, constant } from 'lodash-es';
import has from 'lodash/fp/has';

const rectMixin = css`
  ${cond([
    [has('strokeColor'), ({ strokeColor, theme }) => `stroke: ${theme.colors[strokeColor]};`],
    [stubTrue, constant('')],
  ])}
  ${cond([
    [has('fill'), ({ fill, theme }) => `fill: ${theme.colors[fill]};`],
    [stubTrue, constant('')],
  ])}
`;

const IconWrapper = styled.div`
  display: flex;
  svg {
    width: 0.375rem;
    height: 0.375rem;
  }
  & rect {
    ${rectMixin}
  }
`;

const GreenIcon = () => (
  <IconWrapper>
    <Icons.Solid.BudgeActive size="XXSMALL" />
  </IconWrapper>
);

const GreyIcon = () => (
  <IconWrapper strokeColor="INK_400" fill="INK_200">
    <Icons.Solid.BudgeUnpaid size="XXSMALL" />
  </IconWrapper>
);

const Green = ({ label }) => (
  <Badge.Outline color="GREEN_DEFAULT" label={label} Icon={GreenIcon} borderColor="GREEN_400" />
);

const Grey = ({ label }) => (
  <Badge.Outline color="INK_400" label={label} Icon={GreyIcon} borderColor="INK_300" backgroundColor="SNOW_500" />
);

const ColorBadge = {
  Green,
  Grey,
};

export default ColorBadge;
