const invoiceManagement = {
  'Invoice Management': 'Invoice Management',
  Invoice: 'Invoice',
  'Create Invoice': 'Create Invoice',
  'Invoice Details': 'Invoice Details',
  'Order Invoice': 'Order Invoice',
  'Non-order Invoice': 'Non-order Invoice',
  'Invoice Number': 'Invoice Number',
  'Order Number': 'Order Number',
  'Invoice Date': 'Invoice Date',
  'Sales Amount': 'Sales Amount',
  Tax: 'Tax',
  'Total Amount': 'Total',
  'Invoice Status': 'Invoice Status',
  Issued: 'Issued',
  Voided: 'Voided',
  Void: 'Void',
  Channel: 'Channel',
  Shop: 'Shop',
  'Reason for voiding invoice': 'Reason for voiding invoice',
  'Please enter reason for voiding invoice':
    'Please enter reason for voiding invoice',
  'Successfully created an invoice':
    'Successfully created an invoice {{invoiceNo}}',
  'Successfully voided an invoice':
    'Successfully voided an invoice {{invoiceNo}}',
  'Failed to void the invoice': 'Failed to void the invoice',
  Export: 'Export',
  Create: 'Create',
  'Export Report': 'Export Report',
  'Type of Report': 'Type of Report',
  'Data Range': 'Data Range',
  'Current Page': 'Current Page',
  'Select Date Range': 'Select Date Range',
  'maximum date range is 100 days': 'maximum date range is 100 days',
  'The invoice has been voided': 'The invoice has been voided',
  'Cant void invoice that passes tax filing period':
    "Can't void invoice that passes tax filing period",
  'The invoice has been issued': 'The invoice has been issued',
  'No available invoice number ': 'No available invoice number ',
  'Invoice Information': 'Invoice Information',
  'Voided Date': 'Voided Date',
  'Type of Invoice Request': 'Type of Invoice Request',
  Carrier: 'Carrier',
  'Non-physical electronic invoice': 'Non-physical electronic invoice',
  'Donate e-invoice': 'Donate e-invoice',
  'Donation Units': 'Donation Units',
  'Uniform invoice': 'Uniform invoice',
  'Duplicate uniform invoice': 'Duplicate uniform invoice',
  'Paper invoice': 'Paper Invoice',
  Member: 'Member',
  'Mobile Barcode': 'Mobile Barcode',
  'NPC Barcode': 'NPC Barcode',
  'Company Name': 'Company Name',
  'Tax ID': 'Tax ID',
  'Send to': 'Send to',
  'Send to optional': 'Send to (optional)',
  "Buyer's Information": "Buyer's Information",
  "Buyer's Name": "Buyer's Name",
  'Mobile Phone': 'Mobile Phone',
  'Order Details': 'Order Details',
  Subtotal: 'Subtotal',
  'Delivery Fee': 'Delivery Fee',
  'Payment Fee': 'Payment Fee',
  Total: 'Total',
  'Tax Type': 'Tax Type',
  TaxTypeDisplay: '',
  TaxTypeDisplay_T: 'Taxable',
  TaxTypeDisplay_O: 'Tax Free',
  TaxTypeDisplay_C: 'Zero-Tax-Rate - Exported by customs',
  TaxTypeDisplay_Z: 'Zero-Tax-Rate - Not exported by customs',
  Taxable: '$t(invoiceManagement:TaxTypeDisplay, {"context": "T"})',
  'Tax Free': '$t(invoiceManagement:TaxTypeDisplay, {"context": "O"})',
  'Zero-Tax-Rate - Exported by customs':
    '$t(invoiceManagement:TaxTypeDisplay, {"context": "C"})',
  'Zero-Tax-Rate - Not exported by customs':
    '$t(invoiceManagement:TaxTypeDisplay, {"context": "Z"})',
  "Import Customer's information": "Import Customer's information",
  'Product Information': 'Product Information',
  'Product Information Description':
    "For issuing invoice only, inventory won't be affected",
  'Import Product information': 'Import Product information',
  'Custom Item': 'Custom Item',
  'Add Discount': 'Add Discount',
  'please enter {xxxx}': 'please enter {xxxx}',
  'Address is up to 100 characters': 'Address is up to 100 characters',
  'at least one product is required': 'at least one product is required',
  'up tp 250 characters only': 'up tp 250 characters only',
  'Total amounts need to be > 0': 'Total amounts need to be > 0',
  'Please fill out all the required field':
    'Please fill out all the required field',
  'Void Invoice': 'Void Invoice',
  'No Invoice Record': 'No Invoice Record',
  'No Invoice Record Description':
    'Invoice that was issued or voided by Shopline system will be listed here',
  'Exporting Your Invoice Report': 'Exporting Your Invoice Report',
  'Invoice Report': 'invoice report',
  'List By Day': 'List By Day',
  'Start - End Number': 'Start - End Number',
  'Total Taxable Invoice Amount': 'Total Taxable Invoice Amount',
  'Total Tax Exempt Invoice Amount': 'Total Tax Exempt Invoice Amount',
  'Total Zero-Tax-Rate Invoice Amount': 'Total Zero-Tax-Rate Invoice Amount',
  'Void Invoice Number': 'Void Invoice Number',
  'Customer Name': 'Name',
  'Customer Mobile': 'Mobile phone',
  'Customer Email': 'Email',
  'Is A Member': 'Is a member',
  Import: 'Import',
  'Invoice Amount': 'Invoice Amount',
  'Items Subtotal': 'Subtotal',
  'Discount Name': 'Discount Name',
  'Discount Amount': 'Discount Amount',
  'Product Title': 'Product',
  'Product Variations': 'Variations',
  'Product Price': 'Price (Tax included)',
  'Search Product Placeholder': 'Search by Product name or SKU',
  'View Selected Products': 'View selected products({{count}})',
  'View All Products': 'View all products',
  'Search Customer Placeholder': 'Search by customer name/phone/email',
  'Save Discount': 'Save',
  'No Products Found': 'No Products Found',
  'No Result': 'No Result',
  'Try Again Search': 'Please try again with other keywords or filters',
  'Invoice and Tax': 'Invoice',
  'Retail Store': 'Retail Store',
  'Failed to create': 'Failed to issue the invoice.',
  'Error Description': 'Please try again',
  'Error Description_Failed to create-1002': 'No available invoice number',
  'Error Description_Failed to void the invoice-3002':
    "Can't void invoice that passes tax filing period",
  'Error Description_Failed to void the invoice-3003':
    'The invoice has been voided',
  'Product Name': 'Product name',
  'Enter Product Name': 'Input Product Name',
  'Discount Amount Min Error': 'Discount amounts need to be > 0',
  'Discount Amount Max Error':
    'Discount amounts can not be greater than subtotal',
  'Invalid invoice amount': 'Incorrect Invoice amounts',
  'maximum date range is 1 year': 'Selected Date Range is up to 1 year',
  Required: 'Required',
};

export default invoiceManagement;
