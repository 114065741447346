import {
  GET_INITIAL_STATE_REQUEST,
  GET_INITIAL_STATE_SUCCESS,
  GET_INITIAL_STATE_FAIL,
} from '../actions/getInitialState';

const initState = {
  isLoadingInitState: true,
  merchantData: {},
  merchantSetting: {},
  staff: {},
  error: null,
};

const merchant = (state = initState, action) => {
  switch (action.type) {
    case GET_INITIAL_STATE_REQUEST: {
      return { ...state, error: null, isLoadingInitState: true };
    }
    case GET_INITIAL_STATE_SUCCESS: {
      const { data } = action.payload;
      const {
        merchant: merchantData,
        merchantOrdersSetting: merchantSetting,
        ...restData
      } = data;
      return {
        ...state,
        error: null,
        isLoadingInitState: false,
        merchantData,
        merchantSetting,
        ...restData,
      };
    }
    case GET_INITIAL_STATE_FAIL: {
      const { error } = action.payload;
      const newError = {
        error: error.error || 'Something Went Wrong, Please Try Again Later',
        errorDetail: error.errorDetail || {},
      };
      return { ...state, isLoadingInitState: false, error: newError };
    }
    default:
      return state;
  }
};

export default merchant;
