import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { cond, constant, iteratee, stubTrue } from 'lodash-es';
import eq from 'lodash/fp/eq';
import DispatchRoute from 'components/DispatchRoute';
import TradevanList from './TradevanList';
import TradevanCreate from './TradevanCreate';
import CetustekList from './CetustekList';

const TradevanRoute = () => (
  <Switch>
    <DispatchRoute
      path="/invoice-management/tradevan/list/:listType(by-order|by-day)"
      component={TradevanList}
    />
    <DispatchRoute
      path="/invoice-management/tradevan/create"
      component={TradevanCreate}
    />
    <Redirect to="/invoice-management/tradevan/list/by-order" />
  </Switch>
);

const CetustekRoute = () => (
  <Switch>
    <DispatchRoute
      path="/invoice-management/cetustek/list/:listType(by-order|by-day)"
      component={CetustekList}
    />
    <Redirect to="/invoice-management/cetustek/list/by-order" />
  </Switch>
);

const serviceProviderSelector = iteratee(
  'merchant.merchantSetting.current_invoice_service_provider',
);
const defaultRedirectSelector = createSelector(
  serviceProviderSelector,
  cond([
    [eq('tradevan'), constant('/invoice-management/tradevan')],
    [stubTrue, constant('/invoice-management/cetustek')],
  ]),
);

const InvoiceManagement = () => {
  const defaultRedirect = useSelector(defaultRedirectSelector);
  return (
    <Switch>
      <Route path="/invoice-management/tradevan" component={TradevanRoute} />
      <Route path="/invoice-management/cetustek" component={CetustekRoute} />
      <Redirect to={defaultRedirect} />
    </Switch>
  );
};

export default InvoiceManagement;
