import * as personalCarrierTypes from 'constants/personalCarrierTypes';
import { actionTypes } from '../../actions/tradevanCreate/personalCarrier';

export const initialState = {
  type: personalCarrierTypes.MEMBER,
  value: '',
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case actionTypes.SET_TYPE:
      return {
        ...state,
        type: payload,
        value: '',
      };
    case actionTypes.SET_VALUE:
      return {
        ...state,
        value: payload,
      };
    default:
      return state;
  }
}
