import styled from 'styled-components';
import { Grid, media } from '@shopline/dashboard-ui';
import getOr from 'lodash/fp/getOr';

const Row = styled(Grid.Row)`
  flex-wrap: nowrap;
  ${media.tablet`
    flex-wrap: wrap;
  `}
`;

const Column = styled(Grid.Column)`
  flex-grow: ${getOr(0, 'lg')};
  flex-shrink: ${getOr(0, 'lg')};
  & + & {
    margin-left: 1rem;
  }
  ${media.tablet`
    flex-grow: ${getOr(0, 'md')};
    flex-shrink: ${getOr(0, 'md')};
    & + & {
      margin-left: 0;
      margin-top: 1rem;
    }
  `}
  ${media.mobile`
    flex-grow: ${getOr(0, 'sd')};
    flex-shrink: ${getOr(0, 'sd')};
    & + & {
      margin-top: 0.5rem;
    }
  `}
`;

const EnhancedGrid = { Row, Column };

export default EnhancedGrid;
