import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';

import { ToastPropType } from '../../../types/toast';

export const CREATE_UI_TOAST = 'CREATE_UI_TOAST';
export const DISMISS_UI_TOAST = 'DISMISS_UI_TOAST';
export const DELETE_UI_TOAST = 'DELETE_UI_TOAST';
export const CLEAR_UI_TOAST = 'CLEAR_UI_TOAST';

export const showUIToast = (toast) => (dispatch, getState) => {
  PropTypes.checkPropTypes(ToastPropType, toast, '[toast settings]', 'showUIToast Action');
  const newToastId = nanoid();
  dispatch(
    createUIToast({
      [newToastId]: {
        ...toast,
        id: newToastId,
        isShow: true,
      },
    }),
  );

  if (toast.duration) {
    setTimeout(() => {
      dispatch(dismissUIToast(newToastId));
    }, toast.duration);
  }
};

export const createUIToast = (toast) => ({
  type: CREATE_UI_TOAST,
  payload: { toast },
});

export const dismissUIToast = (id) => ({
  type: DISMISS_UI_TOAST,
  payload: { id },
});

export const deleteUIToast = (id) => ({
  type: 'DELETE_UI_TOAST',
  payload: { id },
});

export const clearUIToast = () => ({
  type: 'CLEAR_UI_TOAST',
});
