import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import prop from 'lodash/fp/prop';
import { curryRight } from 'lodash-es';

const merchantSelector = prop('merchant');
const adminEndpointSelector = createSelector(
  merchantSelector,
  prop('adminEndpoint'),
);
const handleSelector = createSelector(
  merchantSelector,
  prop('merchantData.handle'),
);
const eternalOrderUrlSelector = createSelector(
  adminEndpointSelector,
  handleSelector,
  (_, { eternalOrderUrl }) => eternalOrderUrl,
  (adminEndpoint, handle, eternalOrderUrl) =>
    eternalOrderUrl
      .replace('<admin-endpoint>', adminEndpoint)
      .replace('<handle>', handle),
);

const curriedEternalOrderUrlSelector = curryRight(eternalOrderUrlSelector, 2);

const useComposeEternalUrl = (eternalOrderUrl) => {
  const url = useSelector(curriedEternalOrderUrlSelector({ eternalOrderUrl }));
  return url;
};

export default useComposeEternalUrl;
