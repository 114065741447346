import { curryRight, gte, lte, toPairs } from 'lodash-es';

const isValidRequired = (value, ruleValue) => {
  if (!ruleValue) {
    return true;
  }
  return `${value}` !== '';
};

const isValidMaxLength = (value, ruleValue) =>
  lte(`${value}`.length, ruleValue);

const isValidMin = (value, ruleValue) => gte(Number(value), ruleValue);

const isValidMax = (value, ruleValue) => lte(Number(value), ruleValue);

const isValidPattern = (value, ruleValue) =>
  value === '' || ruleValue.test(value);

const isValid = (value, ruleType, ruleValue) => {
  switch (ruleType) {
    case 'required':
      return isValidRequired(value, ruleValue);
    case 'maxLength':
      return isValidMaxLength(value, ruleValue);
    case 'min':
      return isValidMin(value, ruleValue);
    case 'max':
      return isValidMax(value, ruleValue);
    case 'pattern':
      return isValidPattern(value, ruleValue);
    case 'custom':
      return ruleValue(value);
    default:
      return true;
  }
};

const validate = curryRight((value, rule) =>
  toPairs(rule).reduce(
    (accumulator, [ruleType, { value: ruleValue, message }]) => {
      if (accumulator) {
        return accumulator;
      }
      if (isValid(value, ruleType, ruleValue)) {
        return accumulator;
      }
      return message;
    },
    '',
  ),
);

export default validate;
