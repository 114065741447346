import {
  cond,
  constant,
  negate,
  noop,
  iteratee,
  overEvery,
  overSome,
  stubTrue,
} from 'lodash-es';
import has from 'lodash/fp/has';
import { DONATE_MARK, INVOICE_ISSUE_TYPE } from 'constants/invoice';

export const paperPrintModeIssueTypeSelector = cond([
  [
    overEvery([
      has('buyer_companyun'),
      negate(
        overSome([
          iteratee({ buyer_companyun: '' }),
          iteratee({ buyer_companyun: null }),
        ]),
      ),
    ]),
    constant(INVOICE_ISSUE_TYPE.UNIFORM),
  ],
  [iteratee({ paper_print_mode: 1 }), constant(INVOICE_ISSUE_TYPE.PAPER)],
  [
    iteratee({ paper_print_mode: 4 }),
    constant(INVOICE_ISSUE_TYPE.DUPLICATE_UNIFORM),
  ],
  [stubTrue, noop],
]);

export const issueTypeSelector = cond([
  [
    iteratee({ donate_mark: DONATE_MARK.CARRIER }),
    constant(INVOICE_ISSUE_TYPE.CLOUD),
  ],
  [
    iteratee({ donate_mark: DONATE_MARK.DONATE }),
    constant(INVOICE_ISSUE_TYPE.DONATE),
  ],
  [
    iteratee({ donate_mark: DONATE_MARK.PAPER }),
    paperPrintModeIssueTypeSelector,
  ],
  [stubTrue, noop],
]);
