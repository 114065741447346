export const GET_CHANNELS_REQUEST = 'GET_CHANNELS_REQUEST';
export const GET_CHANNELS_SUCCESS = 'GET_CHANNELS_SUCCESS';
export const GET_CHANNELS_FAIL = 'GET_CHANNELS_FAIL';

export const getChannelsRequest = () => ({
  type: GET_CHANNELS_REQUEST,
});

export const getChannelsSuccess = (data = {}) => ({
  type: GET_CHANNELS_SUCCESS,
  payload: { data },
});
export const getChannelsFailed = () => ({
  type: GET_CHANNELS_FAIL,
});

export const getChannels = () => async (dispatch, getState, context) => {
  const { api } = context;
  dispatch(getChannelsRequest());
  try {
    const { data } = await api('/channels', {
      params: {
        page: 1,
        per_page: 999,
      },
    });
    dispatch(getChannelsSuccess(data));
  } catch (e) {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.error(e);
    }
    dispatch(getChannelsFailed());
  }
};
