import styled from 'styled-components';
import prop from 'lodash/fp/prop';

const Container = styled.div`
  padding: 1rem;
  width: 20rem;
  background-color: ${prop('theme.colors.SNOW_100')};
  border-radius: 0.5rem;
  box-shadow: 0px 2px 16px ${prop('theme.colors.INK_A200')},
    0px 32px 48px ${prop('theme.colors.INK_A100')};
`;

export default Container;
