import { Tag as UITag } from '@shopline/dashboard-ui';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import ClearButton from '../Button/ClearButton';

const StyledUITag = styled(UITag)`
  margin-right: 0.5rem;
  margin-top: 0.5rem;
`;

const Tag = ({ e2eTagId, type, value, title, content, onRemoveTag }) => {
  const { i18n } = useTranslation();
  const { language } = i18n;
  const onClickRemove = (e) => {
    onRemoveTag(e, type, value);
  };

  return (
    <StyledUITag
      e2eTagId={e2eTagId}
      lang={language}
      size="MEDIUM"
      label={`${title} ${content}`}
      onCloseClick={onClickRemove}
    />
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 0.375rem;
  padding: 0.5rem 1rem 1rem;
  ${({ theme }) => `
    background-color: ${theme.colors.PRIMARY_200};
  `}
`;

const TagsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
`;

const ButtonContainer = styled.div`
  flex-shrink: 0;
  margin-top: 0.75rem;
`;

const TagPanel = ({ tags, onRemove }) => {
  const onClickRemove = (e, targetTags) => {
    onRemove(e, targetTags);
  };

  const onRemoveTag = (e, type, value) => {
    onClickRemove(e, [{ type, value }]);
  };

  const onRemoveAll = (e) => {
    onClickRemove(e, tags);
  };

  return (
    <Container>
      <TagsContainer>
        {tags.map((tag) => (
          <Tag key={`${tag.type}_${tag.value}`} onRemoveTag={onRemoveTag} {...tag} />
        ))}
      </TagsContainer>
      <ButtonContainer>
        <ClearButton onClick={onRemoveAll} data-e2e-id="tag-panel-clear-btn" />
      </ButtonContainer>
    </Container>
  );
};

TagPanel.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      content: PropTypes.string,
    }),
  ).isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default TagPanel;
