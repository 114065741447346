import axios from 'axios';
import dayjs from 'dayjs';
import { merge, toUpper } from 'lodash-es';
import { createCancelToken } from 'utils/api';
import * as apiStatusMap from 'constants/apiStatusTypes';

export const GET_LIST_REQUEST = 'GET_LIST_REQUEST';
export const GET_LIST_SUCCESS = 'GET_LIST_SUCCESS';
export const GET_LIST_FAIL = 'GET_LIST_FAIL';

export const getListRequest = () => ({
  type: GET_LIST_REQUEST,
});

export const getListSuccess = (data = {}) => ({
  type: GET_LIST_SUCCESS,
  payload: { data },
});

export const getListFailed = () => ({
  type: GET_LIST_FAIL,
});

let cancelTokenSource = null;

export const cookApiSearchParams = ({
  startDate,
  endDate,
  invoiceStatus,
  channel,
  invoiceNumber,
  limit,
  page,
  ...rest
}) => {
  const searchParams = {
    channel_id: channel,
    invoice_number: invoiceNumber,
    per_page: limit,
    page,
    ...(invoiceStatus && {
      statuses: invoiceStatus.map((status) => apiStatusMap[toUpper(status)]),
    }),
    ...rest,
  };

  const dateQuery =
    startDate && endDate
      ? {
          start_date: dayjs(startDate).format('YYYY-MM-DD'),
          end_date: dayjs(endDate).format('YYYY-MM-DD'),
        }
      : {};
  return merge(searchParams, dateQuery);
};

export const getList = (parameters) => async (dispatch, getState, context) => {
  const { api } = context;

  cancelTokenSource = createCancelToken(cancelTokenSource);

  dispatch(getListRequest());
  try {
    const params = cookApiSearchParams(parameters);
    const { data } = await api('/cetustek-invoices', {
      cancelToken: cancelTokenSource.token,
      params,
    });

    cancelTokenSource = null;
    dispatch(getListSuccess(data));
  } catch (e) {
    if (axios.isCancel(e)) return;
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.error(e);
    }

    dispatch(getListFailed());
  }
};
