import { combineReducers } from 'redux';
import merchant from './merchant/reducers';
import toasts from './toasts/reducers';
import invoiceManagement from './invoiceManagement/reducers';
import channels from './channels/reducers';

const createRootReducer = () =>
  combineReducers({
    merchant,
    toasts,
    invoiceManagement,
    channels,
  });

export default createRootReducer;
