import styled from 'styled-components';
import prop from 'lodash/fp/prop';
import IconCheck from './IconCheck';
import IconTimes from './IconTimes';

const Icon = styled.i`
  width: 1.25rem;
  height: 1.25rem;
`;

const GreenIcon = styled(Icon)`
  color: ${prop('theme.colors.GREEN_700')};
`;

const RedIcon = styled(Icon)`
  color: ${prop('theme.colors.RED_700')};
`;

const renderIsMember = ({ isMember }) =>
  isMember ? <GreenIcon as={IconCheck} /> : <RedIcon as={IconTimes} />;

export default renderIsMember;
