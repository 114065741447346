import { useCallback } from 'react';
import styled from 'styled-components';
import { createSelector } from 'reselect';
import { Loading, TextButton, Icons } from '@shopline/dashboard-ui';
import { useTranslation } from 'react-i18next';
import { getTargetConfig } from 'utils/getFilterConfig';
import useEternalOrderUrl from 'hooks/useEternalOrderUrl';
import useComposeEternalUrl from 'hooks/useComposeEternalUrl';
import TextLabel from 'components/TextLabel';
import { CellContainer, Cell, CellTitle, CellContent } from './Cell';
import { issueTypeSelector } from '../../../selectors';
import { getIssueTypeTransKey } from '../../../utils';
import { carrierTypeConfig } from '../../../filterConfig';
import { E2E_BY_ORDER_PREFIX } from '../../../constants';

import StatusBadge from '../../../components/StatusBadge';
import Time from '../../../components/Time';

const E2E_PREFIX = `${E2E_BY_ORDER_PREFIX}detail-`;

const issueTypeTransKeySelector = createSelector(
  issueTypeSelector,
  getIssueTypeTransKey,
);

export const InfoContainer = styled.div`
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  position: relative;
`;

const AbsoluteDivider = styled.div`
  border-top: ${({ theme }) => `1px solid ${theme.colors.SKY_DEFAULT}`};
  position: absolute;
  bottom: 0;
  left: -2rem;
  width: calc(100% + 4rem);
`;

const InfoSection = ({
  titleKey,
  headColumns,
  columnTranslateKey,
  isLoading,
  detail,
}) => {
  const eternalUrl = useEternalOrderUrl({
    orderId: detail?.order_id,
    eternalOrderUrl: detail?.eternal_order_url,
    createdBy: detail?.create_by,
  });
  const orderUrl = useComposeEternalUrl(eternalUrl);
  const { t } = useTranslation();
  const renderCellContent = useCallback(
    (columnKey) => {
      switch (columnKey) {
        case 'order_id': {
          return detail.order_id ? (
            <TextButton.Primary as="a" href={orderUrl} target="_blank">
              {detail.order_number}
              <Icons.Outline.Link size="SMALL" />
            </TextButton.Primary>
          ) : (
            '-'
          );
        }
        case 'carrier_type':
          return t(
            getTargetConfig(carrierTypeConfig, detail.carrier_type, 'label'),
          );
        case 'mobile_barcode':
        case 'digital_certificate':
          return detail.carrier_id1;
        case 'donee':
          return detail.donation?.code
            ? `${detail.donation?.code} ${detail.donation?.name || ''}`
            : '-';
        case 'status':
          return <StatusBadge invalidAt={detail.invalid_at} />;
        case 'issueType':
          return t(issueTypeTransKeySelector(detail));
        case 'invoice_at':
        case 'invalid_at':
          return <Time time={detail[columnKey]} />;
        default: {
          const content = detail[columnKey] || '-';
          return content;
        }
      }
    },
    [t, detail, orderUrl],
  );
  return (
    <InfoContainer>
      <TextLabel
        as="h2"
        e2eId={`${E2E_PREFIX}invoice-info-title`}
        fontType="Heading"
        weight="Semibold"
      >
        {t(titleKey)}
      </TextLabel>
      <CellContainer>
        {headColumns.map((columnKey, index) => (
          <Cell
            key={columnKey}
            hasPaddingLeft={index % 2 === 1}
            hasPaddingRight={index % 2 === 0 && index !== headColumns.length}
          >
            <CellTitle title={t(columnTranslateKey[columnKey])} />
            <CellContent>
              {isLoading || !detail ? (
                <Loading.SparkLine />
              ) : (
                renderCellContent(columnKey)
              )}
            </CellContent>
          </Cell>
        ))}
        <AbsoluteDivider />
      </CellContainer>
    </InfoContainer>
  );
};

export default InfoSection;
