import { combineReducers } from 'redux';
import { flowRight } from 'lodash-es';
import update from 'immutability-helper';
import withInit from '../../../utils/withInit';
import { actionTypes as mainActionTypes } from '../../actions/tradevanCreate/main';
import buyerInfo, { initialState as buyerInfoInitialState } from './buyerInfo';
import companyInfo, {
  initialState as companyInfoInitialState,
} from './companyInfo';
import discountItems, {
  initialState as discountItemsInitialState,
} from './discountItems';
import donationCode, {
  initialState as donationCodeInitialState,
} from './donationCode';
import duplicateInvoice, {
  initialState as duplicateInvoiceInitialState,
} from './duplicateInvoice';
import invoiceItems, {
  initialState as invoiceItemsInitialState,
} from './invoiceItems';
import personalCarrier, {
  initialState as personalCarrierInitialState,
} from './personalCarrier';
import main, { initialState as mainInitialState } from './main';

const { INIT } = mainActionTypes;

const withTouched = (reducer) => (state, action) => {
  const nextState = reducer(state, action);
  if (nextState.touched) {
    return nextState;
  }
  if (action.type === INIT) {
    return nextState;
  }
  return update(nextState, { touched: { $set: true } });
};

const tradevanCreate = combineReducers({
  buyerInfo: withInit(buyerInfo, buyerInfoInitialState, INIT),
  companyInfo: withInit(companyInfo, companyInfoInitialState, INIT),
  discountItems: withInit(discountItems, discountItemsInitialState, INIT),
  donationCode: withInit(donationCode, donationCodeInitialState, INIT),
  duplicateInvoice: withInit(
    duplicateInvoice,
    duplicateInvoiceInitialState,
    INIT,
  ),
  invoiceItems: withInit(invoiceItems, invoiceItemsInitialState, INIT),
  personalCarrier: withInit(personalCarrier, personalCarrierInitialState, INIT),
  main: flowRight(withInit(mainInitialState, INIT), withTouched)(main),
});

export default tradevanCreate;
