import { useMemo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { matchesProperty, negate, overEvery } from 'lodash-es';
import getOr from 'lodash/fp/getOr';
import useHasRolloutKey from 'hooks/useHasRolloutKey';

import Page from '../../../components/Page';
import TextLabelDropdown from '../../../components/TextLabelDropdown';
import TitleButtonSection from './ByOrderList/list/TitleButtonSection';
import ByDayList from './ByDayList';
import ByOrderList from './ByOrderList';
import E2E_PREFIX from './e2ePrefix';

const merchantSelector = getOr({}, ['merchant']);

const hasCreatePermissionSelector = createSelector(
  merchantSelector,
  getOr(false, ['permissions', 'invoices', 'create']),
);

const isInvoiceActiveSelector = createSelector(
  merchantSelector,
  matchesProperty(['merchantSetting', 'invoice_activation'], 'active'),
);

const createDisabledSelector = negate(
  overEvery([hasCreatePermissionSelector, isInvoiceActiveSelector]),
);

const hasExportPermissionSelector = createSelector(
  merchantSelector,
  getOr(false, ['permissions', 'invoices', 'export']),
);

const exportDisabledSelector = negate(hasExportPermissionSelector);

const TradevanList = () => {
  const { t } = useTranslation();
  const createDisabled = useSelector(createDisabledSelector);
  const exportDisabled = useSelector(exportDisabledSelector);
  const { listType } = useParams();
  const history = useHistory();
  const [isExportPopupOpen, setIsExportPopupOpen] = useState(false);
  const { hasRolloutKeys } = useHasRolloutKey();
  const hasByDayList = hasRolloutKeys('einvoice_list_by_day');

  const breadcrumbs = useMemo(
    () => [
      {
        id: t('invoiceManagement:Invoice Management'),
        label: t('invoiceManagement:Invoice Management'),
        e2eId: `${E2E_PREFIX}-breadcrumb-invoice-management`,
      },
      {
        id: t('invoiceManagement:Invoice'),
        label: t('invoiceManagement:Invoice'),
        e2eId: `${E2E_PREFIX}-breadcrumb-invoice`,
      },
    ],
    [t],
  );

  const tabOptions = useMemo(
    () => [
      {
        key: 'byOrder',
        value: 'by-order',
        label: t('invoiceManagement:Invoice'),
      },
      {
        key: 'byDay',
        value: 'by-day',
        label: t('invoiceManagement:List By Day'),
      },
    ],
    [t],
  );

  const onChangeTab = useCallback(
    (value) => {
      const targetPath = `/invoice-management/tradevan/list/${value}`;
      history.push(targetPath);
    },
    [history],
  );

  const onClickExport = useCallback(() => {
    setIsExportPopupOpen(true);
  }, []);

  return (
    <Page
      titleLeftSection={
        hasByDayList ? (
          <TextLabelDropdown
            value={listType}
            options={tabOptions}
            onChange={onChangeTab}
            placeHolder=""
          />
        ) : (
          t('invoiceManagement:Invoice')
        )
      }
      titleRightSection={
        listType === 'by-order' ? (
          <TitleButtonSection
            createDisabled={createDisabled}
            exportDisabled={exportDisabled}
            onClickExport={onClickExport}
          />
        ) : null
      }
      breadcrumbs={breadcrumbs}
    >
      {listType === 'by-day' && <ByDayList />}
      {listType === 'by-order' && (
        <ByOrderList
          isExportPopupOpen={isExportPopupOpen}
          setIsExportPopupOpen={setIsExportPopupOpen}
        />
      )}
    </Page>
  );
};

export default TradevanList;
