import { ThemeProvider } from 'styled-components';
import { Theme } from '@shopline/dashboard-ui';
import { Provider as StoreProvider } from 'react-redux';

import '../locales';
import FeatureRoute from './FeatureRoute';

import configureStore from '../stores/configureStore';
import GlobalStyle from '../components/GlobalStyle';

const store = configureStore();

const Features = () => (
  <StoreProvider store={store}>
    <ThemeProvider theme={Theme}>
      <GlobalStyle />
      <FeatureRoute />
    </ThemeProvider>
  </StoreProvider>
);

export default Features;
