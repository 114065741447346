import { useEffect } from 'react';
import DayList from './DayList';

const View = ({
  parameters,
  updateParams,
  list,
  isLoadingList,
  getByDayList,
  pagination,
  channels,
  getChannels,
}) => {
  useEffect(() => {
    getChannels();
  }, [getChannels]);

  return (
    <DayList
      parameters={parameters}
      updateParams={updateParams}
      list={list}
      isLoadingList={isLoadingList}
      getByDayList={getByDayList}
      pagination={pagination}
      channels={channels}
    />
  );
};

export default View;
