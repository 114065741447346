import { useMemo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { negate } from 'lodash-es';
import getOr from 'lodash/fp/getOr';

import { dispatchRouteChange } from 'utils/message/postMessageCenter';
import Page from 'components/Page';
import TextLabelDropdown from 'components/TextLabelDropdown';
import TitleButtonSection from './ByOrderList/TitleButtonSection';
import ByDayList from './ByDayList';
import ByOrderList from './ByOrderList';

import { E2E_CETUSTEK_BY_ORDER_PREFIX } from '../constants';

const merchantSelector = getOr({}, ['merchant']);

const hasExportPermissionSelector = createSelector(
  merchantSelector,
  getOr(false, ['permissions', 'invoices', 'export']),
);

const exportDisabledSelector = negate(hasExportPermissionSelector);

const CetustekList = () => {
  const { t } = useTranslation();
  const exportDisabled = useSelector(exportDisabledSelector);
  const { listType } = useParams();
  const history = useHistory();
  const [isExportPopupOpen, setIsExportPopupOpen] = useState(false);

  const breadcrumbs = useMemo(
    () => [
      {
        id: t('invoiceManagement:Retail Store'),
        label: t('invoiceManagement:Retail Store'),
      },
      {
        id: t('invoiceManagement:Invoice and Tax'),
        label: t('invoiceManagement:Invoice and Tax'),
      },
    ],
    [t],
  );

  const tabOptions = useMemo(
    () => [
      {
        key: 'byOrder',
        value: 'by-order',
        label: t('invoiceManagement:Invoice'),
      },
      {
        key: 'byDay',
        value: 'by-day',
        label: t('invoiceManagement:List By Day'),
      },
    ],
    [t],
  );

  const onChangeTab = useCallback(
    (value) => {
      const targetPath = `/invoice-management/cetustek/list/${value}`;
      history.push(targetPath);
      dispatchRouteChange({ pathname: targetPath });
    },
    [history],
  );

  const onClickExport = useCallback(() => {
    setIsExportPopupOpen(true);
  }, []);

  return (
    <Page
      titleLeftSection={
        <TextLabelDropdown
          e2eId={`${E2E_CETUSTEK_BY_ORDER_PREFIX}text-label-dropdown`}
          value={listType}
          options={tabOptions}
          onChange={onChangeTab}
          placeHolder=""
        />
      }
      titleRightSection={
        listType === 'by-order' ? (
          <TitleButtonSection
            exportDisabled={exportDisabled}
            onClickExport={onClickExport}
          />
        ) : null
      }
      breadcrumbs={breadcrumbs}
    >
      {listType === 'by-day' && <ByDayList />}
      {listType === 'by-order' && (
        <ByOrderList
          isExportPopupOpen={isExportPopupOpen}
          setIsExportPopupOpen={setIsExportPopupOpen}
        />
      )}
    </Page>
  );
};

export default CetustekList;
