import { get } from 'lodash-es';
import ExportToastMessage from 'features/ToastList/components/ExportToastMessage';
import { cookApiSearchParams } from './getList';
import { showUIToast } from '../../../toasts/actions';

export const exportByOrderList =
  ({ rangeType, parameters, range, list }) =>
  async (dispatch, getState, { api }) => {
    const state = getState();
    const receiverEmail = get(state, 'merchant.staff.email', '');
    const apiBody =
      rangeType === 'CURRENT'
        ? {
            invoice_ids: list.map((item) => item.id),
          }
        : cookApiSearchParams({
            ...parameters,
            startDate: range.start_date,
            endDate: range.end_date,
          });

    try {
      await api.post('/cetustek-invoices/export', apiBody);
      dispatch(
        showUIToast({
          type: 'success',
          title: 'invoiceManagement:Exporting Your Invoice Report',
          descriptionNodeWithProps: {
            node: ExportToastMessage,
            props: { receiverEmail },
          },
        }),
      );
    } catch (e) {
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.error(e);
      }
      dispatch(
        showUIToast({
          type: 'warning',
          title: 'invoiceManagement:Failed to export',
        }),
      );
    }
  };
