import { GET_CHANNELS_REQUEST, GET_CHANNELS_SUCCESS, GET_CHANNELS_FAIL } from '../actions/getChannels';

const initState = {
  isLoadingChannels: false,
  channels: [],
};

const channels = (state = initState, action) => {
  switch (action.type) {
    case GET_CHANNELS_REQUEST: {
      return { ...state, isLoadingChannels: true };
    }
    case GET_CHANNELS_SUCCESS: {
      const { data } = action.payload;
      const { items } = data;
      return { ...state, channels: items, isLoadingChannels: false };
    }
    case GET_CHANNELS_FAIL: {
      return { ...state, isLoadingChannels: false };
    }
    default:
      return state;
  }
};

export default channels;
