import { memo, useMemo, useCallback } from 'react';
import { media } from '@shopline/dashboard-ui';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { cond, stubTrue, constant } from 'lodash-es';
import isEqual from 'lodash/fp/isEqual';

import { CETUSTEK, TRADEVAN } from 'constants/vendor';

import dayjs from 'utils/day';
import getTranslation from 'utils/getTranslation';
import { getTargetConfig } from 'utils/getFilterConfig';

import useTranslateOptions from 'hooks/useTranslateOptions';
import TagPanel from 'components/TagPanel';

import {
  invoiceStatusConfig,
  fixedChannelConfig,
  searchTypeConfig,
} from '../filterConfig';

const TagPanelWrapper = styled.div`
  margin: 0 0 1rem;
  ${media.mobile`
    margin: 0 1rem 1rem;
  `};
`;

const sharedRenderKeys = ['timeRange', 'channel', 'invoiceStatus'];
const tradevanRenderKeys = ['search'];
const cetustekRenderKeys = ['invoiceNumber'];

const getRenderKey = cond([
  [isEqual(TRADEVAN), constant(tradevanRenderKeys.concat(sharedRenderKeys))],
  [isEqual(CETUSTEK), constant(cetustekRenderKeys.concat(sharedRenderKeys))],
  [stubTrue, constant(sharedRenderKeys)],
]);

const ByDayTagPanelSection = ({
  e2eFeaturePrefix,
  vendorType,
  channels,
  parameters,
  updateParams,
}) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const isChinese = useMemo(() => language.includes('zh'), [language]);
  const fixedChannelOptions = useTranslateOptions(fixedChannelConfig);
  const channelsData = useMemo(
    () =>
      fixedChannelOptions.concat(
        channels.map((channel) => ({
          key: channel.id,
          value: channel.id,
          label: getTranslation(channel.name, language),
        })),
      ),
    [fixedChannelOptions, channels, language],
  );

  const onRemoveSearchCondition = useCallback(
    (e, conditions) => {
      const newParams = conditions.reduce((result, { type }) => {
        const params = {};
        switch (type) {
          case 'invoiceNumber':
          case 'channel':
          case 'invoiceStatus': {
            params[type] = undefined;
            break;
          }
          case 'timeRange': {
            params.startDate = undefined;
            params.endDate = undefined;
            break;
          }
          case 'search': {
            params.query = undefined;
            params.field = undefined;
            break;
          }
          default:
            break;
        }
        return { ...result, ...params };
      }, {});
      updateParams({ ...parameters, ...newParams, page: '1' });
    },
    [updateParams, parameters],
  );

  const searchConditions = useMemo(() => {
    const renderedKeys = getRenderKey(vendorType);
    const {
      invoiceNumber,
      field,
      query,
      startDate,
      endDate,
      channel,
      invoiceStatus,
    } = parameters;
    const conditions = renderedKeys.reduce((resultConditions, currentKey) => {
      const newConditions = [];
      const pushCondition = (type, value, title, content) =>
        newConditions.push({
          type,
          value,
          title,
          content: content || value,
          e2eTagId: `${e2eFeaturePrefix}tag-panel-section-tag-${type}`,
        });
      switch (currentKey) {
        case 'invoiceNumber': {
          if (invoiceNumber) {
            pushCondition(
              'invoiceNumber',
              invoiceNumber,
              t('invoiceManagement:Invoice Number'),
            );
          }
          break;
        }
        case 'search': {
          if (field && query) {
            const fieldLabel = t(
              getTargetConfig(searchTypeConfig, field, 'label'),
            );
            pushCondition('search', query, fieldLabel, query);
          }
          break;
        }
        case 'timeRange':
          if (startDate && endDate) {
            const fromTimeString = dayjs(language, startDate).format('LL');
            const toTimeString = dayjs(language, endDate).format('LL');
            pushCondition(
              'timeRange',
              `${fromTimeString} ~ ${toTimeString}`,
              t('invoiceManagement:Invoice Date'),
            );
          }
          break;
        case 'channel':
          if (channel) {
            const target = channelsData.find(
              (channelData) => channelData.value === channel,
            );
            pushCondition(
              'channel',
              channel,
              t('invoiceManagement:Channel'),
              target ? target.label : '',
            );
          }
          break;
        case 'invoiceStatus': {
          if (invoiceStatus) {
            const conditionsLabel = invoiceStatus
              .map((status) =>
                t(getTargetConfig(invoiceStatusConfig, status, 'label')),
              )
              .join(isChinese ? '、' : ', ');
            pushCondition(
              'invoiceStatus',
              conditionsLabel,
              t('invoiceManagement:Invoice Status'),
            );
          }
          break;
        }
        default:
          break;
      }
      return [...resultConditions, ...newConditions];
    }, []);
    return conditions;
  }, [
    t,
    language,
    parameters,
    channelsData,
    isChinese,
    e2eFeaturePrefix,
    vendorType,
  ]);

  return searchConditions.length > 0 ? (
    <TagPanelWrapper>
      <TagPanel tags={searchConditions} onRemove={onRemoveSearchCondition} />
    </TagPanelWrapper>
  ) : null;
};

ByDayTagPanelSection.displayName = 'ByDayTagPanelSection';

export default memo(ByDayTagPanelSection);
