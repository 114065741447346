import React, { useMemo, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  flow,
  iteratee,
  negate,
  identity,
  trimStart,
  trimEnd,
} from 'lodash-es';
import { TextButton } from '@shopline/dashboard-ui';
import { actions } from 'stores/invoiceManagement/actions/tradevanCreate/buyerInfo';
import PARENT_E2E_PREFIX from './e2ePrefix';
import {
  buyerNameRule,
  buyerTelRule,
  getBuyerEmailRule,
} from './validateRules';
import {
  submitKeySelector,
  merchantIdSelector,
  isInvoiceKindPersonalSelector,
} from './selectors/main';
import { buyerInfoSelector } from './selectors/buyerInfo';
import {
  CustomerPicker,
  Field,
  FieldRow,
  FormInput,
  Section,
  SectionSubtitle,
} from './components';

const E2E_PREFIX = `${PARENT_E2E_PREFIX}-buyer-section`;

const selectedFields = ['name', 'tel', 'email'];

const renderRequired = (required) => (required ? '*' : '');

const BuyerSection = () => {
  const { t } = useTranslation();
  const [showPicker, setShowPicker] = useState(false);
  const submitKey = useSelector(submitKeySelector);
  const merchantId = useSelector(merchantIdSelector);
  const buyerInfo = useSelector(buyerInfoSelector);
  const isInvoiceKindPersonal = useSelector(isInvoiceKindPersonalSelector);
  const emailRule = useMemo(
    () => getBuyerEmailRule(isInvoiceKindPersonal),
    [isInvoiceKindPersonal],
  );
  const dispatch = useDispatch();
  const [handleNameChange, handleTelChange, handleEmailChange] = useMemo(
    () =>
      selectedFields.map((field) =>
        flow(
          iteratee('target.value'),
          trimStart,
          field === 'name' ? identity : trimEnd,
          actions.setFieldValue(field),
          dispatch,
        ),
      ),
    [dispatch],
  );
  const handlePickCustomer = useCallback(
    (customer) => {
      dispatch(actions.importCustomer(customer));
      setShowPicker(false);
    },
    [dispatch],
  );
  const handleToggleShowPicker = useCallback(
    () => setShowPicker(negate(identity)),
    [],
  );
  return (
    <>
      <Section title={t("invoiceManagement:Buyer's Information")}>
        <SectionSubtitle>
          <TextButton.Primary
            onClick={handleToggleShowPicker}
            data-e2e-id={`${E2E_PREFIX}-import-btn`}
          >
            {t("invoiceManagement:Import Customer's information")}
          </TextButton.Primary>
        </SectionSubtitle>
        <FieldRow>
          <Field title={t('invoiceManagement:Customer Name')}>
            <FormInput
              block
              value={buyerInfo.name}
              onChange={handleNameChange}
              e2eInputId={`${E2E_PREFIX}-name-input`}
              e2eMessagesId={`${E2E_PREFIX}-name-errors`}
              trimStart
              rule={buyerNameRule}
            />
          </Field>
          <Field title={t('invoiceManagement:Customer Mobile')}>
            <FormInput
              block
              type="tel"
              value={buyerInfo.tel}
              onChange={handleTelChange}
              e2eInputId={`${E2E_PREFIX}-tel-input`}
              e2eMessagesId={`${E2E_PREFIX}-tel-errors`}
              trimStart
              trimEnd
              rule={buyerTelRule}
              fieldName={t('invoiceManagement:Customer Mobile')}
            />
          </Field>
        </FieldRow>
        <FieldRow>
          <Field
            title={`${t('invoiceManagement:Customer Email')}${renderRequired(
              isInvoiceKindPersonal,
            )}`}
          >
            <FormInput
              block
              type="email"
              value={buyerInfo.email}
              onChange={handleEmailChange}
              e2eInputId={`${E2E_PREFIX}-email-input`}
              e2eMessagesId={`${E2E_PREFIX}-email-errors`}
              trimStart
              trimEnd
              rule={emailRule}
              validateKey={submitKey}
              fieldName={t('invoiceManagement:Customer Email')}
            />
          </Field>
        </FieldRow>
      </Section>
      {showPicker && (
        <CustomerPicker
          merchantId={merchantId}
          onClose={handleToggleShowPicker}
          onPick={handlePickCustomer}
        />
      )}
    </>
  );
};

export default BuyerSection;
