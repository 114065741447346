import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Popup,
  RadioButton,
  DateRangePicker,
} from '@shopline/dashboard-ui';
import styled from 'styled-components';
import dayjs from 'dayjs';

import TextLabel from 'components/TextLabel';

const ExportRangeTitle = styled(TextLabel)`
  display: block;
  margin-bottom: 0.5rem;
`;

const DateRangePickerContainer = styled.div`
  padding-left: 1.5rem;
  width: 100%;
`;

const Divider = styled.div`
  margin: 1rem 0;
  border: ${({ theme }) => `1px solid ${theme.colors.SKY_DEFAULT}`};
`;

const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  > * {
    margin-bottom: 0.5rem;
  }
  & label {
    align-items: center;
  }
  & label > div > span {
    max-width: unset;
  }
`;

const rangeTypes = [
  { key: 'CURRENT', label: 'invoiceManagement:Current Page' },
  { key: 'CUSTOM', label: 'invoiceManagement:Select Date Range' },
];

const initDateRanges = () => () => {
  const now = dayjs();
  const startOfMonth = now.date(1).toDate();
  const current = now.toDate();
  return [startOfMonth, current];
};

const ExportPopup = ({ onClose, onSubmit }) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const [rangeType, setRangeType] = useState('CURRENT');
  const [ranges, setRanges] = useState(initDateRanges());

  const isInvalidDate = useMemo(() => {
    const startDate = ranges?.[0];
    const endDate = ranges?.[1];
    return (
      startDate &&
      endDate &&
      dayjs.duration(dayjs(endDate).diff(dayjs(startDate))).asDays() > 99
    );
  }, [ranges]);

  const renderFooter = () => (
    <>
      <Button.Default height="LARGE" onClick={onClose}>
        {t('common:Cancel')}
      </Button.Default>
      <Button.Primary
        height="LARGE"
        width="WIDE"
        onClick={() => {
          onSubmit({
            rangeType,
            range: {
              start_date: dayjs(ranges?.[0]).format('YYYY-MM-DD'),
              end_date: dayjs(ranges?.[1]).format('YYYY-MM-DD'),
            },
          });
        }}
      >
        {t('invoiceManagement:Export')}
      </Button.Primary>
    </>
  );

  return (
    <Popup
      onClose={onClose}
      title={t('invoiceManagement:Export Report')}
      renderFooter={renderFooter}
      width="42rem"
    >
      <ExportRangeTitle fontType="Subheading" color="INK_800" weight="Medium">
        {t('invoiceManagement:Type of Report')}
      </ExportRangeTitle>
      <RadioContainer>
        <RadioButton
          label={t('invoiceManagement:Invoice Details')}
          defaultChecked
        />
      </RadioContainer>
      <Divider />
      <ExportRangeTitle fontType="Subheading" color="INK_800" weight="Medium">
        {t('invoiceManagement:Data Range')}
      </ExportRangeTitle>
      <RadioContainer>
        {rangeTypes.map((type) => (
          <RadioButton
            key={type.key}
            label={t(type.label)}
            checked={rangeType === type.key}
            value={type.key}
            onChange={() => setRangeType(type.key)}
          />
        ))}
      </RadioContainer>
      {rangeType === 'CUSTOM' && (
        <DateRangePickerContainer>
          <DateRangePicker
            block
            lang={language}
            format="YYYY-MM-DD"
            status={isInvalidDate ? 'invalid' : 'valid'}
            defaultValues={ranges}
            onRangeChange={({ range }) => setRanges(range)}
          />
          <TextLabel
            fontType="Caption"
            color={isInvalidDate ? 'RED_600' : 'INK_500'}
          >
            {t('invoiceManagement:maximum date range is 100 days')}
          </TextLabel>
        </DateRangePickerContainer>
      )}
    </Popup>
  );
};

export default ExportPopup;
