import { memo, useState, useCallback } from 'react';
import { media, Icons, Button, DateRangePicker } from '@shopline/dashboard-ui';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import dayjs from 'utils/day';
import useScreenType from 'hooks/useScreenType';
import useTranslateOptions from 'hooks/useTranslateOptions';
import { TRADEVAN, CETUSTEK } from 'constants/vendor';

import PageDropdown from 'components/PageDropdown';
import ChannelDropdown from 'components/ChannelDropdown';
import SearchBar from 'components/SearchBar';
import Dropdown from './Dropdown';
import DropdownSearch from './DropdownSearch';
import ByOrderMobileFiltersPopup from './ByOrderMobileFiltersPopup';

import { fixedChannelConfig } from '../filterConfig';

const E2E_PREFIX = 'filter-section-';

const SelectorWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  ${media.tablet`
    flex-wrap: wrap;
  `}
  ${media.mobile`
    flex-wrap: nowrap;
    padding: 0 1rem;
    margin-bottom: 1rem;
  `};
`;

SelectorWrapper.displayName = 'SelectorWrapper';

const StyledDropdownSearch = styled(DropdownSearch)`
  margin-right: 0.5rem;
  flex-grow: 1;
`;

const SearchBarWrapper = styled.div`
  margin-right: 0.5rem;
  flex-grow: 1;
`;

const DateRangePickerWrapper = styled.div`
  max-width: 16.875rem;
  margin: 0 0.5rem 0rem 0rem;
  flex-shrink: 0;
  ${media.tablet`
    margin-right: 0;
    max-width: 50%;
    flex-grow: 1;
  `}
  ${media.mobile`
    margin-right: 0.5rem;
    min-width: 0;
    flex-shrink: 1;
  `}
`;

DateRangePickerWrapper.displayName = 'DateRangePickerWrapper';

const DropdownWrapper = styled.div`
  display: flex;

  > *:not(:last-child) {
    margin: 0 0.5rem 1rem 0;
  }
  ${media.tablet`
    flex-grow: 1;
    > * {
      width: 33%;
    }
  `}
`;

DropdownWrapper.displayName = 'DropdownWrapper';

const FilterSection = ({
  e2eFeaturePrefix,
  vendorType,
  parameters,
  updateParams,
  channels = [],
}) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const screenType = useScreenType();
  const fixedChannelOptions = useTranslateOptions(fixedChannelConfig);
  const [isMobileFilterOpen, setIsMobileFiltersOpen] = useState(false);

  const onRangeChange = (params) => {
    const [start, end] = params.range || [];
    const startDay = dayjs(language, start);
    const endDay = dayjs(language, end);
    if (!start || !end || startDay.isAfter(endDay))
      updateParams({ startDate: undefined, endDate: undefined, page: '1' });
    else {
      updateParams({
        startDate: new Date(startDay.format('YYYY-MM-DD')),
        endDate: new Date(endDay.format('YYYY-MM-DD')),
        page: '1',
      });
    }
  };

  const onSearch = useCallback(
    ({ searchField, searchValue }) => {
      if (searchField && searchValue) {
        updateParams({
          query: searchValue,
          field: searchField,
          page: '1',
        });
      } else {
        updateParams({
          query: undefined,
          field: undefined,
          page: '1',
        });
      }
    },
    [updateParams],
  );

  const onInvoiceNumberSearch = useCallback(
    (value) => {
      updateParams({
        invoiceNumber: value || undefined,
        page: '1',
      });
    },
    [updateParams],
  );

  const renderSearch = useCallback(() => {
    switch (vendorType) {
      case TRADEVAN:
        return (
          <StyledDropdownSearch
            field={parameters.field}
            query={parameters.query}
            onSearch={onSearch}
          />
        );
      case CETUSTEK:
        return (
          <SearchBarWrapper>
            <SearchBar
              key={parameters.invoiceNumber}
              defaultValue={parameters.invoiceNumber}
              placeholder={t('invoiceManagement:Invoice Number')}
              onSearch={onInvoiceNumberSearch}
            />
          </SearchBarWrapper>
        );
      default:
        return null;
    }
  }, [t, vendorType, parameters, onSearch, onInvoiceNumberSearch]);

  return (
    <>
      <SelectorWrapper>
        {renderSearch()}
        {screenType !== 'mobile' && (
          <DateRangePickerWrapper>
            <DateRangePicker
              data-e2e-id={`${e2eFeaturePrefix}${E2E_PREFIX}date-picker`}
              key={
                JSON.stringify(parameters.startDate) +
                JSON.stringify(parameters.endDate)
              }
              onRangeChange={onRangeChange}
              lang={language}
              {...(parameters.startDate &&
                parameters.endDate && {
                  defaultValues: [parameters.startDate, parameters.endDate],
                })}
              block
            />
          </DateRangePickerWrapper>
        )}
        {screenType !== 'mobile' ? (
          <DropdownWrapper>
            <ChannelDropdown
              e2eId={`${e2eFeaturePrefix}${E2E_PREFIX}channel-dropdown`}
              block
              width={screenType === 'desktop' ? '7.5rem' : '100%'}
              fixedOptions={vendorType === TRADEVAN ? fixedChannelOptions : []}
              channels={channels}
              placeHolder={t('invoiceManagement:Channel')}
              value={parameters.channel}
              onChange={(value) =>
                updateParams({ channel: value[0], page: '1' })
              }
              disabledShadow
            />
            <Dropdown.InvoiceStatus
              e2eId={`${e2eFeaturePrefix}${E2E_PREFIX}invoice-status-dropdown`}
              width={screenType === 'desktop' ? '7.5rem' : '100%'}
              value={parameters.invoiceStatus || []}
              onChange={(value) =>
                updateParams({
                  invoiceStatus: value.length ? value : undefined,
                  page: '1',
                })
              }
            />
            <PageDropdown
              e2eId={`${e2eFeaturePrefix}${E2E_PREFIX}page-dropdown`}
              width={screenType === 'desktop' ? '5.25rem' : '100%'}
              minHeight="2.25rem"
              value={[parameters.limit.toString()]}
              onChange={(value) => updateParams({ limit: value[0], page: '1' })}
            />
          </DropdownWrapper>
        ) : (
          <Button.Default
            e2eId={`${e2eFeaturePrefix}${E2E_PREFIX}mobile-filter-btn`}
            Icon={Icons.Outline.Filter}
            width="SQUARE"
            height="SQUARE"
            onClick={() => setIsMobileFiltersOpen(true)}
          />
        )}
      </SelectorWrapper>
      {isMobileFilterOpen && (
        <ByOrderMobileFiltersPopup
          e2eFeaturePrefix={e2eFeaturePrefix}
          vendorType={vendorType}
          channels={channels}
          initParams={parameters}
          onCancel={() => setIsMobileFiltersOpen(false)}
          onConfirm={(newParameters) => {
            updateParams({ ...newParameters, page: '1' });
            setIsMobileFiltersOpen(false);
          }}
        />
      )}
    </>
  );
};

FilterSection.displayName = 'FilterSection';

export default memo(FilterSection);
