import { createSelector, createStructuredSelector } from 'reselect';
import {
  concat,
  cond,
  constant,
  curryRight,
  flow,
  iteratee,
  mapValues,
  noop,
  negate,
  overEvery,
  size,
  stubTrue,
  transform,
} from 'lodash-es';
import eq from 'lodash/fp/eq';
import lt from 'lodash/fp/lt';
import lte from 'lodash/fp/lte';
import add from 'lodash/fp/add';
import all from 'lodash/fp/all';
import getOr from 'lodash/fp/getOr';
import * as taxTypes from 'constants/taxTypes';
import * as apiTaxTypes from 'constants/apiTaxTypes';
import * as invoiceKinds from 'constants/invoiceKinds';
import * as personalCarrierTypes from 'constants/personalCarrierTypes';
import * as apiCarrierTypes from 'constants/apiCarrierTypes';
import validate from 'utils/validate';
import * as validateRules from '../validateRules';
import { toDropdownValue } from '../utils';
import {
  subtotalSelector,
  itemIdListSelector,
  itemMapSelector as invoiceItemMapSelector,
} from './invoiceItems';
import {
  totalDiscountSelector,
  itemMapSelector as discountItemMapSelector,
  discountIdListSelector,
} from './discountItems';
import {
  buyerNameSelector,
  buyerTelSelector,
  buyerEmailSelector,
  buyerCustomerIdSelector,
} from './buyerInfo';
import {
  companyAddressSelector,
  companyTitleSelector,
  companyUniformNumberSelector,
} from './companyInfo';
import { duplicateAddressSelector } from './duplicateInvoice';
import { carrierTypeSelector, carrierValueSelector } from './personalCarrier';
import { donationCodeValueSelector } from './donationCode';

const merchantSelector = iteratee('merchant');

export const merchantIdSelector = createSelector(
  merchantSelector,
  iteratee('merchantData.id'),
);

const merchantSettingSelector = createSelector(
  merchantSelector,
  iteratee('merchantSetting'),
);

export const merchantTaxTypeSelector = createSelector(
  merchantSettingSelector,
  iteratee('invoice_tradevan.tax_type'),
);

export const defaultTaxTypeSelector = createSelector(
  merchantTaxTypeSelector,
  cond([
    [eq('1'), constant(taxTypes.TAXABLE)],
    [eq('2'), constant(taxTypes.ZERO_TAX_RATE_NON_CUSTOMS_EXPORT)],
    [eq('3'), constant(taxTypes.TAX_FREE)],
    [eq('5'), constant(taxTypes.ZERO_TAX_RATE_CUSTOMS_EXPORT)],
    [stubTrue, constant(taxTypes.TAXABLE)],
  ]),
);

const defaultDonationSettingSelector = createSelector(
  merchantSettingSelector,
  iteratee('invoice_tradevan.n_p_o_b_a_n'),
);

export const defaultDonationCodeSelector = createSelector(
  defaultDonationSettingSelector,
  getOr('', 'code'),
);

const defaultDonationNameSelector = createSelector(
  defaultDonationSettingSelector,
  getOr('', 'name'),
);

export const defaultDonationDisplaySelector = createSelector(
  defaultDonationCodeSelector,
  defaultDonationNameSelector,
  (code, name) => (code ? `${code} ${name}` : ''),
);

const mainSelector = iteratee('invoiceManagement.tradevanCreate.main');

export const submitKeySelector = createSelector(
  mainSelector,
  iteratee('submitKey'),
);

const taxTypeSelector = createSelector(mainSelector, iteratee('taxType'));

export const taxTypeValueSelector = createSelector(
  taxTypeSelector,
  toDropdownValue,
);

const invoiceKindSelector = createSelector(
  mainSelector,
  iteratee('invoiceKind'),
);

export const invoiceKindValueSelector = createSelector(
  invoiceKindSelector,
  toDropdownValue,
);

export const shippingFeeSelector = createSelector(
  mainSelector,
  iteratee('shippingFee'),
);

export const extraFeeSelector = createSelector(
  mainSelector,
  iteratee('extraFee'),
);

export const totalSelector = createSelector(
  subtotalSelector,
  shippingFeeSelector,
  extraFeeSelector,
  totalDiscountSelector,
  (...args) => args.reduce(add),
);

export const maxDiscountValueSelector = createSelector(totalSelector, add(-1));

export const submitDisabledSelector = createSelector(
  mainSelector,
  negate(iteratee('touched')),
);

const companyunSelector = createSelector(
  merchantSettingSelector,
  iteratee('invoice_tradevan.tax_id'),
);

const orgIdSelector = createSelector(
  merchantSettingSelector,
  iteratee('invoice_tradevan.source.business_type_code'),
);

export const {
  PERSONAL: isInvoiceKindPersonalSelector,
  DONATION: isInvoiceKindDonationSelector,
  COMPANY: isInvoiceKindCompanySelector,
  DUPLICATE: isInvoiceKindDuplicateSelector,
} = mapValues(invoiceKinds, (kind) =>
  createSelector(invoiceKindSelector, eq(kind)),
);

const buyerCompanyunSelector = cond([
  [isInvoiceKindCompanySelector, companyUniformNumberSelector],
  [stubTrue, noop],
]);

const buyerAddressSelector = cond([
  [isInvoiceKindCompanySelector, companyAddressSelector],
  [
    createSelector(invoiceKindSelector, eq(invoiceKinds.DUPLICATE)),
    duplicateAddressSelector,
  ],
  [stubTrue, noop],
]);

const buyerTitleSelector = cond([
  [isInvoiceKindCompanySelector, companyTitleSelector],
  [stubTrue, noop],
]);

const donateMarkSelector = cond([
  [isInvoiceKindPersonalSelector, constant(0)],
  [isInvoiceKindDonationSelector, constant(1)],
  [stubTrue, constant(2)],
]);

const {
  MEMBER: isPersonalCarrierTypeMemberSelector,
  MOBILE_BARCODE: isPersonalCarrierTypeMobileBarcodeSelecctor,
  DIGITAL_CERTIFICATE: isPersonalCarrierTypeDigitalCertificateSelecctor,
} = mapValues(personalCarrierTypes, (type) =>
  createSelector(carrierTypeSelector, eq(type)),
);

const carrierTypeForApiSelector = cond([
  [
    isInvoiceKindPersonalSelector,
    cond([
      [isPersonalCarrierTypeMemberSelector, constant(apiCarrierTypes.MEMBER)],
      [
        isPersonalCarrierTypeMobileBarcodeSelecctor,
        constant(apiCarrierTypes.MOBILE_BARCODE),
      ],
      [
        isPersonalCarrierTypeDigitalCertificateSelecctor,
        constant(apiCarrierTypes.DIGITAL_CERTIFICATE),
      ],
      [stubTrue, noop],
    ]),
  ],
  [stubTrue, noop],
]);

const memberCarrierValueSelector = createSelector(
  buyerCustomerIdSelector,
  (customerId) => `ECI02308M${customerId}`,
);

const carrierIdSelector = cond([
  [
    isInvoiceKindPersonalSelector,
    cond([
      [isPersonalCarrierTypeMemberSelector, memberCarrierValueSelector],
      [isPersonalCarrierTypeMobileBarcodeSelecctor, carrierValueSelector],
      [isPersonalCarrierTypeDigitalCertificateSelecctor, carrierValueSelector],
      [stubTrue, noop],
    ]),
  ],
  [stubTrue, noop],
]);

const donationCodeValueForApiSelector = cond([
  [isInvoiceKindDonationSelector, donationCodeValueSelector],
  [stubTrue, noop],
]);

const paperPrintModeSelector = cond([
  [isInvoiceKindCompanySelector, constant(4)],
  [isInvoiceKindDuplicateSelector, constant(4)],
  [stubTrue, constant(0)],
]);

const invoiceAlarmModeSelector = cond([
  [
    overEvery([
      isInvoiceKindPersonalSelector,
      isPersonalCarrierTypeMemberSelector,
    ]),
    constant(2),
  ],
  [isInvoiceKindCompanySelector, constant(2)],
  [stubTrue, constant(0)],
]);

const taxTypeMap = transform(
  taxTypes,
  (result, value, key) => {
    result[value] = apiTaxTypes[key];
  },
  {},
);
const toApiTaxType = (type) => taxTypeMap[type];

const taxTypeForApiSelector = createSelector(taxTypeSelector, toApiTaxType);

const itemsForApiSelector = createSelector(
  invoiceItemMapSelector,
  itemIdListSelector,
  taxTypeForApiSelector,
  (itemMap, idList, taxType) =>
    idList.map((id) => ({
      description: itemMap[id].name,
      unit_price: itemMap[id].price,
      quantity: itemMap[id].qty,
      tax_type: taxType,
      production_code: 'Product',
    })),
);

const discountsForApiSelector = createSelector(
  discountItemMapSelector,
  discountIdListSelector,
  taxTypeForApiSelector,
  (itemMap, idList, taxType) =>
    idList.map((id) => ({
      description: itemMap[id].name,
      unit_price: itemMap[id].discountValue * -1,
      quantity: 1,
      tax_type: taxType,
      production_code: 'Discount',
    })),
);

const shippingItemSelector = createSelector(
  shippingFeeSelector,
  taxTypeForApiSelector,
  (shippingFee, taxType) =>
    shippingFee === 0
      ? []
      : [
          {
            description: '運費',
            unit_price: shippingFee,
            quantity: 1,
            tax_type:
              taxType === apiTaxTypes.TAX_FREE ? apiTaxTypes.TAXABLE : taxType,
            production_code: 'Delivery fee',
          },
        ],
);

const extraItemSelector = createSelector(
  extraFeeSelector,
  taxTypeForApiSelector,
  (extraFee, taxType) =>
    extraFee === 0
      ? []
      : [
          {
            description: '附加費',
            unit_price: extraFee,
            quantity: 1,
            tax_type:
              taxType === apiTaxTypes.TAX_FREE ? apiTaxTypes.TAXABLE : taxType,
            production_code: 'Payment fee',
          },
        ],
);

const detailsSelector = createSelector(
  itemsForApiSelector,
  shippingItemSelector,
  extraItemSelector,
  discountsForApiSelector,
  concat,
);

const isValid = curryRight((value, rule) => validate(value, rule) === '');

const isValidMobileBarcodeSelector = createSelector(
  carrierValueSelector,
  isValid(validateRules.mobileBarcodeRule),
);

const isValidDigitalCertificateSelector = createSelector(
  carrierValueSelector,
  isValid(validateRules.digitalCertificateRule),
);

const isValidPersonalCarrierValueSelector = cond([
  [isPersonalCarrierTypeMobileBarcodeSelecctor, isValidMobileBarcodeSelector],
  [
    isPersonalCarrierTypeDigitalCertificateSelecctor,
    isValidDigitalCertificateSelector,
  ],
  [stubTrue, stubTrue],
]);

const isValidCompanyInfoSelector = overEvery([
  createSelector(
    buyerCompanyunSelector,
    isValid(validateRules.companyUniformNumberRule),
  ),
  createSelector(
    buyerAddressSelector,
    isValid(validateRules.companyAddressRule),
  ),
]);

const isValidDuplicateInvoiceSelector = createSelector(
  buyerAddressSelector,
  isValid(validateRules.duplicateAddressRule),
);

const isValidInvoiceKindValuesSelector = cond([
  [isInvoiceKindPersonalSelector, isValidPersonalCarrierValueSelector],
  [isInvoiceKindCompanySelector, isValidCompanyInfoSelector],
  [isInvoiceKindDuplicateSelector, isValidDuplicateInvoiceSelector],
  [stubTrue, stubTrue],
]);

const isValidBuyerInfoSelector = overEvery([
  createSelector(buyerNameSelector, isValid(validateRules.buyerNameRule)),
  createSelector(buyerTelSelector, isValid(validateRules.buyerTelRule)),
  createSelector(
    buyerEmailSelector,
    isInvoiceKindPersonalSelector,
    (buyerEmail, required) =>
      isValid(buyerEmail, validateRules.getBuyerEmailRule(required)),
  ),
]);

const isValidItem = ({ description, unit_price: price, quantity: qty }) =>
  isValid(description, validateRules.itemNameRule) &&
  Number.isInteger(price) &&
  Number.isInteger(qty) &&
  lt(0, qty);

const isValidItemsSelector = overEvery([
  createSelector(itemIdListSelector, flow(size, lt(0))),
  createSelector(itemsForApiSelector, all(isValidItem)),
]);

const isValidShippingFeeSelector = createSelector(
  shippingFeeSelector,
  Number.isInteger,
);

const isValidExtraFeeSelector = createSelector(
  extraFeeSelector,
  Number.isInteger,
);

const isValidDiscount = ({ description, unit_price: price }) =>
  isValid(description, validateRules.discountNameRule) &&
  Number.isInteger(price);

const isValidDiscountsSelector = createSelector(
  discountsForApiSelector,
  all(isValidDiscount),
);

const isValidTotalDiscountSelector = createSelector(
  subtotalSelector,
  totalDiscountSelector,
  (subtotal, totalDiscount) => lte(0, subtotal + totalDiscount),
);

const isValidTotalSelector = createSelector(totalSelector, lt(0));

export const isValidInvoiceAmountSelector = overEvery([
  isValidTotalDiscountSelector,
  isValidTotalSelector,
]);

export const isValidFormSelector = overEvery([
  isValidInvoiceKindValuesSelector,
  isValidBuyerInfoSelector,
  isValidItemsSelector,
  isValidShippingFeeSelector,
  isValidExtraFeeSelector,
  isValidDiscountsSelector,
]);

export const createInvoicePayloadSelector = createStructuredSelector({
  companyun: companyunSelector,
  orgid: orgIdSelector,
  buyer_companyun: buyerCompanyunSelector,
  buyer_name: buyerNameSelector,
  buyer_address: buyerAddressSelector,
  buyer_title: buyerTitleSelector,
  buyer_telephone: buyerTelSelector,
  buyer_email: buyerEmailSelector,
  donate_mark: donateMarkSelector,
  carrier_type: carrierTypeForApiSelector,
  carrier_id1: carrierIdSelector,
  carrier_id2: carrierIdSelector,
  n_p_o_b_a_n: donationCodeValueForApiSelector,
  paper_print_mode: paperPrintModeSelector,
  invoice_alarm_mode: invoiceAlarmModeSelector,
  details: detailsSelector,
});
