import { useMemo, useCallback, memo } from 'react';
import { Tabs } from '@shopline/dashboard-ui';
import { useTranslation } from 'react-i18next';
import { invoiceTypeConfig } from '../filterConfig';

const InvoiceTypeTab = ({ currentTab, onClick }) => {
  const { t } = useTranslation();

  const tabsConfig = useMemo(
    () =>
      invoiceTypeConfig.map((config) => ({
        ...config,
        label: t(config.label),
      })),
    [t],
  );

  const onClickTab = useCallback(
    (tab) => (e) => {
      if (onClick) onClick(e, tab);
    },
    [onClick],
  );

  return (
    <Tabs.TabsHeader
      backgroundColor="SNOW_200"
      renderTabs={(Tab) =>
        tabsConfig.map((tab) => (
          <Tab
            key={tab.key}
            label={tab.label}
            isActive={currentTab === tab.key}
            onClick={onClickTab(tab.key)}
            isDisabled={false}
            isError={false}
          />
        ))
      }
    />
  );
};

export default memo(InvoiceTypeTab);
