import { useReducer, useCallback, useEffect } from 'react';
import getProducts from './getProducts';
import { actionTypes, initialState, reducer } from './reducer';

const PER_PAGE = 10;

const useProducts = ({ merchantId }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const loadMore = useCallback(async () => {
    if (state.currentPage >= state.totalPages) {
      return;
    }
    try {
      dispatch({ type: actionTypes.LOAD_MORE });
      const { pagination, items } = await getProducts({
        merchantId,
        page: state.currentPage + 1,
        perPage: PER_PAGE,
        keyword: state.keyword,
      });
      dispatch({
        type: actionTypes.REQUEST_SUCCESS,
        payload: {
          ...pagination,
          data: items,
        },
      });
    } catch (error) {
      dispatch({ type: actionTypes.REQUEST_FAIL, payload: error });
    }
  }, [merchantId, state.keyword, state.currentPage, state.totalPages]);
  const setKeyword = useCallback(
    (keyword) =>
      dispatch({
        type: actionTypes.SET_KEYWORD,
        payload: keyword,
      }),
    [],
  );
  const toggleSelection = useCallback(
    (item) =>
      dispatch({
        type: actionTypes.TOGGLE_SELECTION,
        payload: item,
      }),
    [],
  );
  useEffect(() => {
    (async () => {
      try {
        dispatch({ type: actionTypes.REQUEST });
        const { pagination, items } = await getProducts({
          merchantId,
          page: 1,
          perPage: PER_PAGE,
          keyword: state.keyword,
        });
        dispatch({
          type: actionTypes.REQUEST_SUCCESS,
          payload: {
            ...pagination,
            data: items,
          },
        });
      } catch (error) {
        dispatch({ type: actionTypes.REQUEST_FAIL, payload: error });
      }
    })();
  }, [merchantId, state.keyword]);
  return [state, { loadMore, setKeyword, toggleSelection }];
};

export default useProducts;
