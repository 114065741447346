import styled from 'styled-components';
import AddItemButton from './AddItemButton';

const PopoverContainer = styled.div`
  flex: 0 0 100%;
  display: flex;
  margin-top: 0.25rem;
  & > ${AddItemButton} {
    margin-top: 0.5rem;
  }
`;

export default PopoverContainer;
