export const CARRIER_TYPE = {
  // 會員載具
  MEMBER: 'EG0142',
  // 手機條碼
  MOBILE_BARCODE: '3J0002',
  // 自然人憑證條碼
  NPC_BARCODE: 'CQ0001',
};

export const TAX_TYPE = {
  // 應稅
  TAXABLE: 'T',
  // 免稅
  TAX_FREE: 'O',
  // 零稅率-海關出口
  ZERO_TAX_EXPORTED: 'C',
  // 零稅率-非海關出口
  ZERO_TAX_NOT_EXPORTED: 'Z',
};

export const DONATE_MARK = {
  // 0:載具
  CARRIER: 0,
  // 1:捐贈
  DONATE: 1,
  // 2:紙本
  PAPER: 2,
};

// FE defined invoice issue type
export const INVOICE_ISSUE_TYPE = {
  // 雲端發票
  CLOUD: 'cloud',
  // 捐贈發票
  DONATE: 'donate',
  // 公司戶發票
  UNIFORM: 'uniform',
  // 二聯式紙本
  DUPLICATE_UNIFORM: 'duplicateUniform',
  // 紙本發票
  PAPER: 'paper',
};
