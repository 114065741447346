import update from 'immutability-helper';

export const actionTypes = {
  REQUEST: 'REQUEST',
  REQUEST_SUCCESS: 'REQUEST_SUCCESS',
  REQUEST_FAIL: 'REQUEST_FAIL',
  LOAD_MORE: 'LOAD_MORE',
  SET_KEYWORD: 'SET_KEYWORD',
  TOGGLE_SELECTION: 'TOGGLE_SELECTION',
};

export const initialState = {
  loading: false,
  totalCount: 0,
  totalPages: 0,
  currentPage: 1,
  data: [],
  keyword: '',
  error: null,
  selectedMap: new Map(),
};

export function reducer(state, { type, payload }) {
  switch (type) {
    case actionTypes.REQUEST:
      return {
        ...state,
        loading: true,
        totalCount: 0,
        totalPages: 0,
        currentPage: 1,
        data: [],
        error: null,
      };
    case actionTypes.REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        totalCount: payload.totalCount || state.totalCount,
        totalPages: payload.totalPages || state.totalPages,
        currentPage: payload.currentPage || state.currentPage,
        data: state.data.concat(payload.data),
      };
    case actionTypes.REQUEST_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case actionTypes.LOAD_MORE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.SET_KEYWORD:
      return {
        ...state,
        keyword: payload,
      };
    case actionTypes.TOGGLE_SELECTION:
      return update(state, {
        selectedMap: {
          $apply: (map) =>
            map.has(payload.uniqueId)
              ? update(map, {
                  $remove: [payload.uniqueId],
                })
              : update(map, {
                  $add: [[payload.uniqueId, payload]],
                }),
        },
      });
    default:
      throw new Error(`Unknown type: ${type}`);
  }
}
