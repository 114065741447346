import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Table } from '@shopline/dashboard-ui';

import LoadingRows from 'components/Table/LoadingRows';
import WelcomeBox from 'components/Table/Welcomebox';

import useByDayInvoiceTableConfig from '../hooks/useByDayInvoiceTableConfig';

const StyledTableWrapper = styled.div`
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.SNOW_200};

  & thead th:first-child,
  & tbody td:first-child {
    padding-left: 1.5rem;
  }
`;

const ByDayInvoiceTable = ({
  e2eFeaturePrefix = '',
  isLoading = false,
  isEmpty = false,
  excludeColumnKeys = [],
  tableData,
}) => {
  const { t } = useTranslation();

  const { tablePropColumns, dataSource } = useByDayInvoiceTableConfig({
    tableData,
    excludeColumnKeys,
  });

  return (
    <StyledTableWrapper>
      <Table
        e2eId={`${e2eFeaturePrefix}by-order-invoice-table`}
        columns={tablePropColumns}
        dataSource={isLoading ? [] : dataSource}
        rowHeight={3.5625}
        hoverable={false}
      />
      {isEmpty && (
        <WelcomeBox
          welcomeImg="empty.svg"
          welcomeTitle={t('invoiceManagement:No Invoice Record')}
          welcomeSubtitle={t('invoiceManagement:No Invoice Record Description')}
          imgMaxWidth="25.5rem"
        />
      )}
      {isLoading && (
        <LoadingRows withAvatar rowNumber={10} rowHeight="3.5625rem" />
      )}
    </StyledTableWrapper>
  );
};

export default ByDayInvoiceTable;
