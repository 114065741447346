import {
  cond,
  constant,
  flow,
  overEvery,
  isString,
  size,
  stubTrue,
} from 'lodash-es';
import eq from 'lodash/fp/eq';
import startsWith from 'lodash/fp/startsWith';
import lt from 'lodash/fp/lt';

export const getFallbackLangKeys = cond([
  [eq('zh-hant'), constant(['zh-cn', 'en', 'vi', 'th'])],
  [eq('zh-cn'), constant(['zh-hant', 'en', 'vi', 'th'])],
  [eq('en'), constant(['zh-hant', 'zh-cn', 'vi', 'th'])],
  [eq('vi'), constant(['en', 'zh-hant', 'zh-cn', 'th'])],
  [eq('th'), constant(['en', 'zh-hant', 'zh-cn', 'vi'])],
  [startsWith('zh'), constant(['zh-hant', 'zh-cn', 'en', 'vi', 'th'])],
  [stubTrue, constant(['en', 'zh-hant', 'zh-cn', 'vi', 'th'])],
]);

export const isValidTranslationString = overEvery([
  isString,
  flow(size, lt(0)),
]);

const getTranslation = (
  trans,
  langKey,
  fallback = '',
  isValid = isValidTranslationString,
  customFallbackLangKeys = getFallbackLangKeys,
) => {
  const translation = trans[langKey];
  if (isValid(translation)) {
    return translation;
  }
  const fallbackLangKeys = customFallbackLangKeys(langKey);
  const validFallbackKey = fallbackLangKeys.find((key) => isValid(trans[key]));
  return validFallbackKey ? trans[validFallbackKey] : fallback;
};

export default getTranslation;
