import { CETUSTEK } from 'constants/vendor';
import {
  generateGetByDayList,
  generateGetByDateListActionTypes,
} from '../byDay/baseGetByDayList';

export const getByDayListActionTypes =
  generateGetByDateListActionTypes(CETUSTEK);

export const getByDayList = generateGetByDayList(
  CETUSTEK,
  getByDayListActionTypes,
);
