import { curry } from 'lodash-es';

const PREFIX = 'BUYER_INFO';

export const SET_FIELD_VALUE = `${PREFIX}.SET_FIELD_VALUE`;
export const IMPORT_CUSTOMER = `${PREFIX}.IMPORT_CUSTOMER`;

export const actionTypes = {
  SET_FIELD_VALUE,
  IMPORT_CUSTOMER,
};

export const setFieldValue = (field, value) => ({
  type: actionTypes.SET_FIELD_VALUE,
  payload: [field, value],
});

export const importCustomer = ({ id, name, tel, email }) => ({
  type: actionTypes.IMPORT_CUSTOMER,
  payload: { customerId: id, name, tel, email },
});

export const actions = {
  setFieldValue: curry(setFieldValue),
  importCustomer,
};
