import { createSelector } from 'reselect';
import { iteratee } from 'lodash-es';
import { toDropdownValue } from '../utils';

const donationCodeSelector = iteratee(
  'invoiceManagement.tradevanCreate.donationCode',
);
export const donationCodeValueSelector = createSelector(
  donationCodeSelector,
  iteratee('value'),
);
export const donationCodeValueValueSelector = createSelector(
  donationCodeValueSelector,
  toDropdownValue,
);
