import { cond, constant, curryRight, stubTrue } from 'lodash-es';

function withInit(reducer, initialState, initActionType) {
  return cond([
    [(state, { type }) => type === initActionType, constant(initialState)],
    [stubTrue, reducer],
  ]);
}

export default curryRight(withInit);
