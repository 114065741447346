import styled from 'styled-components';
import prop from 'lodash/fp/prop';

const Hr = styled.hr`
  height: 1px;
  width: 100%;
  border: 0;
  background-color: ${prop('theme.colors.INK_200')};
  margin: 1rem 0 0;
`;

export default Hr;
