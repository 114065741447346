import update from 'immutability-helper';

const INPUT_SIZE_SMALL = 'SMALL';

const customTheme = (theme) =>
  update(theme, {
    inputs: {
      size: {
        [INPUT_SIZE_SMALL]: {
          height: {
            $set: '1.875rem',
          },
        },
      },
    },
  });

export default customTheme;
