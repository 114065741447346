import { Button } from '@shopline/dashboard-ui';

const createRenderAction =
  ({ onClick, text }) =>
  ({ uniqueId, name, mobilePhone, email }) =>
    (
      <Button.Primary
        data-import-customer-button
        data-customer-id={uniqueId}
        data-customer-name={name || ''}
        data-customer-mobile-phone={mobilePhone || ''}
        data-customer-email={email || ''}
        onClick={onClick}
      >
        {text}
      </Button.Primary>
    );

export default createRenderAction;
