import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import map from 'lodash/fp/map';
import * as personalCarrierTypes from 'constants/personalCarrierTypes';
import useTranslateOptions from 'hooks/useTranslateOptions';
import { actions as personalCarrierActions } from 'stores/invoiceManagement/actions/tradevanCreate/personalCarrier';
import { createDropdownOptionsMapper } from '../utils';
import {
  carrierTypeValueSelector,
  carrierValueSelector,
} from '../selectors/personalCarrier';
import { Dropdown, Field, FieldRow } from '../components';
import * as PersonalCarrierValue from './PersonalCarrierValue';
import PARENT_E2E_PREFIX from './e2ePrefix';

const E2E_PREFIX = `${PARENT_E2E_PREFIX}-personal`;

const E2E_ID_CARRIER_TYPE_DROPDOWN = `${E2E_PREFIX}-carrier-type`;

const mapCarrierTypeOptions = map(
  createDropdownOptionsMapper(E2E_ID_CARRIER_TYPE_DROPDOWN),
);
const carrierTypeOptions = mapCarrierTypeOptions([
  {
    value: personalCarrierTypes.MEMBER,
    label: 'invoiceManagement:Member',
  },
  {
    value: personalCarrierTypes.MOBILE_BARCODE,
    label: 'invoiceManagement:Mobile Barcode',
  },
  {
    value: personalCarrierTypes.DIGITAL_CERTIFICATE,
    label: 'invoiceManagement:NPC Barcode',
  },
]);

const renderPersonalCarrierValue = (carrierType, carrierValue) => {
  switch (carrierType) {
    case personalCarrierTypes.MEMBER:
      return <PersonalCarrierValue.Member carrierValue={carrierValue} />;
    case personalCarrierTypes.MOBILE_BARCODE:
      return <PersonalCarrierValue.MobileBarcode carrierValue={carrierValue} />;
    case personalCarrierTypes.DIGITAL_CERTIFICATE:
      return (
        <PersonalCarrierValue.DigitalCertificate carrierValue={carrierValue} />
      );
    default:
      return null;
  }
};

const Personal = () => {
  const { t } = useTranslation();
  const carrierType = useSelector(carrierTypeValueSelector);
  const carrierValue = useSelector(carrierValueSelector);
  const translatedCarrierTypeOptions = useTranslateOptions(carrierTypeOptions);
  const dispatch = useDispatch();
  const handleCarrierTypeChange = useCallback(
    ([value]) => {
      dispatch(personalCarrierActions.setType(value));
    },
    [dispatch],
  );
  return (
    <FieldRow>
      <Field title={`${t('invoiceManagement:Carrier')}*`}>
        <Dropdown
          onChange={handleCarrierTypeChange}
          value={carrierType}
          options={translatedCarrierTypeOptions}
          placeHolder=""
          e2eId={E2E_ID_CARRIER_TYPE_DROPDOWN}
        />
      </Field>
      {renderPersonalCarrierValue(carrierType[0], carrierValue)}
    </FieldRow>
  );
};

export default Personal;
