import { createSelector } from 'reselect';
import { cond, eq, iteratee, stubTrue } from 'lodash-es';

export const buyerInfoSelector = iteratee(
  'invoiceManagement.tradevanCreate.buyerInfo',
);

export const buyerNameSelector = createSelector(
  buyerInfoSelector,
  iteratee('name'),
);

export const buyerTelSelector = createSelector(
  buyerInfoSelector,
  iteratee('tel'),
);

export const buyerEmailSelector = createSelector(
  buyerInfoSelector,
  iteratee('email'),
);

const importedEmailSelector = createSelector(
  buyerInfoSelector,
  iteratee('importedEmail'),
);

const isImportedCustomer = createSelector(
  buyerEmailSelector,
  importedEmailSelector,
  eq,
);

const customerIdSelector = createSelector(
  buyerInfoSelector,
  iteratee('customerId'),
);

export const buyerCustomerIdSelector = cond([
  [isImportedCustomer, customerIdSelector],
  [stubTrue, buyerEmailSelector],
]);
