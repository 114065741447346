const invoiceManagement = {
  'Invoice Management': '发票管理',
  Invoice: '发票',
  'Create Invoice': '新增发票',
  'Invoice Details': '发票明细',
  'Order Invoice': '订单发票',
  'Non-order Invoice': '独立发票',
  'Invoice Number': '发票号码',
  'Order Number': '订单号码',
  'Invoice Date': '发票日期',
  'Sales Amount': '销售额',
  Tax: '营业税额',
  'Total Amount': '总计',
  'Invoice Status': '发票状态',
  Issued: '已开立',
  Voided: '已作废',
  Void: '作废',
  Channel: '开立渠道',
  Shop: '网店',
  'Reason for voiding invoice': '作废原因',
  'Please enter reason for voiding invoice': '请输入作废原因',
  'Successfully created an invoice': '成功开立发票 {{invoiceNo}}',
  'Successfully voided an invoice': '成功作废发票 {{invoiceNo}}',
  'Failed to void the invoice': '作废发票失败',
  Export: '汇出',
  Create: '新增',
  'Export Report': '汇出报表',
  'Type of Report': '报表类型',
  'Data Range': '选择范围',
  'Current Page': '目前页面',
  'Select Date Range': '自订区间',
  'maximum date range is 100 days': '最多 100 天',
  'The invoice has been voided': '该发票已经作废过',
  "Can't void invoice that passes tax filing period":
    '发票已过申报期间不得作废',
  'The invoice has been issued': '该发票已经开立过',
  'No available invoice number ': '未有可使用之发票号码，导致开立失败',
  'Invoice Information': '发票资讯',
  'Voided Date': '作废时间',
  'Type of Invoice Request': '发票类型',
  Carrier: '载具类型',
  'Non-physical electronic invoice': '云端发票',
  'Donate e-invoice': '捐增发票',
  'Donation Units': '捐赠单位',
  'Uniform invoice': '公司户发票',
  'Duplicate uniform invoice': '二联式纸本',
  'Paper invoice': '纸本发票',
  Member: '会员载具（发票资讯会寄到顾客电邮)',
  'Mobile Barcode': '手机条码',
  'NPC Barcode': '自然人凭证条码',
  'Company Name': '发票抬头',
  'Tax ID': '统一编号',
  'Send to': '发票寄送地址',
  'Send to optional': '发票寄送地址（选填）',
  "Buyer's Information": '买受人资讯',
  "Buyer's Name": '买受人姓名',
  'Mobile Phone': '手机号码',
  'Order Details': '订单详情',
  Subtotal: '小计',
  'Delivery Fee': '运费',
  'Payment Fee': '附加费',
  Total: '合计',
  'Tax Type': '税別',
  TaxTypeDisplay: '',
  TaxTypeDisplay_T: '应税',
  TaxTypeDisplay_O: '免税',
  TaxTypeDisplay_C: '零税率-海关出口',
  TaxTypeDisplay_Z: '零税率-非海关出口',
  Taxable: '$t(invoiceManagement:TaxTypeDisplay, {"context": "T"})',
  'Tax Free': '$t(invoiceManagement:TaxTypeDisplay, {"context": "O"})',
  'Zero-Tax-Rate - Exported by customs':
    '$t(invoiceManagement:TaxTypeDisplay, {"context": "C"})',
  'Zero-Tax-Rate - Not exported by customs':
    '$t(invoiceManagement:TaxTypeDisplay, {"context": "Z"})',
  'Product Information': '商品资讯',
  'Product Information Description': '仅用于开立发票，不影响商品库存',
  'Import Product information': '带入商品资讯',
  'Custom Item': '自订商品',
  'Add Discount': '新增折扣',
  'please enter {xxxx}': '请输入{xxxx}',
  'Address is up to 100 characters': '地址最多100个字元',
  'at least one product is required': '请新增至少 1 笔商品资讯',
  'up tp 250 characters only': '最多250个字元',
  'Total amounts need to be > 0': '合计金额需大于 0',
  'Please fill out all the required field': '请输入必填栏位',
  'Void Invoice': '作废发票',
  'No Invoice Record': '没有发票记录',
  'No Invoice Record Description': '在 SHOPLINE 开立、作废的发票纪录将显示于此',
  'Exporting Your Invoice Report': '正在输出你的发票记录',
  'Invoice Report': '发票报表',
  'List By Day': '日结列出',
  'Start - End Number': '发票起讫号码',
  'Total Taxable Invoice Amount': '应税发票总金额',
  'Total Tax Exempt Invoice Amount': '免税发票总金额',
  'Total Zero-Tax-Rate Invoice Amount': '零税发票总金额',
  'Void Invoice Number': '作废发票号码',
  'Customer Name': '姓名',
  'Customer Mobile': '手机号码',
  'Customer Email': 'Email',
  'Is A Member': '已是会员',
  Import: '带入',
  'Invoice Amount': '发票金额',
  'Items Subtotal': '商品小计',
  'Discount Name': '折扣名称',
  'Discount Amount': '折扣金额',
  'Product Title': '商品',
  'Product Variations': '款式',
  'Product Price': '价格 (含税）',
  'Search Product Placeholder': '搜寻商品名称/代号',
  'View Selected Products': '检视已选商品({{count}})',
  'View All Products': '检视全部商品',
  'Search Customer Placeholder': '搜寻顾客姓名 / 手机号码 / Email',
  'Save Discount': '储存',
  'No Products Found': '没有商品资讯',
  'No Result': '没有结果',
  'Try Again Search': '请试试其他关键字或搜寻条件',
  'Invoice and Tax': '发票报税',
  'Retail Store': '实体店管理',
  'Failed to create': '开立发票失败',
  'Error Description': '请再次尝试，若失败请联络客户顾问',
  'Error Description_Failed to create-1002':
    '未有可使用之发票号码，导致开立失败',
  'Error Description_Failed to void the invoice-3002':
    '发票已过申报期间不得作废',
  'Error Description_Failed to void the invoice-3003': '该发票已经作废过',
  'Product Name': '商品名称',
  'Enter Product Name': '输入商品名称',
  'Discount Amount Min Error': '折扣金额需大于 0',
  'Discount Amount Max Error': '折扣金额不得超过商品小计',
  'Invalid invoice amount': '发票金额有误',
  'maximum date range is 1 year': '筛选区间至多一年',
  Required: '必填',
};

export default invoiceManagement;
