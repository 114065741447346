import { curry } from 'lodash-es';

const PREFIX = 'COMPANY_INFO';

export const SET_FIELD_VALUE = `${PREFIX}.SET_FIELD_VALUE`;

export const actionTypes = {
  SET_FIELD_VALUE,
};

export const setFieldValue = (field, value) => ({
  type: actionTypes.SET_FIELD_VALUE,
  payload: [field, value],
});

export const actions = {
  setFieldValue: curry(setFieldValue),
};
