import { createSelector } from 'reselect';
import { iteratee } from 'lodash-es';

export const companyInfoSelector = iteratee(
  'invoiceManagement.tradevanCreate.companyInfo',
);

export const companyAddressSelector = createSelector(
  companyInfoSelector,
  iteratee('address'),
);

export const companyTitleSelector = createSelector(
  companyInfoSelector,
  iteratee('title'),
);

export const companyUniformNumberSelector = createSelector(
  companyInfoSelector,
  iteratee('uniformNumber'),
);
