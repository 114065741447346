const common = {
  Test: '測試',
  '25 Rows': '25筆',
  '50 Rows': '50筆',
  '100 Rows': '100 筆',
  'Reason is required': '原因 是必須的',
  'Data is required': '{{column}} 是必須的',
  'Data is invalid': '{{column}} 無效',
  All: '全部',
  'Per Page Limit': '顯示筆數',
  Filter: '篩選',
  Cancel: '取消',
  Date: '訂單日期',
  Clear: '清除',
  Search: '搜尋',
  'Please Enter': '請輸入{{field}}',
  'Report Toast Message':
    '資料正在傳送至 {{email}} 。在繁忙時間，可能要稍候片刻，請耐心等候。',
  Here: '點擊此處',
};

export default common;
