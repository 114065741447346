import { useCallback, memo } from 'react';
import { Input } from '@shopline/dashboard-ui';

const renderPreText = () => <span>NT$</span>;

const PriceInput = ({ onChange, value, ...props }) => {
  const handleChange = useCallback(
    (event) => {
      const parsedValue = parseInt(event.target.value, 10);
      onChange(Number.isNaN(parsedValue) ? 0 : parsedValue);
    },
    [onChange],
  );
  return (
    <Input
      type="number"
      value={`${value}`}
      onChange={handleChange}
      renderPreText={renderPreText}
      {...props}
    />
  );
};

export default memo(PriceInput);
