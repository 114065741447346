const PREFIX = 'DONATION_CODE';

export const SET_VALUE = `${PREFIX}.SET_VALUE`;

export const actionTypes = {
  SET_VALUE,
};

export const setValue = (value) => ({
  type: actionTypes.SET_VALUE,
  payload: value,
});

export const actions = {
  setValue,
};
