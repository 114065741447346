import styled, { css } from 'styled-components';
import TextLabel from 'components/TextLabel';

export const baseCellContainerStyles = css`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const CellContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const baseCellStyled = css`
  flex-grow: 1;
  word-wrap: break-word;
  width: 50%;
  margin-top: 1rem;
  ${({ hasPaddingRight }) => hasPaddingRight && 'padding-right: 0.5rem;'}
  ${({ hasPaddingLeft }) => hasPaddingLeft && 'padding-left: 0.5rem;'}
`;

export const Cell = styled.div`
  ${baseCellStyled}
`;

export const CellTitle = ({ title }) => (
  <TextLabel fontType="Caption" color="INK_DEFAULT" weight="Medium">
    {title}
  </TextLabel>
);

export const CellContent = styled.div`
  margin-top: 0.25rem;
  font-size: 0.875rem;
  min-height: 1.25rem;
`;
