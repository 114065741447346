import { useMemo } from 'react';
import { useWindowSize } from './useWindowSize';

const useScreenType = () => {
  const windowSize = useWindowSize();

  const screenType = useMemo(() => {
    if (windowSize.width <= 767) return 'mobile';
    if (windowSize.width <= 1024) return 'tablet';
    return 'desktop';
  }, [windowSize]);

  return screenType;
};

export default useScreenType;
