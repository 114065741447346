import { memo, useState, useCallback } from 'react';
import { media, Icons, Button, DateRangePicker } from '@shopline/dashboard-ui';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';
import useScreenType from 'hooks/useScreenType';
import useTranslateOptions from 'hooks/useTranslateOptions';

import PageDropdown from 'components/PageDropdown';
import ChannelDropdown from 'components/ChannelDropdown';
import ByDayMobileFiltersPopup from './ByDayMobileFiltersPopup';

const E2E_PREFIX = 'filter-section-';

const SelectorWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  margin-bottom: 0.5rem;
  ${media.tablet`
    margin-bottom: 1rem;
  `};
  ${media.mobile`
    padding: 0 1rem;
    margin-bottom: 0.5rem;
  `};
`;
SelectorWrapper.displayName = 'SelectorWrapper';

const DateRangePickerWrapper = styled.div`
  margin-right: 0.5rem;
  flex-grow: 1;
`;
DateRangePickerWrapper.displayName = 'DateRangePickerWrapper';

const DropdownWrapper = styled.div`
  display: flex;
  > *:not(:last-child) {
    margin-right: 0.5rem;
  }
`;
DropdownWrapper.displayName = 'DropdownWrapper';

const ByDayFilterSection = ({
  e2eFeaturePrefix,
  parameters,
  updateParams,
  fixedChannelConfig,
  channels = [],
}) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const screenType = useScreenType();
  const fixedChannelOptions = useTranslateOptions(fixedChannelConfig);
  const [isMobileFilterOpen, setIsMobileFiltersOpen] = useState(false);
  const [isRangeValid, setIsRangeValid] = useState(true);

  const onRangeChange = (params) => {
    const [start, end] = params.range || [];
    const startDay = dayjs(start);
    const endDay = dayjs(end);
    if (dayjs.duration(endDay.diff(startDay)).asYears() > 1) {
      setIsRangeValid(false);
      return;
    }
    setIsRangeValid(true);
    if (!start || !end || startDay.isAfter(endDay))
      updateParams({ startDate: undefined, endDate: undefined, page: '1' });
    else {
      updateParams({
        startDate: new Date(startDay.format('YYYY-MM-DD')),
        endDate: new Date(endDay.format('YYYY-MM-DD')),
        page: '1',
      });
    }
  };

  const onChangeChannel = useCallback(
    (value) => updateParams({ channel: value[0], page: '1' }),
    [updateParams],
  );
  return (
    <>
      <SelectorWrapper>
        <DateRangePickerWrapper>
          <DateRangePicker
            status={isRangeValid ? 'valid' : 'invalid'}
            renderMessages={
              isRangeValid
                ? []
                : [() => t('invoiceManagement:maximum date range is 1 year')]
            }
            data-e2e-id={`${e2eFeaturePrefix}${E2E_PREFIX}date-picker`}
            key={
              JSON.stringify(parameters.startDate) +
              JSON.stringify(parameters.endDate)
            }
            onRangeChange={onRangeChange}
            lang={language}
            {...(parameters.startDate &&
              parameters.endDate && {
                defaultValues: [parameters.startDate, parameters.endDate],
              })}
            block
          />
        </DateRangePickerWrapper>

        {screenType !== 'mobile' ? (
          <DropdownWrapper>
            <ChannelDropdown
              e2eId={`${e2eFeaturePrefix}${E2E_PREFIX}channel-dropdown`}
              block
              width="15rem"
              fixedOptions={fixedChannelOptions}
              channels={channels}
              placeHolder={t('invoiceManagement:Channel')}
              value={parameters.channel}
              onChange={onChangeChannel}
              disabledShadow
            />
            <PageDropdown
              e2eId={`${e2eFeaturePrefix}${E2E_PREFIX}page-dropdown`}
              width="5.25rem"
              minHeight="2.25rem"
              value={[parameters.limit.toString()]}
              onChange={(value) => updateParams({ limit: value[0], page: '1' })}
            />
          </DropdownWrapper>
        ) : (
          <Button.Default
            e2eId={`${e2eFeaturePrefix}${E2E_PREFIX}mobile-filter-btn`}
            Icon={Icons.Outline.Filter}
            width="SQUARE"
            height="SQUARE"
            onClick={() => setIsMobileFiltersOpen(true)}
          />
        )}
      </SelectorWrapper>
      {isMobileFilterOpen && (
        <ByDayMobileFiltersPopup
          e2eFeaturePrefix={e2eFeaturePrefix}
          fixedChannelConfig={fixedChannelConfig}
          channels={channels}
          initParams={parameters}
          onCancel={() => setIsMobileFiltersOpen(false)}
          onConfirm={(newParameters) => {
            updateParams({ ...newParameters, page: '1' });
            setIsMobileFiltersOpen(false);
          }}
        />
      )}
    </>
  );
};

ByDayFilterSection.displayName = 'ByDayFilterSection';

export default memo(ByDayFilterSection);
