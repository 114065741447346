import { get } from 'lodash-es';

export const GET_INITIAL_STATE_REQUEST = 'GET_INITIAL_STATE_REQUEST';
export const GET_INITIAL_STATE_SUCCESS = 'GET_INITIAL_STATE_SUCCESS';
export const GET_INITIAL_STATE_FAIL = 'GET_INITIAL_STATE_FAIL';

export const getInitialStateRequest = () => ({
  type: GET_INITIAL_STATE_REQUEST,
});

export const getInitialStateSuccess = (data = {}) => ({
  type: GET_INITIAL_STATE_SUCCESS,
  payload: { data },
});
export const getInitialStateFailed = (error) => ({
  type: GET_INITIAL_STATE_FAIL,
  payload: { error },
});

export const getInitialState =
  ({ merchantId }) =>
  async (dispatch, getState, context) => {
    const { api, updateApiBaseUrl } = context;
    dispatch(getInitialStateRequest());
    try {
      const { data } = await api(`initial-state?merchant_id=${merchantId}`);
      updateApiBaseUrl(`/api/merchants/${merchantId}`);
      dispatch(getInitialStateSuccess(data));
    } catch (e) {
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.error(e);
      }
      const errorData = get(e, 'response.data', {});
      dispatch(getInitialStateFailed(errorData));
    }
  };
