import React from 'react';
import styled from 'styled-components';
import TextLabel from 'components/TextLabel';

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  align-items: center;
  > :first-child {
    padding-right: 1rem;
    min-width: 7.5rem;
  }
`;

const Row = ({ title, content, titleWeight }) => (
  <RowContainer>
    <TextLabel
      fontType="Body"
      color="INK_800"
      weight={titleWeight || 'Default'}
    >
      {title}
    </TextLabel>
    <TextLabel fontType="Body" color="INK_800" weight="Default">
      {content || '-'}
    </TextLabel>
  </RowContainer>
);

export default Row;
