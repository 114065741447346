const PREFIX = 'DUPLICCATE_INVOICE';

export const SET_ADDRESS = `${PREFIX}.SET_ADDRESS`;

export const actionTypes = {
  SET_ADDRESS,
};

export const setAddress = (address) => ({
  type: actionTypes.SET_ADDRESS,
  payload: address,
});

export const actions = {
  setAddress,
};
