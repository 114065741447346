import styled from 'styled-components';
import { media, DynamicEditor } from '@shopline/dashboard-ui';

export default styled(DynamicEditor.Primary).attrs(
  ({ children, ...props }) => ({
    ...props,
    height: '2.25rem',
    hPadding: 'SMALL',
    direction: 'vertical',
    borderRadius: '4px',
    content: children,
  }),
)`
  flex: 1 1 0;
  align-items: center;
  margin-top: 1rem;
  & + & {
    margin-left: 0.625rem;
  }
  & > div:first-child {
    height: 1rem;
  }
  ${media.mobile`
    flex: 1 0 100%;
    margin-top: 0.625rem;
    & + & {
      margin-left: 0;
    }
  `}
`;
