import Dropdown from 'components/Dropdown';
import { withProps } from 'utils/recompose';

export default withProps({
  width: '100%',
  block: true,
  minHeight: '2.25rem',
  useDefaultBorder: true,
  disabledShadow: true,
})(Dropdown);
