import { createSelector } from 'reselect';
import { iteratee, nthArg, get, toPairs } from 'lodash-es';

const invoiceItemsSelector = iteratee(
  'invoiceManagement.tradevanCreate.invoiceItems',
);

export const itemMapSelector = createSelector(
  invoiceItemsSelector,
  iteratee('itemMap'),
);

export const itemSelector = createSelector(itemMapSelector, nthArg(1), get);

export const itemIdListSelector = createSelector(
  invoiceItemsSelector,
  iteratee('itemIdList'),
);

const itemPairsSelector = createSelector(itemMapSelector, toPairs);

export const subtotalSelector = createSelector(itemPairsSelector, (itemPairs) =>
  itemPairs.reduce(
    (accumulator, [, { price, qty }]) => accumulator + price * qty,
    0,
  ),
);
