import { useReducer, useCallback, useEffect } from 'react';
import { camelCase, cond, constant, flow, stubTrue } from 'lodash-es';
import pick from 'lodash/fp/pick';
import eq from 'lodash/fp/eq';
import mapKeys from 'lodash/fp/mapKeys';
import createApi from 'utils/api';
import { actionTypes, initialState, reducer } from './reducer';

const PER_PAGE = 10;

const pickCustomer = pick(['id', 'name', 'email', 'mobile_phone', 'is_member']);

const getCustomers = ({ page, perPage, merchantId, keyword }) =>
  createApi()
    .get(`/merchants/${merchantId}/customers`, {
      params: { page, per_page: perPage, query: keyword },
    })
    .then((res) => ({
      pagination: mapKeys(camelCase, res.data.pagination),
      items: res.data.items.map(
        flow(
          pickCustomer,
          mapKeys(
            cond([
              [eq('id'), constant('uniqueId')],
              [stubTrue, camelCase],
            ]),
          ),
        ),
      ),
    }));

const useCustomers = ({ merchantId }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const loadMore = useCallback(async () => {
    try {
      if (state.currentPage >= state.totalPages) {
        return;
      }
      dispatch({ type: actionTypes.LOAD_MORE });
      const { pagination, items } = await getCustomers({
        merchantId,
        page: state.currentPage + 1,
        perPage: PER_PAGE,
        keyword: state.keyword,
      });
      dispatch({
        type: actionTypes.REQUEST_SUCCESS,
        payload: {
          ...pagination,
          data: items,
        },
      });
    } catch (error) {
      dispatch({ type: actionTypes.REQUEST_FAIL, payload: error });
    }
  }, [merchantId, state.keyword, state.currentPage, state.totalPages]);
  const setKeyword = useCallback(
    (keyword) =>
      dispatch({
        type: actionTypes.SET_KEYWORD,
        payload: keyword,
      }),
    [],
  );
  useEffect(() => {
    (async () => {
      try {
        dispatch({ type: actionTypes.REQUEST });
        const { pagination, items } = await getCustomers({
          merchantId,
          page: 1,
          perPage: PER_PAGE,
          keyword: state.keyword,
        });
        dispatch({
          type: actionTypes.REQUEST_SUCCESS,
          payload: {
            ...pagination,
            data: items,
          },
        });
      } catch (error) {
        dispatch({ type: actionTypes.REQUEST_FAIL, payload: error });
      }
    })();
  }, [merchantId, state.keyword]);
  return [state, { loadMore, setKeyword }];
};

export default useCustomers;
