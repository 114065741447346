import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { flow, iteratee, identity, trimStart, trimEnd } from 'lodash-es';
import { Input, TextArea } from '@shopline/dashboard-ui';
import { actions } from 'stores/invoiceManagement/actions/tradevanCreate/companyInfo';
import { companyUniformNumberRule, companyAddressRule } from '../validateRules';
import { submitKeySelector } from '../selectors/main';
import { companyInfoSelector } from '../selectors/companyInfo';
import { Field, FieldRow, FormInput } from '../components';
import PARENT_E2E_PREFIX from './e2ePrefix';

const E2E_PREFIX = `${PARENT_E2E_PREFIX}-company`;

const selectedFields = ['title', 'uniformNumber', 'address'];

const Company = () => {
  const { t } = useTranslation();
  const submitKey = useSelector(submitKeySelector);
  const companyInfo = useSelector(companyInfoSelector);
  const dispatch = useDispatch();
  const [handleTitleChange, handleUniformNumberChange, handleAddressChange] =
    useMemo(
      () =>
        selectedFields.map((field) =>
          flow(
            iteratee('target.value'),
            trimStart,
            field === 'uniformNumber' ? trimEnd : identity,
            actions.setFieldValue(field),
            dispatch,
          ),
        ),
      [dispatch],
    );
  return (
    <>
      <FieldRow>
        <Field title={t('invoiceManagement:Company Name')}>
          <Input
            block
            value={companyInfo.title}
            onChange={handleTitleChange}
            e2eInputId={`${E2E_PREFIX}-title-input`}
            status="valid"
            renderMessages={[]}
          />
        </Field>
        <Field title={`${t('invoiceManagement:Tax ID')}*`}>
          <FormInput
            block
            value={companyInfo.uniformNumber}
            onChange={handleUniformNumberChange}
            e2eInputId={`${E2E_PREFIX}-uniform-number-input`}
            e2eMessagesId={`${E2E_PREFIX}-uniform-number-errors`}
            trimStart
            timeEnd
            rule={companyUniformNumberRule}
            fieldName={t('invoiceManagement:Tax ID')}
            validateKey={submitKey}
          />
        </Field>
      </FieldRow>
      <FieldRow>
        <Field title={`${t('invoiceManagement:Send to optional')}`}>
          <FormInput
            as={TextArea}
            rows={2}
            maxCount={100}
            block
            value={companyInfo.address}
            onChange={handleAddressChange}
            e2eInputId={`${E2E_PREFIX}-address-input`}
            e2eMessagesId={`${E2E_PREFIX}-address-errors`}
            trimStart
            rule={companyAddressRule}
            fieldName={t('invoiceManagement:Send to optional')}
            validateKey={submitKey}
          />
        </Field>
      </FieldRow>
    </>
  );
};

export default Company;
