import { useState, useCallback, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { iteratee } from 'lodash-es';
import memoizee from 'memoizee';
import map from 'lodash/fp/map';
import DropdownOptionWrapper from '@shopline/dashboard-ui/dist/esm/components/dropdown/DropdownOptionWrapper';
import { actions } from 'stores/invoiceManagement/actions/tradevanCreate/donationCode';
import { createDropdownOptionsMapper } from '../utils';
import {
  defaultDonationCodeSelector,
  defaultDonationDisplaySelector,
} from '../selectors/main';
import { donationCodeValueValueSelector } from '../selectors/donationCode';
import {
  Dropdown,
  Field,
  FieldRow,
  LoadingDropdownSelector,
} from '../components';
import PARENT_E2E_PREFIX from './e2ePrefix';

const E2E_PREFIX = `${PARENT_E2E_PREFIX}-donation`;

const E2E_ID_DONATION_CODE_DROPDOWN = `${E2E_PREFIX}-donation-code`;

const mapDonationCodeValueOptions = map(
  createDropdownOptionsMapper(E2E_ID_DONATION_CODE_DROPDOWN),
);

const fetchDonationCodes = () =>
  axios({
    method: 'get',
    url: 'https://static.shoplineapp.com/e-invoice/tw/donation_targets.json',
  })
    .then(iteratee('data'))
    .then(
      map(({ name, code }) => ({
        value: code,
        label: `${code} ${name}`,
      })),
    )
    .then(mapDonationCodeValueOptions);

const memoizedFetchDonationCodes = memoizee(fetchDonationCodes, {
  promise: true,
});

const useDonationList = () => {
  const dataRef = useRef([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        dataRef.current = await memoizedFetchDonationCodes();
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    })();
  }, []);
  return {
    loading,
    data: dataRef.current,
  };
};

const StyledDropdown = styled(Dropdown)`
  & ${DropdownOptionWrapper} {
    right: 0;
  }
`;

const Donation = () => {
  const { t } = useTranslation();
  const defaultDonationCode = useSelector(defaultDonationCodeSelector);
  const defaultDonationDisplay = useSelector(defaultDonationDisplaySelector);
  const donationCodeValue = useSelector(donationCodeValueValueSelector);
  const { loading, data } = useDonationList();
  const dispatch = useDispatch();
  const handleDonationCodeValueChange = useCallback(
    ([value]) => {
      dispatch(actions.setValue(value));
    },
    [dispatch],
  );
  useEffect(() => {
    if (defaultDonationCode) {
      dispatch(actions.setValue(defaultDonationCode));
    }
  }, [dispatch, defaultDonationCode]);
  return (
    <FieldRow>
      <Field title={`${t('invoiceManagement:Donation Units')}*`}>
        <StyledDropdown
          onChange={handleDonationCodeValueChange}
          value={donationCodeValue}
          options={data}
          renderSelector={
            loading
              ? () => (
                  <LoadingDropdownSelector>
                    {defaultDonationDisplay}
                  </LoadingDropdownSelector>
                )
              : undefined
          }
          placeHolder=""
          optionMaxHight="15.75rem;"
          e2eId={E2E_ID_DONATION_CODE_DROPDOWN}
        />
      </Field>
    </FieldRow>
  );
};

export default Donation;
