const common = {
  Test: 'Test',
  '25 Rows': '25 Rows',
  '50 Rows': '50 Rows',
  '100 Rows': '100 Rows',
  'Reason is required': 'Reason is required',
  'Data is required': '{{column}} is required',
  'Data is invalid': '{{column}} is invalid',
  All: 'All',
  'Per Page Limit': 'Per Page Limit',
  Filter: 'Filter',
  Cancel: 'Cancel',
  Date: 'Date',
  Clear: 'Clear',
  Search: 'Search',
  'Please Enter': 'please enter {{field}}',
  'Report Toast Message':
    'Sending data to {{email}}. At peak times this may take a while. Please be patient while waiting.',
  Here: 'here',
};

export default common;
