import dayjs from 'dayjs';

export const E2E_FEATURE_PREFIX = 'im-tradevan-list-';
export const E2E_BY_ORDER_PREFIX = `${E2E_FEATURE_PREFIX}by-order-`;
export const E2E_BY_DAY_PREFIX = `${E2E_FEATURE_PREFIX}by-day-`;

export const E2E_FEATURE_CETUSTEK_PREFIX = 'im-cetustek-list-';
export const E2E_CETUSTEK_BY_ORDER_PREFIX = `${E2E_FEATURE_CETUSTEK_PREFIX}by-order-`;
export const E2E_CETUSTEK_BY_DAY_PREFIX = `${E2E_FEATURE_CETUSTEK_PREFIX}by-day-`;

export const byDayDefaultStart = dayjs().date(1).toDate();
export const byDayDefaultEnd = new Date();
