import { useLayoutEffect, useCallback, useMemo, memo } from 'react';
import styled from 'styled-components';

import { CETUSTEK } from 'constants/vendor';
import Paginator from 'components/Paginator';

import ByOrderFilterSection from '../../components/ByOrderFilterSection';
import ByOrderTagPanelSection from '../../components/ByOrderTagPanelSection';
import ByOrderInvoiceTable from '../../components/ByOrderInvoiceTable';

import { COLUMN_KEYS } from '../../hooks/useInvoiceTableConfig';
import { E2E_CETUSTEK_BY_ORDER_PREFIX } from '../../constants';
import { getDollarFromMoneyObj } from '../../utils';

const TableContentWrapper = styled.div`
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.SNOW_200};
`;

const PaginatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
`;

const EXCLUDE_COLUMNS_KEYS = [
  COLUMN_KEYS.ORDER_ID,
  COLUMN_KEYS.CARRIER_TYPE,
  COLUMN_KEYS.VOID_BUTTON,
];

const OrderList = ({
  parameters,
  updateParams,
  list,
  isLoadingList,
  getList,
  pagination,
  channels,
}) => {
  const onPageChange = useCallback(
    (page) => {
      updateParams({ page: page.toString() });
    },
    [updateParams],
  );

  const tableData = useMemo(
    () =>
      list.map((item) => ({
        ...item,
        invoice_at: item.created_at,
        invalid_at: item.cancelled_at,
        sales_amount: getDollarFromMoneyObj(item.sales_amount),
        tax_amount: getDollarFromMoneyObj(item.tax),
        total_amount: getDollarFromMoneyObj(item.total),
        companyun: item.tax_id,
      })),
    [list],
  );

  useLayoutEffect(() => {
    getList(parameters);
  }, [parameters, getList]);

  return (
    <>
      <ByOrderFilterSection
        e2eFeaturePrefix={E2E_CETUSTEK_BY_ORDER_PREFIX}
        vendorType={CETUSTEK}
        channels={channels}
        parameters={parameters}
        updateParams={updateParams}
      />
      <ByOrderTagPanelSection
        e2eFeaturePrefix={E2E_CETUSTEK_BY_ORDER_PREFIX}
        vendorType={CETUSTEK}
        channels={channels}
        parameters={parameters}
        updateParams={updateParams}
      />
      <TableContentWrapper>
        <ByOrderInvoiceTable
          tableData={tableData}
          isEmpty={!isLoadingList && pagination && pagination.total_count === 0}
          isLoading={isLoadingList}
          excludeColumnKeys={EXCLUDE_COLUMNS_KEYS}
        />
      </TableContentWrapper>
      {!isLoadingList && pagination && pagination.total_pages > 1 && (
        <PaginatorWrapper>
          <Paginator
            totalCount={pagination.total_count}
            onPageChange={onPageChange}
            page={pagination.current_page}
            limit={pagination.per_page}
          />
        </PaginatorWrapper>
      )}
    </>
  );
};

export default memo(OrderList);
