import {
  GET_INVOICE_DETAIL_REQUEST,
  GET_INVOICE_DETAIL_SUCCESS,
  GET_INVOICE_DETAIL_FAIL,
  RESET_INVOICE_DETAIL,
} from '../actions/tradevanList/getInvoiceDetail';

const initState = {
  isLoadingDetail: false,
  detail: null,
};

const tradevanDetail = (state = initState, action) => {
  switch (action.type) {
    case GET_INVOICE_DETAIL_REQUEST: {
      return { ...state, isLoadingDetail: true };
    }
    case GET_INVOICE_DETAIL_SUCCESS: {
      const { data } = action.payload;
      return { ...state, detail: data, isLoadingDetail: false };
    }
    case GET_INVOICE_DETAIL_FAIL: {
      return { ...state, isLoadingDetail: false };
    }
    case RESET_INVOICE_DETAIL: {
      return { ...initState };
    }
    default:
      return state;
  }
};

export default tradevanDetail;
