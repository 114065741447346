import { memo, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Drawer, Icons, IconButton } from '@shopline/dashboard-ui';
import TextLabel from 'components/TextLabel';
import { INVOICE_ISSUE_TYPE } from 'constants/invoice';
import * as apiCarrierTypes from 'constants/apiCarrierTypes';
import useScreenType from 'hooks/useScreenType';
import useClickOutside from 'hooks/useClickOutside';
import InfoSection from './detail/InfoSection';
import OrderSection from './detail/OrderSection';
import { issueTypeSelector } from '../../selectors';
import { E2E_BY_ORDER_PREFIX } from '../../constants';

const E2E_PREFIX = `${E2E_BY_ORDER_PREFIX}detail-`;

const DrawerWrapper = styled.div`
  h2 {
    margin: 0;
  }
  a {
    text-decoration: none;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5rem;
  height: 4.5rem;
  background-color: ${({ theme }) => theme.colors.SNOW_200};
`;

const ContentContainer = styled.div`
  padding-bottom: 3rem;
`;

const infoHeaderCol = {
  fixed: ['order_id', 'invoice_at', 'status', 'invalid_at', 'issueType'],
  [INVOICE_ISSUE_TYPE.CLOUD]: ['carrier_type'],
  [INVOICE_ISSUE_TYPE.DONATE]: ['donee'],
  [INVOICE_ISSUE_TYPE.UNIFORM]: [
    'buyer_title',
    'buyer_companyun',
    'buyer_address',
  ],
  [INVOICE_ISSUE_TYPE.DUPLICATE_UNIFORM]: ['buyer_address'],
  [apiCarrierTypes.MEMBER]: ['buyer_email'],
  [apiCarrierTypes.MOBILE_BARCODE]: ['mobile_barcode'],
  [apiCarrierTypes.DIGITAL_CERTIFICATE]: ['digital_certificate'],
};

const infoColumnTranslateKey = {
  order_id: 'invoiceManagement:Order Number',
  invoice_at: 'invoiceManagement:Invoice Date',
  status: 'invoiceManagement:Invoice Status',
  invalid_at: 'invoiceManagement:Voided Date',
  issueType: 'invoiceManagement:Type of Invoice Request',
  carrier_type: 'invoiceManagement:Carrier',
  carrier_id1: 'invoiceManagement:Mobile Barcode',
  donee: 'invoiceManagement:Donation Units',
  buyer_title: 'invoiceManagement:Company Name',
  buyer_companyun: 'invoiceManagement:Tax ID',
  buyer_address: 'invoiceManagement:Send to',
  buyer_email: 'Email',
  mobile_barcode: 'invoiceManagement:Mobile Barcode',
  digital_certificate: 'invoiceManagement:NPC Barcode',
};

const buyerHeaderColumnsKey = ['buyer_name', 'buyer_telephone', 'buyer_email'];
const buyerColumnTranslateKey = {
  buyer_name: "invoiceManagement:Buyer's Name",
  buyer_telephone: 'invoiceManagement:Mobile Phone',
  buyer_email: 'Email',
};

const DetailDrawer = ({ detail, isLoading, onClickClose }) => {
  const { t } = useTranslation();
  const screenType = useScreenType();
  const drawerRef = useRef(null);

  useClickOutside(drawerRef, onClickClose);

  const renderHeader = useCallback(
    () => (
      <HeaderWrapper>
        <TextLabel
          e2eId={`${E2E_PREFIX}invoice-drawer-title`}
          fontType="Heading"
        >
          {`${t('invoiceManagement:Invoice')} ${detail?.invoice_number || ''}`}
        </TextLabel>
        <IconButton
          Icon={Icons.Outline.Cross}
          colorType="basic"
          iconProps={{ size: 'SMALL' }}
          tooltipProps={{ text: '', direction: 'TOP_CENTER' }}
          handleClick={onClickClose}
        />
      </HeaderWrapper>
    ),
    [t, onClickClose, detail],
  );

  const renderContent = useCallback(() => {
    const issueType = issueTypeSelector(detail);
    const renderHeadColumns = infoHeaderCol.fixed.concat(
      infoHeaderCol[issueType] || [],
      issueType === INVOICE_ISSUE_TYPE.CLOUD
        ? infoHeaderCol[detail.carrier_type]
        : [],
    );
    return (
      <ContentContainer>
        <InfoSection
          titleKey="invoiceManagement:Invoice Information"
          headColumns={renderHeadColumns}
          columnTranslateKey={infoColumnTranslateKey}
          isLoading={isLoading}
          detail={detail}
        />
        <InfoSection
          titleKey="invoiceManagement:Buyer's Information"
          headColumns={buyerHeaderColumnsKey}
          columnTranslateKey={buyerColumnTranslateKey}
          isLoading={isLoading}
          detail={detail}
        />
        <OrderSection
          titleLangKey={'invoiceManagement:Order Details'}
          details={detail?.details || []}
          orderAmount={detail?.total_amount || 0}
          isLoading={isLoading}
        />
      </ContentContainer>
    );
  }, [detail, isLoading]);

  return (
    <DrawerWrapper ref={drawerRef}>
      <Drawer
        lang="en"
        width={screenType === 'mobile' ? '100vw' : '30rem'}
        isShow
        isShowOverlay={false}
        renderHeader={renderHeader}
        renderContent={renderContent}
      />
    </DrawerWrapper>
  );
};
export default memo(DetailDrawer);
