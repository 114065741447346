import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash-es';

const fallbackRolloutKeys = [];

const useHasRolloutKey = () => {
  const rolloutKeys =
    useSelector((state) => get(state, 'merchant.merchantData.rollout_keys')) ||
    fallbackRolloutKeys;

  const hasRolloutKey = useCallback(
    (requireKey) => rolloutKeys.includes(requireKey),
    [rolloutKeys],
  );

  const hasRolloutKeys = useCallback(
    (requireKeys) => {
      const keys = Array.isArray(requireKeys) ? requireKeys : [requireKeys];
      return keys.every((k) => hasRolloutKey(k));
    },
    [hasRolloutKey],
  );

  return { hasRolloutKeys };
};

export default useHasRolloutKey;
