import { mapValues } from 'lodash-es';
import {
  GET_INVOICE_LIST_REQUEST,
  GET_INVOICE_LIST_SUCCESS,
  GET_INVOICE_LIST_FAIL,
} from '../actions/tradevanList/getInvoiceList';

const initState = {
  isLoadingList: false,
  list: [],
  pagination: null,
};

const tradevanList = (state = initState, action) => {
  switch (action.type) {
    case GET_INVOICE_LIST_REQUEST: {
      return { ...state, isLoadingList: true };
    }
    case GET_INVOICE_LIST_SUCCESS: {
      const { payload } = action;
      const { data } = payload;
      const { items, pagination } = data;
      const numberPagination = mapValues(
        pagination,
        (value) => parseInt(value, 10) || 0,
      );
      return {
        ...state,
        isLoadingList: false,
        list: items,
        pagination: numberPagination,
      };
    }
    case GET_INVOICE_LIST_FAIL: {
      return { ...initState };
    }
    default:
      return state;
  }
};

export default tradevanList;
