import styled from 'styled-components';
import { media } from '@shopline/dashboard-ui';
import {
  MessageWrapper as InputMessageWrapper,
  InputWrapper as InputInputWrapper,
  StyledInput as InputStyledInput,
  Wrapper as InputWrapper,
} from '@shopline/dashboard-ui/dist/esm/components/input';
import { Container as QtyInputContainer } from './QtyInput';

const ItemContainer = styled.li`
  flex: 0 0 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:not(:first-child) {
    margin-top: 1.75rem;
  }
  & > div:first-child {
    position: relative;
    & > ${InputMessageWrapper} {
      position: absolute;
    }
  }

  & > ${InputMessageWrapper} {
    display: none;
  }

  ${media.mobile`
    flex-wrap: wrap;
    & > div:first-child {
      flex: 0 0 calc(100% - 0.5rem - 1.25rem);
      margin-right: 0.5rem;
      & > ${InputInputWrapper} {
        display: flex;
        width: 100%;
        & > ${InputStyledInput} {
          width: auto;
          flex: 1 0 0;
        }
      }
      & > ${InputMessageWrapper} {
        position: static;
      }
    }
    & > ${InputWrapper} {
      margin-top: 0.5rem;
      order: 1;
    }
    & > ${QtyInputContainer} {
      Order: 2;
    }
    & > div:last-child {
      margin-top: 0.3125rem;
      align-self: flex-start;
    }
  `}
`;

export default ItemContainer;
