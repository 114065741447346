import { useCallback, memo } from 'react';
import { Link } from 'react-router-dom';
import { Button, Icons } from '@shopline/dashboard-ui';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { withProps } from 'utils/recompose';

import { E2E_BY_ORDER_PREFIX } from '../../../constants';

const E2E_PREFIX = `${E2E_BY_ORDER_PREFIX}title-button-section-`;

const TitleButtonWrapper = styled.div`
  & > :first-child {
    margin-right: 0.5rem;
  }
`;

const CreateButton = withProps({
  e2eId: `${E2E_PREFIX}create-btn`,
  Icon: Icons.Outline.CirclePlus,
})(Button.Primary);

const CreateLinkButton = ({ navigate, ...props }) => {
  const handleClick = useCallback(
    (event) => {
      event.preventDefault();
      navigate();
    },
    [navigate],
  );
  return <CreateButton as="a" onClick={handleClick} {...props} />;
};

const TitleButtonSection = ({
  onClickExport,
  createDisabled,
  exportDisabled,
}) => {
  const { t } = useTranslation();
  return (
    <TitleButtonWrapper>
      <Button.Default
        e2eId={`${E2E_PREFIX}cancel-btn`}
        onClick={onClickExport}
        disabled={exportDisabled}
      >
        {t('invoiceManagement:Export')}
      </Button.Default>
      {createDisabled ? (
        <CreateButton disabled>{t('invoiceManagement:Create')}</CreateButton>
      ) : (
        <Link
          to="/invoice-management/tradevan/create"
          component={CreateLinkButton}
        >
          {t('invoiceManagement:Create')}
        </Link>
      )}
    </TitleButtonWrapper>
  );
};

export default memo(TitleButtonSection);
