import { useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Popup, TextArea } from '@shopline/dashboard-ui';

import { E2E_BY_ORDER_PREFIX } from '../../../constants';

const E2E_PREFIX = `${E2E_BY_ORDER_PREFIX}void-popup-`;

const VoidPopup = ({ data, onClose, onSubmit }) => {
  const { t } = useTranslation();
  const [reason, setReason] = useState('');
  const [count, setCount] = useState(0);

  const handleOnChange = useCallback((e) => {
    setReason(e.target.value);
    setCount(e.target.value.length);
  }, []);

  const isReasonInvalid = useMemo(() => count === 0 || count > 20, [count]);

  const handleSubmit = () => onSubmit(data, reason);

  const renderFooter = () => (
    <>
      <Button.Default height="LARGE" onClick={onClose}>
        {t('common:Cancel')}
      </Button.Default>
      <Button.Primary
        height="LARGE"
        onClick={handleSubmit}
        disabled={isReasonInvalid}
      >
        {t('invoiceManagement:Void')}
      </Button.Primary>
    </>
  );

  return (
    <Popup
      e2eId={`${E2E_PREFIX}popup`}
      onClose={onClose}
      title={`${t('invoiceManagement:Void Invoice')} ${data.invoice_number}`}
      renderFooter={renderFooter}
      width="30rem"
    >
      <TextArea
        e2eId={`${E2E_PREFIX}textarea`}
        block
        title={`${t('invoiceManagement:Reason for voiding invoice')}*`}
        placeholder={t(
          'invoiceManagement:Please enter reason for voiding invoice',
        )}
        value={reason}
        onChange={handleOnChange}
        maxCount={20}
        count={count}
        status={count > 20 ? 'invalid' : 'valid'}
      />
    </Popup>
  );
};

export default VoidPopup;
