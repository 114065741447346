import { createSelector } from 'reselect';
import { iteratee } from 'lodash-es';

const duplicateInvoiceSelector = iteratee(
  'invoiceManagement.tradevanCreate.duplicateInvoice',
);

export const duplicateAddressSelector = createSelector(
  duplicateInvoiceSelector,
  iteratee('address'),
);
