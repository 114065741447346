import { curry } from 'lodash-es';
import pick from 'lodash/fp/pick';

const PREFIX = 'INVOICE_ITEMS';

export const INSERT_EMPTY = `${PREFIX}.INSERT_EMPTY`;
export const SET_ITEM_FIELD_VALUE = `${PREFIX}.SET_ITEM_FIELD_VALUE`;
export const INCREMENT_ITEM_QTY = `${PREFIX}.INCREMENT_ITEM_QTY`;
export const PULL_ITEM = `${PREFIX}.PULL_ITEM`;
export const IMPORT_PRODUCTS = `${PREFIX}.IMPORT_PRODUCTS`;

export const actionTypes = {
  INSERT_EMPTY,
  SET_ITEM_FIELD_VALUE,
  INCREMENT_ITEM_QTY,
  PULL_ITEM,
  IMPORT_PRODUCTS,
};

export const insertEmpty = () => ({
  type: actionTypes.INSERT_EMPTY,
});

export const setItemFieldValue = (id, field, value) => ({
  type: actionTypes.SET_ITEM_FIELD_VALUE,
  payload: { id, field, value },
});

export const incrementItemQty = (id, qty) => ({
  type: actionTypes.INCREMENT_ITEM_QTY,
  payload: { id, qty },
});

export const pullItem = (id) => ({
  type: actionTypes.PULL_ITEM,
  payload: id,
});

const pickItem = pick(['name', 'price']);

export const importProducts = (products) => ({
  type: actionTypes.IMPORT_PRODUCTS,
  payload: products.map(pickItem),
});

export const actions = {
  insertEmpty,
  setItemFieldValue: curry(setItemFieldValue),
  incrementItemQty: curry(incrementItemQty),
  pullItem,
  importProducts,
};
