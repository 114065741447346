import { useState, useCallback } from 'react';
import { useSelector, useDispatch, useStore } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { noop } from 'lodash-es';
import getOr from 'lodash/fp/getOr';
import { actions } from 'stores/invoiceManagement/actions/tradevanCreate/main';
import { showUIToast } from 'stores/toasts/actions';
import { createInvoice } from './utils';
import {
  merchantIdSelector,
  submitDisabledSelector,
  isValidInvoiceAmountSelector,
  isValidFormSelector,
  createInvoicePayloadSelector,
} from './selectors/main';

const getErrorCode = getOr('', 'response.data.errorDetail.extras.code');

const useCreateInvoice = () => {
  const merchantId = useSelector(merchantIdSelector);
  const submitDisabled = useSelector(submitDisabledSelector);
  const store = useStore();
  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();

  const create = useCallback(async () => {
    try {
      if (submitting) {
        return;
      }
      setSubmitting(true);
      dispatch(actions.submit());
      const state = store.getState();
      const isValidInvoiceAmount = isValidInvoiceAmountSelector(state);
      if (!isValidInvoiceAmount) {
        dispatch(
          showUIToast({
            type: 'alert',
            title: 'invoiceManagement:Invalid invoice amount',
          }),
        );
        return;
      }
      const isValid = isValidFormSelector(state);
      if (!isValid) {
        dispatch(
          showUIToast({
            type: 'alert',
            title: 'invoiceManagement:Please fill out all the required field',
          }),
        );
        return;
      }
      const data = await createInvoice({
        merchantId,
        payload: createInvoicePayloadSelector(state),
      });
      dispatch(
        showUIToast({
          type: 'success',
          titleWithParams: {
            key: 'invoiceManagement:Successfully created an invoice',
            params: {
              invoiceNo: data.invoice_number,
            },
          },
        }),
      );
      history.push('/invoice-management');
    } catch (error) {
      dispatch(
        showUIToast({
          type: 'alert',
          title: 'invoiceManagement:Failed to create',
          descriptionWithParams: {
            key: 'invoiceManagement:Error Description',
            params: {
              context: `Failed to create-${getErrorCode(error)}`,
            },
          },
        }),
      );
    } finally {
      setSubmitting(false);
    }
  }, [submitting, dispatch, merchantId, store, history]);
  return [submitting, submitDisabled ? noop : create];
};

export default useCreateInvoice;
