import { useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import {
  getInvoiceList,
  voidInvoice,
  getInvoiceDetail,
  resetInvoiceDetail,
  exportInvoice,
} from 'stores/invoiceManagement/actions/tradevanList';
import { getChannels } from 'stores/channels/actions/getChannels';
import useSearch from '../../hooks/useSearch';

export const baseParameters = {
  page: '1',
  limit: '25',
  invoiceType: 'all',
};

const useProps = () => {
  const { tradevanList, tradevanDetail } = useSelector(
    (state) => state.invoiceManagement,
  );
  const channels = useSelector((state) => state.channels);
  const dispatch = useDispatch();
  const { parameters, updateParams } = useSearch(baseParameters);
  const computedState = useMemo(
    () => ({
      parameters,
      ...channels,
      ...tradevanList,
      ...tradevanDetail,
    }),
    [parameters, channels, tradevanList, tradevanDetail],
  );

  const handlers = useMemo(
    () => ({
      updateParams,
      getInvoiceList: bindActionCreators(getInvoiceList, dispatch),
      voidInvoice: bindActionCreators(voidInvoice, dispatch),
      getChannels: bindActionCreators(getChannels, dispatch),
      getInvoiceDetail: bindActionCreators(getInvoiceDetail, dispatch),
      resetInvoiceDetail: bindActionCreators(resetInvoiceDetail, dispatch),
      exportInvoice: bindActionCreators(exportInvoice, dispatch),
    }),
    [dispatch, updateParams],
  );

  return useMemo(
    () => ({ ...computedState, ...handlers }),
    [computedState, handlers],
  );
};

export default useProps;
