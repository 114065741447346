import { pick } from 'lodash-es';
import getOr from 'lodash/fp/getOr';
import { showUIToast } from '../../../toasts/actions';

export const VOID_INVOICE_REQUEST = 'VOID_INVOICE_REQUEST';
export const VOID_INVOICE_SUCCESS = 'VOID_INVOICE_SUCCESS';
export const VOID_INVOICE_FAIL = 'VOID_INVOICE_FAIL';

export const voidInvoiceRequest = () => ({
  type: VOID_INVOICE_REQUEST,
});

export const voidInvoiceSuccess = (data = {}) => ({
  type: VOID_INVOICE_SUCCESS,
  payload: { data },
});

const getErrorCode = getOr('', 'response.data.errorDetail.extras.code');

const invoiceDataApiKey = [
  'create_by',
  'channel_id',
  'device_id',
  'user_id',
  'invoice_at',
];

const cookApiData = ({ invoiceData, reason }) => {
  const apiInvoiceData = pick(invoiceData, invoiceDataApiKey);
  return {
    ...apiInvoiceData,
    invalid_remark: reason,
  };
};

export const voidInvoice =
  ({ invoiceData, reason }) =>
  async (dispatch, getState, context) => {
    const { api } = context;
    const { period, invoice_number: invoiceNumber } = invoiceData;
    dispatch(voidInvoiceRequest());
    try {
      await api.put(
        `/invoices/${period}/${invoiceNumber}/void`,
        cookApiData({ invoiceData, reason }),
      );
      dispatch(
        showUIToast({
          type: 'success',
          titleWithParams: {
            key: 'invoiceManagement:Successfully voided an invoice',
            params: {
              invoiceNo: invoiceNumber,
            },
          },
        }),
      );
    } catch (error) {
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.error(error);
      }
      dispatch(
        showUIToast({
          type: 'alert',
          title: 'invoiceManagement:Failed to void the invoice',
          descriptionWithParams: {
            key: 'invoiceManagement:Error Description',
            params: {
              context: `Failed to void the invoice-${getErrorCode(error)}`,
            },
          },
        }),
      );
    }
  };
