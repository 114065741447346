import { useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import TextLabel from '../TextLabel';
import { APP_CONFIG_STATIC_RESOURCE_HOST_DEFAULT } from '../../constants/resource';

const Welcome = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 1rem 2rem;
`;

const StyledImage = styled.img`
  width: 100%;
  max-width: ${({ isWelcome, imgMaxWidth }) => imgMaxWidth || (isWelcome ? '36rem' : '11rem')};
  margin-bottom: 1.5rem;
`;

const WelcomeText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  > *:nth-child(1) {
    margin-bottom: 0.25rem;
  }
`;

const WelcomeBox = ({
  isWelcome = true,
  welcomeTitle = '',
  welcomeSubtitle = '',
  welcomeImg = '',
  imgMaxWidth = '',
}) => {
  const { t } = useTranslation('common');

  const imageSrc = useMemo(
    () => (isWelcome ? welcomeImg : 'order/pos_order_search_no_result.png'),
    [isWelcome, welcomeImg],
  );

  return (
    <Welcome>
      <StyledImage
        isWelcome={isWelcome}
        src={`${APP_CONFIG_STATIC_RESOURCE_HOST_DEFAULT}admin/v2/img/${imageSrc}`}
        imgMaxWidth={imgMaxWidth}
      />
      <WelcomeText hasButton={isWelcome}>
        <TextLabel fontType="Heading" color="INK_600">
          {isWelcome ? welcomeTitle : t('No Results')}
        </TextLabel>
        <TextLabel fontType="Body" color="INK_500">
          {isWelcome ? welcomeSubtitle : t('Please try other keywords or search terms')}
        </TextLabel>
      </WelcomeText>
    </Welcome>
  );
};

WelcomeBox.propTypes = {
  isWelcome: PropTypes.bool,
  welcomeTitle: PropTypes.string,
  welcomeSubtitle: PropTypes.string,
  welcomeImg: PropTypes.string,
};

export default WelcomeBox;
