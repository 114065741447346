import { nanoid } from 'nanoid/non-secure';
import update from 'immutability-helper';
import { curry, flow, fromPairs, iteratee } from 'lodash-es';
import add from 'lodash/fp/add';
import pull from 'lodash/fp/pull';
import { actionTypes } from '../../actions/tradevanCreate/invoiceItems';

export const initialState = {
  itemMap: {},
  itemIdList: [],
};

const createItemPair = ({ name, price }) => [
  nanoid(),
  {
    name: name || '',
    price: parseInt(price, 10) || 0,
    qty: 1,
  },
];

const insertItemPairs = (state, itemPairs) =>
  update(state, {
    itemMap: {
      $merge: fromPairs(itemPairs),
    },
    itemIdList: {
      $push: itemPairs.map(iteratee('0')),
    },
  });

const curriedMax = curry(Math.max, 2);

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case actionTypes.INSERT_EMPTY:
      return insertItemPairs(state, [createItemPair({})]);
    case actionTypes.SET_ITEM_FIELD_VALUE:
      return update(state, {
        itemMap: {
          [payload.id]: {
            [payload.field]: {
              $set: payload.value,
            },
          },
        },
      });
    case actionTypes.INCREMENT_ITEM_QTY:
      return update(state, {
        itemMap: {
          [payload.id]: {
            qty: {
              $apply: flow(add(payload.qty), curriedMax(1)),
            },
          },
        },
      });
    case actionTypes.PULL_ITEM:
      return update(state, {
        itemMap: {
          $unset: [payload],
        },
        itemIdList: {
          $apply: pull(payload),
        },
      });
    case actionTypes.IMPORT_PRODUCTS:
      return insertItemPairs(state, payload.map(createItemPair));
    default:
      return state;
  }
}
