import {
  generateGetByDayList,
  generateGetByDateListActionTypes,
} from '../byDay/baseGetByDayList';

export const getByDayListActionTypes =
  generateGetByDateListActionTypes('TRADEVAN');

export const getByDayList = generateGetByDayList(
  'tradevan',
  getByDayListActionTypes,
);
