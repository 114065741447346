import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { subtotalSelector } from './selectors/invoiceItems';
import { totalDiscountSelector } from './selectors/discountItems';

const useMaxDiscount = (discountValue) => {
  const subtotal = useSelector(subtotalSelector);
  const totalDiscount = useSelector(totalDiscountSelector);
  return useMemo(
    () => ({
      value: Math.max(subtotal + totalDiscount + discountValue, 0),
      transKey: 'invoiceManagement:Discount Amount Max Error',
    }),
    [subtotal, totalDiscount, discountValue],
  );
};

export default useMaxDiscount;
