import { media, Breadcrumb } from '@shopline/dashboard-ui';
import styled from 'styled-components';
import TextLabel from '../TextLabel';
import PageHeaderPropTypes from '../../types/pageHeader';

export const HeaderWrapper = styled.div`
  ${media.mobile`
    padding: 0 1rem;
  `};
`;

const TitleSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ rightSectionOnly }) =>
    rightSectionOnly ? 'flex-end' : 'space-between'};
  flex-wrap: wrap;
  margin: 1.5rem 0;
`;

const PageHeader = ({
  titleLeftSection,
  titleRightSection = null,
  breadcrumbs,
}) => (
  <HeaderWrapper>
    {breadcrumbs && <Breadcrumb items={breadcrumbs} />}
    {(titleLeftSection || titleRightSection) && (
      <TitleSection rightSectionOnly={!titleLeftSection && !!titleRightSection}>
        {typeof titleLeftSection === 'string' ? (
          <TextLabel as="h2" fontType="DisplayLarge" color="INK_DEFAULT">
            {titleLeftSection}
          </TextLabel>
        ) : (
          titleLeftSection
        )}
        {titleRightSection}
      </TitleSection>
    )}
  </HeaderWrapper>
);

PageHeader.propTypes = PageHeaderPropTypes;

export default PageHeader;
