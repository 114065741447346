const invoiceManagement = {
  'Invoice Management': '發票管理',
  Invoice: '發票',
  'Create Invoice': '新增發票',
  'Invoice Details': '發票明細',
  'Order Invoice': '訂單發票',
  'Non-order Invoice': '獨立發票',
  'Invoice Number': '發票號碼',
  'Order Number': '訂單號碼',
  'Invoice Date': '發票日期',
  'Sales Amount': '銷售額',
  Tax: '營業稅額',
  'Total Amount': '總計',
  'Invoice Status': '發票狀態',
  Issued: '已開立',
  Voided: '已作廢',
  Void: '作廢',
  Channel: '開立渠道',
  Shop: '網店',
  'Reason for voiding invoice': '作廢原因',
  'Please enter reason for voiding invoice': '請輸入作廢原因',
  'Successfully created an invoice': '成功開立發票 {{invoiceNo}}',
  'Successfully voided an invoice': '成功作廢發票 {{invoiceNo}}',
  'Failed to void the invoice': '作廢發票失敗',
  Export: '匯出',
  Create: '新增',
  'Export Report': '匯出報表',
  'Type of Report': '報表類型',
  'Data Range': '選擇範圍',
  'Current Page': '目前頁面',
  'Select Date Range': '自訂區間',
  'maximum date range is 100 days': '最多 100 天',
  'The invoice has been voided': '該發票已經作廢過',
  "Can't void invoice that passes tax filing period":
    '發票已過申報期間不得作廢',
  'The invoice has been issued': '該發票已經開立過',
  'No available invoice number ': '未有可使用之發票號碼，導致開立失敗',
  'Invoice Information': '發票資訊',
  'Voided Date': '作廢日期',
  'Type of Invoice Request': '發票類型',
  Carrier: '載具類型',
  'Non-physical electronic invoice': '雲端發票',
  'Donate e-invoice': '捐贈發票',
  'Donation Units': '捐贈單位',
  'Uniform invoice': '公司戶發票',
  'Duplicate uniform invoice': '二聯式紙本',
  'Paper invoice': '紙本發票',
  Member: '會員載具',
  'Mobile Barcode': '手機條碼',
  'NPC Barcode': '自然人憑證條碼',
  'Company Name': '發票抬頭',
  'Tax ID': '統一編號',
  'Send to': '發票寄送地址',
  'Send to optional': '發票寄送地址（選填）',
  "Buyer's Information": '買受人資訊',
  "Buyer's Name": '買受人姓名',
  'Mobile Phone': '手機號碼',
  'Order Details': '訂單詳情',
  Subtotal: '小計',
  'Delivery Fee': '運費',
  'Payment Fee': '附加費',
  Total: '合計',
  'Tax Type': '税別',
  TaxTypeDisplay: '',
  TaxTypeDisplay_T: '應稅',
  TaxTypeDisplay_O: '免稅',
  TaxTypeDisplay_C: '零稅率-海關出口',
  TaxTypeDisplay_Z: '零稅率-非海關出口',
  Taxable: '$t(invoiceManagement:TaxTypeDisplay, {"context": "T"})',
  'Tax Free': '$t(invoiceManagement:TaxTypeDisplay, {"context": "O"})',
  'Zero-Tax-Rate - Exported by customs':
    '$t(invoiceManagement:TaxTypeDisplay, {"context": "C"})',
  'Zero-Tax-Rate - Not exported by customs':
    '$t(invoiceManagement:TaxTypeDisplay, {"context": "Z"})',
  "Import Customer's information": '帶入顧客資訊',
  'Product Information': '商品資訊',
  'Product Information Description': '僅用於開立發票，不影響商品庫存',
  'Import Product information': '帶入商品資訊',
  'Custom Item': '自訂商品',
  'Add Discount': '新增折扣',
  'please enter {xxxx}': '請輸入{xxxx}',
  'Address is up to 100 characters': '地址最多100個字元',
  'at least one product is required': '請新增至少 1 筆商品資訊',
  'up tp 250 characters only': '最多 250 字元',
  'Total amounts need to be > 0': '合計金額需大於 0',
  'Please fill out all the required field': '請輸入必填欄位',
  'Void Invoice': '作廢發票',
  'No Invoice Record': '沒有發票記錄',
  'No Invoice Record Description': '在 SHOPLINE 開立、作廢的發票紀錄將顯示於此',
  'Exporting Your Invoice Report': '正在輸出你的發票記錄',
  'Invoice Report': '發票報表',
  'List By Day': '日結列出',
  'Start - End Number': '發票起訖號碼',
  'Total Taxable Invoice Amount': '應稅發票總金額',
  'Total Tax Exempt Invoice Amount': '免稅發票總金額',
  'Total Zero-Tax-Rate Invoice Amount': '零稅發票總金額',
  'Void Invoice Number': '作廢發票號碼',
  'Customer Name': '姓名',
  'Customer Mobile': '手機號碼',
  'Customer Email': 'Email',
  'Is A Member': '已是會員',
  Import: '帶入',
  'Invoice Amount': '發票金額',
  'Items Subtotal': '商品小計',
  'Discount Name': '折扣名稱',
  'Discount Amount': '折扣金額',
  'Product Title': '商品',
  'Product Variations': '款式',
  'Product Price': '價格 (含稅）',
  'Search Product Placeholder': '搜尋商品名稱/代號',
  'View Selected Products': '檢視已選商品({{count}})',
  'View All Products': '檢視全部商品',
  'Search Customer Placeholder': '搜尋顧客姓名 / 手機號碼 / Email',
  'Save Discount': '儲存',
  'No Products Found': '沒有商品資訊',
  'No Result': '沒有結果',
  'Try Again Search': '請試試其他關鍵字或搜尋條件',
  'Invoice and Tax': '發票報稅',
  'Retail Store': '實體店管理',
  'Failed to create': '開立發票失敗',
  'Error Description': '請再次嘗試，若失敗請聯絡客戶顧問',
  'Error Description_Failed to create-1002':
    '未有可使用之發票號碼，導致開立失敗',
  'Error Description_Failed to void the invoice-3002':
    '發票已過申報期間不得作廢',
  'Error Description_Failed to void the invoice-3003': '該發票已經作廢過',
  'Product Name': '商品名稱',
  'Enter Product Name': '輸入商品名稱',
  'Discount Amount Min Error': '折扣金額需大於 0',
  'Discount Amount Max Error': '折扣金額不得超過商品小計',
  'Invalid invoice amount': '發票金額有誤',
  'maximum date range is 1 year': '篩選區間至多一年',
  Required: '必填',
};

export default invoiceManagement;
