import { curry } from 'lodash-es';

const PREFIX = 'INVOICE_ITEMS';

export const INSERT = `${PREFIX}.INSERT`;
export const UPDATE = `${PREFIX}.UPDATE`;
export const PULL = `${PREFIX}.PULL`;

export const actionTypes = {
  INSERT,
  UPDATE,
  PULL,
};

export const insert = (discount) => ({
  type: actionTypes.INSERT,
  payload: discount,
});

export const update = (id, discount) => ({
  type: actionTypes.UPDATE,
  payload: { id, discount },
});

export const pull = (id) => ({
  type: actionTypes.PULL,
  payload: id,
});

export const actions = {
  insert,
  update: curry(update),
  pull,
};
