import { Paginator as UIPaginator } from '@shopline/dashboard-ui';
import styled from 'styled-components';
import useScreenType from '../../hooks/useScreenType';

const PaginatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
`;

const windowSizeMap = {
  desktop: 14,
  tablet: 10,
  mobile: 5,
};

const Paginator = (props) => {
  const screenType = useScreenType();

  return (
    <PaginatorWrapper>
      <UIPaginator windowSize={windowSizeMap[screenType]} {...props} />
    </PaginatorWrapper>
  );
};

export default Paginator;
