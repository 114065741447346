import { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { flow, mapValues, identity, trimStart, trimEnd } from 'lodash-es';
import update from 'immutability-helper';
import { Input } from '@shopline/dashboard-ui';
import validate from 'utils/validate';

const FormInput = ({
  as,
  trimStart: needTrimStart,
  trimEnd: needTrimEnd,
  rule,
  fieldName,
  validateKey,
  ...props
}) => {
  const Component = useMemo(() => as || Input, [as]);
  const { t } = useTranslation();
  const ruleWithTranslation = useMemo(
    () =>
      mapValues(rule, (ruleConfig, ruleType) =>
        update(ruleConfig, {
          message: {
            $set: t(ruleConfig.transKey || `fieldValidation:${ruleType}`, {
              ...ruleConfig,
              fieldName,
            }),
          },
        }),
      ),
    [t, rule, fieldName],
  );
  const validateKeyRef = useRef(validateKey);
  const getInputError = useMemo(
    () => validate(ruleWithTranslation),
    [ruleWithTranslation],
  );
  const [error, setError] = useState('');
  const hasError = error !== '';
  const renderMessages = useMemo(
    () => (hasError ? [() => error] : []),
    [error, hasError],
  );
  const handleBlur = useCallback(
    (event) => {
      const trim = flow(
        needTrimStart ? trimStart : identity,
        needTrimEnd ? trimEnd : identity,
      );
      const value = trim(event.target.value);
      setError(getInputError(value));
    },
    [getInputError, needTrimStart, needTrimEnd],
  );
  useEffect(() => {
    if (validateKey && validateKeyRef.current !== validateKey) {
      validateKeyRef.current = validateKey;
      setError(getInputError(props.value));
    } else if (hasError) {
      setError(getInputError(props.value));
    }
  }, [hasError, getInputError, props.value, validateKey]);

  return (
    <Component
      status={hasError ? 'invalid' : 'valid'}
      onBlur={handleBlur}
      renderMessages={renderMessages}
      {...props}
    />
  );
};

export default FormInput;
