import { createSelector } from 'reselect';
import { get, iteratee, nthArg, toPairs } from 'lodash-es';

const discountItemsSelector = iteratee(
  'invoiceManagement.tradevanCreate.discountItems',
);

export const itemMapSelector = createSelector(
  discountItemsSelector,
  iteratee('itemMap'),
);

export const itemSelector = createSelector(itemMapSelector, nthArg(1), get);

export const discountIdListSelector = createSelector(
  discountItemsSelector,
  iteratee('itemIdList'),
);

const discountMapSelector = createSelector(
  discountItemsSelector,
  iteratee('itemMap'),
);

const discountPairsSelector = createSelector(discountMapSelector, toPairs);

export const totalDiscountSelector = createSelector(
  discountPairsSelector,
  (discountPairs) =>
    discountPairs.reduce(
      (accumulator, [, { discountValue }]) => accumulator - discountValue,
      0,
    ),
);
