import styled, { css } from 'styled-components';
import { MessageWrapper } from '@shopline/dashboard-ui/dist/esm/components/input';
import { cond, constant, iteratee, stubTrue } from 'lodash-es';
import Hr from './Hr';

const paddingRight = ({ theme }) => (theme.hasDiscounts ? '2.25em' : '0');

const height = cond([
  [iteratee({ kind: 'total' }), constant('1.25em')],
  [iteratee({ kind: 'description' }), constant('1em')],
  [stubTrue, constant('2em')],
]);

const descriptionMixin = css`
  &::before {
    content: '';
    display: block;
  }
`;

const SummaryLine = styled.div`
  margin: 0.25rem 0;
  flex: 0 0 100%;
  height: ${height};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: ${paddingRight};
  ${({ kind }) => kind === 'description' && descriptionMixin}
  ${Hr} + & {
    margin-top: 0;
  }
  & ${MessageWrapper} {
    display: none;
  }
`;

export default SummaryLine;
