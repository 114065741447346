import { useState, useEffect, useCallback, useMemo, memo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { negate } from 'lodash-es';
import getOr from 'lodash/fp/getOr';

import { TRADEVAN } from 'constants/vendor';

import Paginator from 'components/Paginator';
import VoidPopup from './list/VoidPopup';
import InvoiceTypeTab from '../../components/InvoiceTypeTab';
import ByOrderFilterSection from '../../components/ByOrderFilterSection';
import ByOrderTagPanelSection from '../../components/ByOrderTagPanelSection';
import ByOrderInvoiceTable from '../../components/ByOrderInvoiceTable';
import { COLUMN_KEYS } from '../../hooks/useInvoiceTableConfig';
import { E2E_BY_ORDER_PREFIX } from '../../constants';

const TableContentWrapper = styled.div`
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.SNOW_200};
`;

const PaginatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
`;

const EXCLUDE_COLUMNS_KEYS = [COLUMN_KEYS.COMPANYUN];

const removeDisabledSelector = negate(
  getOr(false, ['merchant', 'permissions', 'invoices', 'void']),
);

const initVoidPopupState = {
  open: false,
  data: null,
};

const OrderList = ({
  parameters,
  updateParams,
  detailId,
  onChangeDetailId,
  list,
  isLoadingList,
  getInvoiceList,
  voidInvoice,
  pagination,
  channels,
}) => {
  const removeDisabled = useSelector(removeDisabledSelector);
  const [voidPopupStatus, setVoidPopupStatus] = useState(initVoidPopupState);

  const tableDate = useMemo(
    () =>
      list.map((item) => ({
        ...item,
        sales_amount: item.total_amount - item.tax_amount,
      })),
    [list],
  );

  const onClickRemove = useCallback((rowData) => {
    setVoidPopupStatus({
      open: true,
      data: rowData,
    });
  }, []);

  const onClickTab = useCallback(
    (e, tabKey) => {
      updateParams({ invoiceType: tabKey });
    },
    [updateParams],
  );

  const onPageChange = useCallback(
    (page) => {
      updateParams({ page: page.toString() });
    },
    [updateParams],
  );

  const onCloseVoidPopup = useCallback(() => {
    setVoidPopupStatus(initVoidPopupState);
  }, []);

  const onSubmitVoidPopup = useCallback(
    async (data, reason) => {
      onCloseVoidPopup();
      await voidInvoice({ invoiceData: data, reason });
      updateParams({ page: '1' }, true);
    },
    [onCloseVoidPopup, voidInvoice, updateParams],
  );

  useEffect(() => {
    getInvoiceList(parameters);
  }, [parameters, getInvoiceList]);

  return (
    <>
      <ByOrderFilterSection
        e2eFeaturePrefix={E2E_BY_ORDER_PREFIX}
        vendorType={TRADEVAN}
        channels={channels}
        parameters={parameters}
        updateParams={updateParams}
      />
      <ByOrderTagPanelSection
        e2eFeaturePrefix={E2E_BY_ORDER_PREFIX}
        vendorType={TRADEVAN}
        channels={channels}
        parameters={parameters}
        updateParams={updateParams}
      />
      <TableContentWrapper>
        <InvoiceTypeTab
          currentTab={parameters.invoiceType}
          onClick={onClickTab}
        />
        <ByOrderInvoiceTable
          tableData={tableDate}
          removeDisabled={removeDisabled}
          isEmpty={pagination && pagination.total_count === 0}
          isLoading={isLoadingList}
          excludeColumnKeys={EXCLUDE_COLUMNS_KEYS}
          activeRowId={detailId}
          onClickRemove={onClickRemove}
          onTableRowClick={onChangeDetailId}
        />
      </TableContentWrapper>
      {!isLoadingList && pagination && pagination.total_pages > 1 && (
        <PaginatorWrapper>
          <Paginator
            totalCount={pagination.total_count}
            onPageChange={onPageChange}
            page={pagination.current_page}
            limit={pagination.per_page}
          />
        </PaginatorWrapper>
      )}
      {voidPopupStatus.open && (
        <VoidPopup
          data={voidPopupStatus.data}
          onClose={onCloseVoidPopup}
          onSubmit={onSubmitVoidPopup}
        />
      )}
    </>
  );
};

export default memo(OrderList);
