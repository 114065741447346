import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Text from 'components/Text';

const Container = styled.div`
  display: flex;
  align-items: center;

  & > .page-title-caption {
    margin-left: 0.5rem;
  }
`;

const PageTitle = ({ children }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Text as="h2" fontType="DisplayLarge" color="INK_DEFAULT">
        {children}
      </Text>
      <Text className="page-title-caption" fontType="Caption" color="INK_500">
        *{t('invoiceManagement:Required')}
      </Text>
    </Container>
  );
};

export default PageTitle;
