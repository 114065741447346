import { useCallback, useMemo, useRef } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import update from 'immutability-helper';
import { curryRight, flowRight } from 'lodash-es';
import { Button, Icons } from '@shopline/dashboard-ui';
import Text from 'components/Text';
import PriceText from 'components/PriceText';
import { withProps } from 'utils/recompose';
import { actions as mainActions } from 'stores/invoiceManagement/actions/tradevanCreate/main';
import { actions as discountItemsActions } from 'stores/invoiceManagement/actions/tradevanCreate/discountItems';
import { customTheme } from './utils';
import {
  submitKeySelector,
  shippingFeeSelector,
  extraFeeSelector,
  totalSelector,
  submitDisabledSelector,
} from './selectors/main';
import { subtotalSelector } from './selectors/invoiceItems';
import {
  discountIdListSelector,
  totalDiscountSelector,
} from './selectors/discountItems';
import {
  AddItemButton,
  DiscountEditorPopover,
  Hr,
  PopoverContainer,
  PriceInput,
  Section,
  SummaryLine,
} from './components';
import DiscountItem from './DiscountItem';
import useMaxDiscount from './useMaxDiscount';
import useCreateInvoice from './useCreateInvoice';
import PARENT_E2E_PREFIX from './e2ePrefix';

const E2E_PREFIX = `${PARENT_E2E_PREFIX}-summary-section`;

const SmallIconCirclePlus = withProps({ size: 'SMALL' })(
  Icons.Outline.CirclePlus,
);

const BodyText = withProps({ fontType: 'Body', color: 'INK_800' })(Text);

const EnhancedPriceInput = withProps({
  width: '2.875',
  min: '0',
  inputSize: 'SMALL',
})(PriceInput);

const ScrollableContainer = styled.div`
  flex: 1 0 100%;
  margin: 0 -1.5rem;
  padding: 1rem 1.5rem;
  max-height: 11.75rem;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  & + ${Hr} {
    margin-top: 0;
    margin-bottom: 1rem;
  }
`;

const CreateButton = styled(Button.Primary)`
  margin-top: 0.75rem;
`;

const TotalError = ({ children }) => (
  <SummaryLine kind="description">
    <Text fontType="Caption" color="RED_600" e2eId={`${E2E_PREFIX}-error-text`}>
      {children}
    </Text>
  </SummaryLine>
);

const withHasDiscountTheme = curryRight((theme, hasDiscounts) =>
  update(theme, {
    $merge: { hasDiscounts },
  }),
);

const SummarySection = () => {
  const { t } = useTranslation();
  const submitKey = useSelector(submitKeySelector);
  const submitKeyRef = useRef(submitKey);
  const subtotal = useSelector(subtotalSelector);
  const shippingFee = useSelector(shippingFeeSelector);
  const extraFee = useSelector(extraFeeSelector);
  const discountIdList = useSelector(discountIdListSelector);
  const totalDiscount = useSelector(totalDiscountSelector);
  const total = useSelector(totalSelector);
  const submitDisabled = useSelector(submitDisabledSelector);
  const maxDiscount = useMaxDiscount(0);
  const dispatch = useDispatch();
  const handleShippingFeeChange = useCallback(
    (value) => {
      dispatch(mainActions.setShippingFee(value));
    },
    [dispatch],
  );
  const handleExtraFeeChange = useCallback(
    (value) => {
      dispatch(mainActions.setExtraFee(value));
    },
    [dispatch],
  );
  const handleInsertDiscount = useCallback(
    (discount) => {
      dispatch(discountItemsActions.insert(discount));
    },
    [dispatch],
  );
  const hasDiscounts = discountIdList.length !== 0;
  const hasDiscountsTheme = useMemo(
    () => withHasDiscountTheme(hasDiscounts),
    [hasDiscounts],
  );
  const [creating, createInvoice] = useCreateInvoice();
  const hasSubmitted = submitKeyRef.current !== submitKey;
  const hasTotalError = hasSubmitted && total <= 0;
  const hasDiscountMaxError =
    hasSubmitted && !hasTotalError && subtotal + totalDiscount < 0;
  return (
    <ThemeProvider theme={flowRight(customTheme, hasDiscountsTheme)}>
      <Section title={t('invoiceManagement:Invoice Amount')}>
        <Hr />
        <ScrollableContainer>
          <SummaryLine kind="total">
            <BodyText>{t('invoiceManagement:Items Subtotal')}</BodyText>
            <PriceText e2eId={`${E2E_PREFIX}-subtotal-text`}>
              {subtotal}
            </PriceText>
          </SummaryLine>
          <SummaryLine>
            <BodyText>{t('invoiceManagement:Delivery Fee')}</BodyText>
            <EnhancedPriceInput
              value={shippingFee}
              e2eInputId={`${E2E_PREFIX}-shipping-fee-input`}
              e2eMessagesId={`${E2E_PREFIX}-shipping-fee-messages`}
              onChange={handleShippingFeeChange}
            />
          </SummaryLine>
          <SummaryLine>
            <BodyText>{t('invoiceManagement:Payment Fee')}</BodyText>
            <EnhancedPriceInput
              value={extraFee}
              e2eInputId={`${E2E_PREFIX}-extra-fee-input`}
              e2eMessagesId={`${E2E_PREFIX}-extra-fee-messages`}
              onChange={handleExtraFeeChange}
            />
          </SummaryLine>
          {discountIdList.map((discountId) => (
            <DiscountItem key={discountId} id={discountId} />
          ))}
          {discountIdList.length < 10 && (
            <DiscountEditorPopover
              initialName=""
              initialDiscountValue={0}
              maxDiscount={maxDiscount}
              submitText={t('invoiceManagement:Create')}
              onSubmit={handleInsertDiscount}
              container={PopoverContainer}
              e2eId={`${E2E_PREFIX}-new-editor`}
            >
              {({ togglePopover }) => (
                <AddItemButton
                  icon={<SmallIconCirclePlus />}
                  onClick={togglePopover}
                  data-e2e-id={`${E2E_PREFIX}-open-editor-btn`}
                >
                  {t('invoiceManagement:Add Discount')}
                </AddItemButton>
              )}
            </DiscountEditorPopover>
          )}
        </ScrollableContainer>
        <Hr />
        <SummaryLine kind="total">
          <BodyText weight="Semibold">{t('invoiceManagement:Total')}</BodyText>
          <PriceText
            weight="Semibold"
            color={hasTotalError ? 'RED_600' : 'INK_800'}
            e2eId={`${E2E_PREFIX}-total-text`}
          >
            {total}
          </PriceText>
        </SummaryLine>
        {hasTotalError && (
          <TotalError>
            {t('invoiceManagement:Total amounts need to be > 0')}
          </TotalError>
        )}
        {hasDiscountMaxError && (
          <TotalError>
            {t('invoiceManagement:Discount Amount Max Error')}
          </TotalError>
        )}
        <CreateButton
          block
          disabled={submitDisabled}
          isLoading={creating}
          onClick={createInvoice}
          e2eId={`${E2E_PREFIX}-submit-btn`}
        >
          {t('invoiceManagement:Create Invoice')}
        </CreateButton>
      </Section>
    </ThemeProvider>
  );
};

export default SummarySection;
