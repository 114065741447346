import { createSelector } from 'reselect';
import { iteratee } from 'lodash-es';
import { toDropdownValue } from '../utils';

const personalCarrierSelector = iteratee(
  'invoiceManagement.tradevanCreate.personalCarrier',
);

export const carrierTypeSelector = createSelector(
  personalCarrierSelector,
  iteratee('type'),
);

export const carrierTypeValueSelector = createSelector(
  carrierTypeSelector,
  toDropdownValue,
);

export const carrierValueSelector = createSelector(
  personalCarrierSelector,
  iteratee('value'),
);
