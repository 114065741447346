import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button, Input } from '@shopline/dashboard-ui';
import PriceInput from '../PriceInput';
import Container from './Container';
import Footer from './Footer';
import InputWrapper from './InputWrapper';
import useDiscountEditor from './useDiscountEditor';

const CancelButton = styled(Button.Default)`
  margin-right: 1rem;
`;

const DiscountEditor = ({
  maxDiscount,
  initialName,
  initialDiscountValue,
  validateDefaultValues,
  submitText,
  onCancel,
  onSubmit,
  e2eId,
}) => {
  const { t } = useTranslation();
  const [
    { name, discountValue },
    { errors, handlers, handleSubmit, hasErrors, touched },
    { renderMessages },
  ] = useDiscountEditor({
    maxDiscount,
    initialName,
    initialDiscountValue,
    validateDefaultValues,
    onSubmit,
  });
  const handleNameChange = useCallback(
    ({ target: { value } }) => handlers.name.onChange(value),
    [handlers.name],
  );

  return (
    <Container>
      <InputWrapper>
        <Input
          block
          title={`${t('invoiceManagement:Discount Name')}*`}
          value={name}
          status={errors.name !== '' ? 'invalid' : 'valid'}
          renderMessages={renderMessages.name}
          onChange={handleNameChange}
          onBlur={handlers.name.onBlur}
          e2eInputId={`${e2eId}-name-input`}
          e2eMessagesId={`${e2eId}-name-errors`}
        />
      </InputWrapper>
      <InputWrapper>
        <PriceInput
          block
          min="1"
          title={t('invoiceManagement:Discount Amount')}
          value={discountValue}
          status={errors.discountValue !== '' ? 'invalid' : 'valid'}
          renderMessages={renderMessages.discountValue}
          e2eInputId={`${e2eId}-price-input`}
          e2eMessagesId={`${e2eId}-price-errors`}
          {...handlers.discountValue}
        />
      </InputWrapper>
      <Footer>
        <Button.Primary
          e2eId={`${e2eId}-confirm-btn`}
          onClick={handleSubmit}
          disabled={(!touched && !validateDefaultValues) || hasErrors}
        >
          {submitText}
        </Button.Primary>
        <CancelButton e2eId={`${e2eId}-cancel-btn`} onClick={onCancel}>
          {t('common:Cancel')}
        </CancelButton>
      </Footer>
    </Container>
  );
};

export default DiscountEditor;
