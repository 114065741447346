import { useState, useEffect, useCallback, memo } from 'react';
import { TooltipView } from '@shopline/dashboard-ui';
import styled from 'styled-components';
import { flow, cond, stubTrue, isString, constant, iteratee } from 'lodash-es';

export const renderWidthStyle = ({ width }) => `width: ${width};`;

const SelectorContainer = styled.div`
  &,
  & .tooltipViewContainer {
    ${cond([
      [flow(iteratee('width'), isString), renderWidthStyle],
      [stubTrue, constant('')],
    ])}
  }
`;

const TooltipSelectorWrapper = ({
  width,
  dropdownRef,
  selectorRef,
  text,
  tooltipDirection = 'TOP_CENTER',
  children,
}) => {
  const [isOptionOverflow, setIsOptionOverflow] = useState(false);
  const [isOptionHover, setIsOptionHover] = useState(false);

  const onMouseEnter = useCallback(() => setIsOptionHover(true), []);
  const onMouseLeave = useCallback(() => setIsOptionHover(false), []);

  const selector = selectorRef?.current || dropdownRef?.current?.selectorRef.current;

  useEffect(() => {
    if (selector) {
      const { scrollWidth, clientWidth } = selector;
      setIsOptionOverflow(scrollWidth > clientWidth);
    }
  }, [text, selector]);

  return (
    <SelectorContainer width={width || ''}>
      <TooltipView
        type="primary"
        text={text}
        show={isOptionHover && isOptionOverflow}
        direction={tooltipDirection}
        onMouseEnterChildren={onMouseEnter}
        onMouseLeaveChildren={onMouseLeave}
        containerClassName="tooltipViewContainer"
      >
        {children}
      </TooltipView>
    </SelectorContainer>
  );
};

export default memo(TooltipSelectorWrapper);
