const common = {
  Test: '測試',
  '25 Rows': '25笔',
  '50 Rows': '50笔',
  '100 Rows': '100 筆',
  'Reason is required': '原因 是必须的',
  'Data is required': '{{column}} 是必须的',
  'Data is invalid': '{{column}} 无效',
  All: '全部',
  'Per Page Limit': '显示笔数',
  Filter: '筛选',
  Cancel: '取消',
  Date: '订单日期',
  Clear: '清除',
  Search: '搜寻',
  'Please Enter': '请输入{{field}}',
  'Report Toast Message':
    '资料正在传送至 {{email}} 。在繁忙时间，可能要稍候片刻，请耐心等候。',
  Here: '点击此处',
};

export default common;
