import { memo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Loading } from '@shopline/dashboard-ui';

const LoadingWrapper = styled.div`
  align-items: center;
  & * {
    box-sizing: content-box;
  }
`;

LoadingWrapper.displayName = 'LoadingWrapper';

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0rem 1rem;
  ${({ rowHeight }) => `height: ${rowHeight};`}
  & > * {
    flex-grow: 1;
  }
  &:not(:last-child) {
    border-bottom: solid 0.0625rem #e6eaf0;
  }
`;

LoadingContainer.displayName = 'LoadingContainer';

const LoadingRows = ({ withAvatar = false, rowNumber = 10, rowHeight = '3.5625rem' }) => {
  const LoadingElement = withAvatar ? Loading.AvatarWithInfo : Loading.BodyText;
  return (
    <LoadingWrapper>
      {Array(rowNumber)
        .fill(undefined)
        .map((_, idx) => (
          <LoadingContainer key={idx} rowHeight={rowHeight}>
            <LoadingElement />
          </LoadingContainer>
        ))}
    </LoadingWrapper>
  );
};

LoadingRows.propTypes = {
  withAvatar: PropTypes.bool,
  rowNumber: PropTypes.number,
  rowHeight: PropTypes.string,
};

LoadingRows.displayName = 'LoadingRows';

export default memo(LoadingRows);
