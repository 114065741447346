import { useState, useLayoutEffect, useCallback, useMemo } from 'react';
import { isEmpty, isEqual, pickBy } from 'lodash-es';
import qs from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';

import { dispatchUpdateListSearch } from '../utils/message/postMessageCenter';

export function validateParam(source, current) {
  return source.includes(current) ? current : source[0];
}

export function getQueryString(searchParams) {
  return `?${qs.stringify(searchParams)}`;
}

function useSearchParamsObj() {
  const { search } = useLocation();
  const searchParamsObj = useMemo(() => qs.parse(search), [search]);
  return searchParamsObj;
}

function useSearch({
  baseParameters,
  parseSearchParams,
  generateSearchParams,
}) {
  const currentParams = useSearchParamsObj();
  const history = useHistory();

  const [parameters, setParameters] = useState(() => ({
    ...baseParameters,
    ...parseSearchParams(currentParams),
  }));

  const updateParams = useCallback((updatedParams, forceUpdate = false) => {
    setParameters((prevParams) => {
      const newParams = pickBy(
        {
          ...prevParams,
          ...updatedParams,
        },
        (value) => value !== undefined,
      );
      const shouldUpdate = !isEqual(prevParams, newParams) || forceUpdate;
      if (shouldUpdate) return newParams;
      return prevParams;
    });
  }, []);

  useLayoutEffect(() => {
    const paredCurrentParams = parseSearchParams(currentParams);
    const shouldUpdate = !isEqual(paredCurrentParams, parameters);
    if (shouldUpdate) {
      const searchParams = generateSearchParams(parameters);
      const queryString = getQueryString(searchParams);
      if (isEmpty(currentParams)) history.replace({ search: queryString });
      else history.push({ search: queryString });
      dispatchUpdateListSearch({ parameters });
    }
  }, [
    currentParams,
    parameters,
    history,
    parseSearchParams,
    generateSearchParams,
  ]);

  return {
    parameters,
    updateParams,
  };
}

export { useSearch };
