const errorConfig = {
  key: 'error',
  value: 'error',
  label: 'Error',
};

export function getTargetConfig(config, targetValue, targetKey) {
  const targetConfig = config.find((data) => data.value === targetValue) || errorConfig;
  if (targetKey && Object.keys(targetConfig).includes(targetKey)) {
    return targetConfig[targetKey];
  }
  return targetConfig;
}
