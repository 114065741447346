import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown from '../Dropdown';

export const perPageLimitConfig = [
  {
    key: '25',
    value: '25',
    label: '25 Rows',
  },
  {
    key: '50',
    value: '50',
    label: '50 Rows',
  },
  {
    key: '100',
    value: '100',
    label: '100 Rows',
  },
];

const PageDropdown = ({
  block = false,
  disabled = false,
  onChange = () => {},
  value = ['25'],
  width = '7.5rem',
  minHeight = '2rem',
  ...restProps
}) => {
  const { t } = useTranslation('common');
  const optionsWithLang = useMemo(
    () =>
      perPageLimitConfig.map((option) => ({
        ...option,
        label: t(option.label),
        disabled,
      })),
    [t, disabled],
  );

  return (
    <Dropdown
      block={block}
      shouldShowTooltip
      options={optionsWithLang}
      onChange={onChange}
      value={value}
      placeHolder=""
      width={width}
      minHeight={minHeight}
      {...restProps}
    />
  );
};

export default PageDropdown;
