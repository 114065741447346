import { useTranslation } from 'react-i18next';
import UIStatusBadge from 'components/StatusBadge';

const StatusBadge = ({ invalidAt }) => {
  const { t } = useTranslation();
  if (!invalidAt) return <UIStatusBadge.Green label={t('invoiceManagement:Issued')} />;
  return <UIStatusBadge.Grey label={t('invoiceManagement:Voided')} />;
};

export default StatusBadge;
