import { Icons, TextButton } from '@shopline/dashboard-ui';
import { useTranslation } from 'react-i18next';

const ClearButton = ({ onClick, disabled = false, ...restProps }) => {
  const { t } = useTranslation();

  return (
    <TextButton.Primary disabled={disabled} onClick={onClick} {...restProps}>
      <Icons.Outline.Reset size="SMALL" />
      {t('common:Clear')}
    </TextButton.Primary>
  );
};

export default ClearButton;
