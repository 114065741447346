import { combineReducers } from 'redux';
import tradevanCreate from './tradevanCreate';
import tradevanList from './tradevanList';
import tradevanByDayList from './tradevanByDayList';
import tradevanDetail from './tradevanDetail';
import cetustekByOrderList from './cetustekByOrderList';
import cetustekByDayList from './cetustekByDayList';

const invoiceManagement = combineReducers({
  tradevanCreate,
  tradevanList,
  tradevanDetail,
  tradevanByDayList,
  cetustekByOrderList,
  cetustekByDayList,
});

export default invoiceManagement;
