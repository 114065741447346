import { useMemo, memo } from 'react';
import { useTranslation } from 'react-i18next';
import getTranslation from 'utils/getTranslation';

import Dropdown from '../Dropdown';

const ChannelDropdown = ({
  fixedOptions = [],
  menuAlign = 'left',
  channels,
  onChange,
  value,
  disabled = false,
  placeHolder = '',
  width = '100%',
  minHeight = '2.25rem',
  ...rest
}) => {
  const { i18n } = useTranslation();
  const { language } = i18n;
  const channelsOptions = useMemo(() => {
    const channelOptions = channels.map(({ id, name }) => ({
      key: id,
      value: id,
      label: getTranslation(name, language),
    }));
    const options = fixedOptions.concat(channelOptions);
    return options;
  }, [channels, language, fixedOptions]);

  return (
    <Dropdown
      block
      enableSearch
      disabled={disabled}
      onChange={onChange}
      placeHolder={placeHolder}
      options={channelsOptions}
      width={width}
      minHeight={minHeight}
      value={value ? [value] : []}
      useDefaultBorder
      shouldShowTooltip
      keepPlaceHolder
      {...rest}
    />
  );
};

export default memo(ChannelDropdown);
