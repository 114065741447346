import isValidUniformNumber from 'utils/isValidUniformNumber';

const MOBILE_PATTERN = /^09\d{8}$/;
const EMAIL_PATTERN =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const buyerNameRule = {
  maxLength: {
    value: 30,
    message: '不得超過30個字',
  },
};

export const buyerTelRule = {
  pattern: {
    value: MOBILE_PATTERN,
    message: '手機號碼無效',
  },
};

export const getBuyerEmailRule = (required) => ({
  required: {
    value: required,
    message: 'Email 是必須的',
  },
  pattern: {
    value: EMAIL_PATTERN,
    message: 'Email 無效',
  },
});

export const itemNameRule = {
  required: {
    value: true,
    message: '商品名稱 是必須的',
  },
  maxLength: {
    value: 250,
    message: '最多 250 字元',
  },
};

export const companyUniformNumberRule = {
  required: {
    value: true,
    message: '統一編號 是必須的',
  },
  pattern: {
    value: /^\d{8}$/,
    message: '統一編號 無效',
  },
  custom: {
    value: isValidUniformNumber,
    message: '統一編號 無效',
    transKey: 'fieldValidation:pattern',
  },
};

export const companyAddressRule = {
  required: {
    value: false,
  },
  maxLength: {
    value: 100,
    message: '最多 100 字元',
  },
};

export const duplicateAddressRule = {
  required: {
    value: true,
    message: '發票寄送地址 是必須的',
  },
  maxLength: {
    value: 100,
    message: '最多 100 字元',
  },
};

export const digitalCertificateRule = {
  required: {
    value: true,
    message: '自然人憑證條碼 是必須的',
  },
  pattern: {
    value: /^[A-Z]{2}\d{14}$/,
    message: '自然人憑證條碼 無效',
  },
};

export const mobileBarcodeRule = {
  required: {
    value: true,
    message: '手機條碼 是必須的',
  },
  pattern: {
    value: /^\/[A-Z0-9+-.]{7}$/,
    message: '手機條碼 無效',
  },
};

export const discountNameRule = {
  required: {
    value: true,
    message: '折扣名稱 是必須的',
  },
  maxLength: {
    value: 250,
    message: '最多 250 字元',
  },
  pattern: {
    value: /\S/,
    message: '折扣名稱 無效',
  },
};

export const getDiscountValueRule = ({
  value: max,
  transKey: maxTransKey,
}) => ({
  min: {
    value: 1,
    message: '折扣金額需大於 0',
    transKey: 'invoiceManagement:Discount Amount Min Error',
  },
  max: {
    value: max,
    message: '合計金額需大於 0',
    transKey: maxTransKey,
  },
});
