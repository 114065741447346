import { useState, useCallback } from 'react';
import {
  Popup,
  RadioButton,
  Collapse,
  Button,
  Checkbox,
  DateRangePicker,
} from '@shopline/dashboard-ui';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { xor } from 'lodash-es';

import { TRADEVAN } from 'constants/vendor';
import ClearButton from 'components/Button/ClearButton';
import TextLabel from 'components/TextLabel';
import { perPageLimitConfig } from 'components/PageDropdown';

import dayjs from 'utils/day';
import getTranslation from 'utils/getTranslation';
import { invoiceStatusConfig, fixedChannelConfig } from '../filterConfig';

const E2E_PREFIX = 'mobile-filter-popup-';

const TimeRangeHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  ${({ hasPadding }) => hasPadding && 'padding: 1rem 1.8125rem 0rem 1rem;'}
  ${({ hasBorder, theme }) =>
    hasBorder && `border-bottom: 1px solid ${theme.colors.SKY_DEFAULT}`};
  & > * {
    margin-bottom: 1rem;
  }
  & > label {
    align-items: center;
    & > div > span {
      max-width: unset;
    }
  }
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .btn-cancel {
    margin-right: 1rem;
  }
`;

const PanelHeader = ({ title }) => (
  <TextLabel fontType="Subheading" color="INK_800">
    {title}
  </TextLabel>
);

const ByDayMobileFiltersPopup = ({
  e2eFeaturePrefix,
  vendorType,
  initParams,
  channels,
  onConfirm,
  onCancel,
}) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const [parameters, setParameters] = useState(() => initParams);

  const updateParams = useCallback(
    (params) => {
      setParameters((prevParams) => ({
        ...prevParams,
        ...params,
      }));
    },
    [setParameters],
  );

  const onRangeChange = useCallback(
    (params) => {
      const [start, end] = params.range || [];
      const startDay = dayjs(language, start);
      const endDay = dayjs(language, end);
      if (!start || !end || startDay.isAfter(endDay))
        updateParams({ startDate: undefined, endDate: undefined, page: '1' });
      else
        updateParams({
          startDate: new Date(startDay.format('YYYY-MM-DD')),
          endDate: new Date(endDay.format('YYYY-MM-DD')),
          page: '1',
        });
    },
    [updateParams, language],
  );

  const onChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      switch (name) {
        case 'invoiceStatus':
          updateParams({
            [name]: xor(parameters[name], [value]),
          });
          break;
        case 'limit':
          updateParams({ limit: value, page: '1' });
          break;
        default:
          updateParams({ [name]: value, page: '1' });
          break;
      }
    },
    [parameters, updateParams],
  );

  const onClearClick = (type) => {
    if (type === 'date')
      updateParams({
        startDate: undefined,
        endDate: undefined,
        page: '1',
      });
    else
      updateParams({
        [type]: [],
        page: '1',
      });
  };

  const renderFooter = () => (
    <Buttons>
      <Button.Default
        e2eId={`${e2eFeaturePrefix}${E2E_PREFIX}popup-cancel-btn`}
        className="btn-cancel"
        onClick={onCancel}
        width="MEDIUM"
        height="LARGE"
      >
        {t('common:Cancel')}
      </Button.Default>
      <Button.Primary
        e2eId={`${e2eFeaturePrefix}${E2E_PREFIX}popup-confirm-btn`}
        className="btn-delete"
        onClick={() => {
          onConfirm(parameters);
        }}
        width="MEDIUM"
        height="LARGE"
      >
        {t('common:Filter')}
      </Button.Primary>
    </Buttons>
  );

  return (
    <Popup
      e2eId={`${e2eFeaturePrefix}${E2E_PREFIX}popup`}
      title={t('common:Filter')}
      width="40rem"
      renderFooter={renderFooter}
      onClose={onCancel}
      hasBodyPadding={false}
    >
      <Content hasPadding hasBorder>
        <TimeRangeHeader>
          <PanelHeader title={t('common:Date')} />
          <ClearButton
            data-e2e-id={`${e2eFeaturePrefix}${E2E_PREFIX}-time-range-clear-button`}
            disabled={!parameters.startDate && !parameters.endDate}
            onClick={() => onClearClick('date')}
          />
        </TimeRangeHeader>
        <DateRangePicker
          data-e2e-id={`${e2eFeaturePrefix}${E2E_PREFIX}-date-picker`}
          block
          key={
            JSON.stringify(parameters.startDate) +
            JSON.stringify(parameters.endDate)
          }
          onRangeChange={onRangeChange}
          lang={language}
          {...(parameters.startDate &&
            parameters.endDate && {
              defaultValues: [parameters.startDate, parameters.endDate],
            })}
        />
      </Content>
      <Collapse e2eId={`${e2eFeaturePrefix}${E2E_PREFIX}-collapse`}>
        <Collapse.Panel
          e2eId={`${e2eFeaturePrefix}${E2E_PREFIX}-panel-channel`}
          panelKey="channel"
          header={<PanelHeader title={t('invoiceManagement:Channel')} />}
        >
          <Content>
            <RadioButton
              data-e2e-id={`${e2eFeaturePrefix}${E2E_PREFIX}-channel-radio-btn-all`}
              name="channel"
              label={t('common:All')}
              checked={!parameters.channel}
              onChange={onChange}
            />
            {vendorType === TRADEVAN &&
              fixedChannelConfig.map((channel) => (
                <RadioButton
                  key={channel.key}
                  data-e2e-id={`${e2eFeaturePrefix}${E2E_PREFIX}-channel-radio-btn-${channel.value}`}
                  name="channel"
                  label={t(channel.label)}
                  checked={parameters.channel === channel.value}
                  value={channel.value}
                  onChange={onChange}
                />
              ))}
            {channels.map((channel) => (
              <RadioButton
                key={channel._id}
                data-e2e-id={`${e2eFeaturePrefix}${E2E_PREFIX}-channel-radio-btn-${channel.value}`}
                label={getTranslation(channel.name, language)}
                checked={channel._id === parameters.channel}
                name="channel"
                value={channel._id}
                onChange={onChange}
              />
            ))}
          </Content>
        </Collapse.Panel>
        <Collapse.Panel
          e2eId={`${e2eFeaturePrefix}${E2E_PREFIX}-panel-invoice-status`}
          panelKey="invoiceStatus"
          header={<PanelHeader title={t('invoiceManagement:Invoice Status')} />}
        >
          <Content>
            {invoiceStatusConfig.map((status) => (
              <Checkbox.Default
                key={status.key}
                data-e2e-id={`${e2eFeaturePrefix}${E2E_PREFIX}-invoice-status-checkbox-${status.value}`}
                label={t(status.label)}
                checked={
                  parameters.invoiceStatus?.includes(status.value) || false
                }
                name="invoiceStatus"
                value={status.value}
                onChange={onChange}
              />
            ))}
          </Content>
          <ClearButton
            data-e2e-id={`${e2eFeaturePrefix}${E2E_PREFIX}-invoice-status-clear-button`}
            disabled={!parameters.invoiceStatus?.length}
            onClick={() => onClearClick('invoiceStatus')}
          />
        </Collapse.Panel>
        <Collapse.Panel
          e2eId={`${e2eFeaturePrefix}${E2E_PREFIX}-panel-page`}
          panelKey="page"
          header={<PanelHeader title={t('common:Per Page Limit')} />}
        >
          <Content>
            {perPageLimitConfig.map((limit) => (
              <RadioButton
                key={limit.value}
                data-e2e-id={`${e2eFeaturePrefix}${E2E_PREFIX}-page-checkbox-${limit.value}`}
                label={t(`common:${limit.label}`)}
                checked={limit.value === parameters.limit}
                name="limit"
                value={limit.value}
                onChange={onChange}
              />
            ))}
          </Content>
        </Collapse.Panel>
      </Collapse>
    </Popup>
  );
};

export default ByDayMobileFiltersPopup;
